import * as React from 'react';
import {
    Datagrid,
    List,
    TextField,
    BooleanField,
} from 'react-admin';
import { useDefineAppLocation } from '@react-admin/ra-navigation';

const MarketplaceList = () => {
    useDefineAppLocation('settings.marketplaces');

    return (
        <List pagination={false} title="resources.server.cron.page_list_title">
            <Datagrid
                bulkActionButtons={false}
                optimized
                rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" />
                <TextField source="name" label="resources.marketplace.fields.name" />
                <TextField source="proxy" label="resources.marketplace.fields.proxy" />
                <TextField source="countryCode" label="resources.marketplace.fields.country_code" />
                <BooleanField source="isActive" label="resources.marketplace.fields.is_active" />
                <BooleanField source="isRetail" label="resources.marketplace.fields.is_retail" />
            </Datagrid>
        </List>
    );
};

export default MarketplaceList;
