import * as React from 'react';
import {RefObject, useRef, useState} from "react";
import {Card, CardContent, Button, Grid, TextField} from '@mui/material';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import StyleIcon from '@mui/icons-material/Style';
import ArchiveIcon from '@mui/icons-material/Archive';
import BarChartIcon from "@mui/icons-material/BarChart";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    SavedQueriesList,
    useListContext,
    useTranslate
} from 'react-admin';
import {useListFilterContext} from "ra-core";

const Aside = ({tags}) => {
    const t = useTranslate();
    const minStockInputRef = useRef<{ value: string | null }>({value: null});
    const maxStockInputRef = useRef<{ value: string | null }>({value: null});

    const {filterValues, setFilters} = useListFilterContext();
    const {data, isLoading, isFetching} = useListContext();

    const emptyStockFilter = {
        "stock[min]": filterValues['stock[min]'] ?? '',
        "stock[max]": filterValues['stock[max]'] ?? '',
    }

    const [stockFilter, setStockFilter] = useState(emptyStockFilter);
    const [backdrop, setBackdrop] = useState(false);

    const isSelected = (value, filters) => {
        const tags = filters.tags || [];

        return tags.includes(value.tag);
    };

    const toggleFilter = (value, filters) => {
        const tags = filters.tags || [];

        return {
            ...filters,
            tags: tags.includes(value.tag)
                // Remove the category if it was already present
                ? tags.filter(v => v !== value.tag)
                // Add the category if it wasn't already present
                : [...tags, value.tag]
        };
    };

    const handleStockChange = (event) => {
        const stock = parseInt(event.target.value, 10);
        const name = `stock[${event.target.name}]`;

        const filters = {...filterValues};

        if (isNaN(stock) && name in filters) {
            delete filters[name];
        } else if (!isNaN(stock)) {
            filters[name] = stock;
        }

        setFilters({...filters}, null);
    };

    const hide = (key: string): boolean => {
        if (filterValues.hasOwnProperty(key)) {
            return false;
        }
        return data && data.length === 0;
    }

    interface Filter {
        key: string;
        name: string;
        component: JSX.Element;
        render?: (value: any, key?: string) => string;
    }

    const filters: Filter[] = [
        {
            key: 'q',
            name: 'resources.products.filters.search_phrase',
            component: (
                <FilterLiveSearch key="filter_search" disabled={hide('q')}/>
            ),
        },
        {
            key: 'savedQueries',
            name: 'savedQueries',
            component: (
                <div key="filter_sq">
                    <SavedQueriesList/>
                </div>
            ),
        },
        {
            key: 'stock',
            name: 'resources.products.filters.stock_balance_filter',
            component: (
                <FilterList
                    label="resources.products.filters.stock_balance"
                    icon={<BarChartIcon/>}
                    key="filter_stock"
                    display={(hide('stock[min]') && hide('stock[max]')) ? 'none' : 'block'}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                inputRef={minStockInputRef}
                                label="min"
                                name="min"
                                variant="outlined"
                                onChange={handleStockChange}
                                defaultValue={stockFilter['stock[min]']}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                inputRef={maxStockInputRef}
                                label="max"
                                name="max"
                                variant="outlined"
                                onChange={handleStockChange}
                                defaultValue={stockFilter['stock[max]']}
                            />
                        </Grid>
                    </Grid>
                </FilterList>
            ),
            render: (value, key) => {

                if ((key as string).endsWith('min]')) {
                    return `>= ${value ?? ''}`;
                }

                return `<= ${value ?? ''}`;
            }
        },
        {
            key: 'hasPhasingOutDate',
            name: 'resources.products.filters.phased_out_date',
            component: (
                <FilterList
                    label="resources.products.filters.phased_out_date"
                    icon={<DateRangeIcon/>}
                    key="filter_phased_out"
                    display={hide('hasPhasingOutDate') ? 'none' : 'block'}
                >
                    <FilterListItem
                        label="ra.boolean.true"
                        value={{
                            hasPhasingOutDate: true
                        }}
                    />
                    <FilterListItem
                        label="ra.boolean.false"
                        value={{
                            hasPhasingOutDate: false
                        }}
                    />
                </FilterList>
            ),
            render: (value) => {
                return value ? t('ra.boolean.true') : t('ra.boolean.false');
            }
        },
        {
            key: 'retailReady',
            name: 'resources.products.filters.is_retail_ready',
            component: (
                <FilterList
                    label="resources.products.filters.is_retail_ready"
                    icon={<LocalMallOutlinedIcon/>}
                    key="filter_retail"
                    display={hide('retailReady') ? 'none' : 'block'}
                >
                    <FilterListItem
                        label="ra.boolean.true"
                        value={{
                            retailReady: true
                        }}
                    />
                    <FilterListItem
                        label="ra.boolean.false"
                        value={{
                            retailReady: false
                        }}
                    />
                </FilterList>
            ),
            render: (value) => {
                return value ? t('ra.boolean.true') : t('ra.boolean.false');
            }
        },
        {
            key: 'wholesaleReady',
            name: 'resources.products.filters.is_wholesale_ready',
            component: (
                <FilterList
                    label="resources.products.filters.is_wholesale_ready"
                    icon={<WarehouseOutlinedIcon/>}
                    key="filter_wholesale"
                    display={hide('wholesaleReady') ? 'none' : 'block'}
                >
                    <FilterListItem
                        label="ra.boolean.true"
                        value={{
                            wholesaleReady: true
                        }}
                    />
                    <FilterListItem
                        label="ra.boolean.false"
                        value={{
                            wholesaleReady: false
                        }}
                    />
                </FilterList>
            ),
            render: (value) => {
                return value ? t('ra.boolean.true') : t('ra.boolean.false');
            }
        },
        {
            key: 'isBundle',
            name: 'resources.products.filters.is_bundle',
            component: (
                <FilterList
                    label="resources.products.filters.is_bundle"
                    icon={<WorkspacesOutlinedIcon/>}
                    key="filter_bundle"
                    display={hide('isBundle') ? 'none' : 'block'}
                >
                    <FilterListItem
                        label="ra.boolean.true"
                        value={{
                            isBundle: true
                        }}
                    />
                    <FilterListItem
                        label="ra.boolean.false"
                        value={{
                            isBundle: false
                        }}
                    />
                </FilterList>
            ),
            render: (value) => {
                return value ? t('ra.boolean.true') : t('ra.boolean.false');
            }
        },
        {
            key: 'untagged',
            name: 'resources.products.filters.untagged',
            component: (
                <FilterList
                    label="resources.products.filters.untagged"
                    icon={<LocalOfferIcon/>}
                    key="filter_untagged"
                    display={hide('untagged') ? 'none' : 'block'}
                >
                    <FilterListItem
                        label="ra.boolean.true"
                        value={{
                            untagged: true
                        }}
                    />
                </FilterList>
            ),
            render: (value) => {
                return value ? t('ra.boolean.true') : t('ra.boolean.false');
            }
        },
        {
            key: 'tags',
            name: 'resources.products.list.tags',
            component: (
                <FilterList
                    label="resources.products.list.tags"
                    icon={<StyleIcon/>}
                    key="filter_tags"
                    display={hide('tags') ? 'none' : 'block'}
                    sx={{
                        '& .MuiList-root :nth-child(n+6)': {'display': 'none'},
                        '& .MuiList-root:hover > .MuiListItem-root': {'display': 'flex'},
                    }}
                >
                    {tags.map((tag, key) => (
                        <FilterListItem
                            key={key}
                            label={tag.name}
                            value={{tag: tag.id}}
                            isSelected={isSelected}
                            toggleFilter={toggleFilter}
                            sx={
                                {
                                    paddingLeft: tag.type === 'spare_part' || tag.type === 'accessory' ? '1em' : 0,
                                    listStylePosition: tag.type === 'spare_part' || tag.type === 'accessory' ? 'inside' : '',
                                }
                            }
                        />
                    ))}
                </FilterList>
            ),
            render: (value) => {
                return (value as []).join(', ');
            }
        },
        {
            key: 'archived',
            name: 'resources.products.filters.included_archived',
            component: (
                <FilterList
                    label="resources.products.filters.included_archived"
                    icon={<ArchiveIcon/>} sx={{color: '#BA0001'}}
                    key="filter_archived"
                    display={hide('archivedIncluded') ? 'none' : 'block'}
                >
                    <FilterListItem
                        label="ra.boolean.true"
                        value={{
                            archivedIncluded: true
                        }}
                    />
                </FilterList>
            ),
        }
    ];

    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 6,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{pt: 1, position: 'relative'}}>
                <Backdrop
                    sx={{color: '#fff', position: 'absolute', zIndex: (theme) => 9}}
                    open={isFetching || isLoading || backdrop}
                    onClick={() => setBackdrop(false)}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                {
                    Object.keys(filterValues).length > 0 && (
                        <Tooltip
                            title={
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Filter</TableCell>
                                                <TableCell align="right">Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(filterValues).map((key) => {
                                                const k = key.startsWith("stock[") ? "stock" : key;
                                                const filter = filters.find(f => f.key === k);
                                                return (
                                                    <TableRow
                                                        key={key}
                                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {t((filter?.name as string))}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {
                                                                filter?.render ? filter['render'](filterValues[key], key) : filterValues[key]
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }>
                            <Button
                                variant="outlined"
                                size="small"
                                fullWidth
                                color={'primary'}
                                onClick={() => {
                                    setFilters({}, undefined);
                                    setStockFilter(emptyStockFilter);

                                    minStockInputRef.current.value = null;
                                    maxStockInputRef.current.value = null;

                                    setTimeout(() => {
                                        (document.getElementById('q') as HTMLInputElement).value = '';
                                        setFilters({}, undefined);
                                    }, 1000);

                                }}
                                startIcon={<RestartAltIcon/>}
                            >
                                {t('resources.products.filters.reset_filters')}
                            </Button>
                        </Tooltip>
                    )
                }
                {filters.map((f, key) => (f.component))}
            </CardContent>
        </Card>
    );
};

export default Aside;
