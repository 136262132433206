import * as React from 'react';
import { FC } from 'react';
import {
    FormDataConsumer,
    useTranslate,
    AutocompleteInput,
    NumberInput,
    maxValue,
    minValue,
} from 'react-admin';
import { Alert, InputAdornment } from '@mui/material';
import { CouponTypeProps } from '../../types';
import { Targets } from '../targetTypes';
import { ReferenceInput } from 'ra-ui-materialui';

const TargetType: FC<CouponTypeProps> = props => {
    const translate = useTranslate();

    return (
        <FormDataConsumer>
            {({ formData }) => {
                switch (formData?.target?.type) {
                    case Targets.Product:
                        return (
                            <ReferenceInput
                                source={`target[${Targets.Product}]`}
                                name={`target[${Targets.Product}]`}
                                reference="product"
                            >
                                <AutocompleteInput
                                    name={`target[${Targets.Product}]`}
                                    optionText="nameWithSku"
                                    optionValue="sku"
                                    label="resources.coupon.select_product"
                                    sx={{ width: '50%' }}
                                />
                            </ReferenceInput>
                        );
                    case Targets.Money:
                        return (
                            <NumberInput
                                name={`target[${Targets.Money}]`}
                                source={`target[${Targets.Money}]`}
                                label=""
                                hiddenLabel={true}
                                validate={[minValue(1)]}
                                sx={{ width: '170px' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            position="end"
                                            variant={`filled`}
                                        >
                                            €
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        );
                    case Targets.Percentage:
                        return (
                            <NumberInput
                                name={`target[${Targets.Percentage}]`}
                                source={`target[${Targets.Percentage}]`}
                                label=""
                                sx={{ width: '170px' }}
                                hiddenLabel={true}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            variant={`filled`}
                                        >
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                                validate={[maxValue(100), minValue(1)]}
                            />
                        );
                    default:
                        return (
                            <Alert severity="warning">
                                {translate(
                                    'resources.coupon.select_target_type'
                                )}
                            </Alert>
                        );
                }
            }}
        </FormDataConsumer>
    );
};

export default TargetType;
