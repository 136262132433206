import * as React from 'react';
import {
    ExportButton,
    FilterButton,
    List,
    SearchInput,
    TopToolbar,
    TextField,
    BulkDeleteButton,
    DateField,
    FunctionField,
    DatagridConfigurable,
    SelectColumnsButton,
    downloadCSV,
    useStore,
    useTranslate, BooleanField
} from 'react-admin';
import {useMediaQuery, Theme, Chip, Stack} from '@mui/material';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {useEffect, useState} from "react";
import {Dealer} from "../types";
import ArchiveIcon from '@mui/icons-material/Archive';
import jsonExport from 'jsonexport/dist';
import countryCodes, {CountryProperty} from 'country-codes-list';
import DealersListFilters from "./DealersListFilters";

const DealersListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const [showFilter, setShowFilter] = React.useState(false);
    const [backdrop, handleBackdrop] = useState(false);
    const toggleBackdrop = () => {
        handleBackdrop(!backdrop);
    };
    useDefineAppLocation('settings.dealers');

    useEffect(() => {
        let isMounted = true;
        setTimeout(() => {
            if (isMounted) setShowFilter(isSmall);
        }, 0);
        return () => {
            isMounted = false;
        };
    }, [isSmall]);

    return (
        <TopToolbar>
            {showFilter ? <FilterButton/> : null}
            <SelectColumnsButton preferenceKey="dealers.list"/>
            <ExportButton maxResults={10000} onClick={() => toggleBackdrop()}/>
        </TopToolbar>
    );
};

const DealerList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const t = useTranslate();

    let [availableColumns] = useStore('preferences.dealers.list.availableColumns');
    let [selectedColumns] = useStore('preferences.dealers.list.columns');

    const exporter = (dealers: Dealer[]) => {
        const dealersForExport = dealers.map(dealer => {
            if (selectedColumns !== undefined) {

                let dealerToExport = {};

                for (let i = 0; i < selectedColumns.length; i++) {
                    const column = availableColumns.find((column) => column.index === selectedColumns[i]);
                    if (column) {
                        dealerToExport[t(`resources.dealers.list.${column.source}`)] = dealer[column.source];
                    }
                }

                return dealerToExport;
            }

            return {
                [t(`resources.dealers.list.selectLineId`)]: dealer.selectLineId,
                [t(`resources.dealers.list.name`)]: dealer.name,
                [t(`resources.dealers.list.groupNumber`)]: dealer.groupNumber,
                [t(`resources.dealers.list.country`)]: dealer.country,
                [t(`resources.dealers.list.industry`)]: dealer.industry,
                [t(`resources.dealers.list.isActive`)]: dealer.isActive,
                [t(`resources.dealers.list.isServicePartner`)]: dealer.isServicePartner,
                [t(`resources.dealers.list.addressLine1`)]: dealer.addressLine1,
                [t(`resources.dealers.list.addressLine2`)]: dealer.addressLine2,
                [t(`resources.dealers.list.assortment`)]: dealer.assortment.join(', ')
            };
        });
        jsonExport(dealersForExport, {}, (err, csv) => {
            downloadCSV(csv, 'dealers');
        });
    };

    return (
        <React.Fragment>
            <List
                exporter={exporter}
                filters={isSmall ? [<SearchInput key="q" source="q" alwaysOn name="q"/>] : undefined}
                sort={{field: 'number', order: 'DESC'}}
                perPage={25}
                aside={<DealersListFilters/>}
                actions={<DealersListActions/>}
                sx={{marginTop: isSmall ? undefined : -3}}
            >
                <DatagridConfigurable
                    omit={['creationDate', 'phoneNumber1', 'website', 'isDocumentLock', 'street', 'city', 'zipCode', 'addressLine1', 'addressLine2']}
                    preferenceKey="dealers.list"
                    rowClick={(id, resource, record) => record?.deletedAt === undefined ? 'edit' : false}
                    sx={{
                        '& .column-groups': {
                            md: {display: 'none'},
                            lg: {display: 'table-cell'},
                        },
                    }}
                    isRowSelectable={record => record?.deletedAt === undefined}
                    bulkActionButtons={
                        <>
                            <BulkDeleteButton
                                icon={<ArchiveIcon/>}
                                label="resources.dealers.list.archive"
                                mutationMode="optimistic"
                                confirmTitle=""
                                confirmContent="resources.dealers.list.bulk_delete_content"
                            />
                        </>
                    }
                    rowSx={(record, index) => ({
                        backgroundColor: record?.deletedAt !== undefined ? '#f29898' : 'transparent'
                    })}
                >
                    <TextField
                        source="selectLineId"
                        key="selectLineId"
                        label={`resources.dealers.list.selectLineId`}
                    />
                    <TextField
                        source="name"
                        key="name"
                        label={`resources.dealers.list.name`}
                    />
                    <TextField
                        source="groupNumber"
                        key="groupNumber"
                        label={`resources.dealers.list.groupNumber`}
                    />
                    <TextField
                        source="industry"
                        key="industry"
                        label={`resources.dealers.list.industry`}
                    />
                    <FunctionField
                        source="country"
                        key="country"
                        label={`resources.dealers.list.country`}
                        render={(dealer: Dealer) => {
                            const country = countryCodes.findOne('countryCode' as CountryProperty, dealer.country);

                            if (country) {
                                return `${country.flag} ${country.countryNameEn}`;
                            }

                            return dealer.country;
                        }}
                    />
                    <TextField
                        source="addressLine1"
                        key="addressLine1"
                        label={`resources.dealers.list.addressLine1`}
                    />
                    <TextField
                        source="addressLine2"
                        key="addressLine2"
                        label={`resources.dealers.list.addressLine2`}
                    />
                    <TextField
                        source="city"
                        key="city"
                        label={`resources.dealers.list.city`}
                    />
                    <TextField
                        source="street"
                        key="street"
                        label={`resources.dealers.list.street`}
                        sortable={false}
                    />
                    <TextField
                        source="zipCode"
                        key="zipCode"
                        label={`resources.dealers.list.zipCode`}
                        sortable={false}
                    />
                    <TextField
                        source="phoneNumber1"
                        key="phoneNumber1"
                        label={`resources.dealers.list.phoneNumber1`}
                        sortable={false}
                    />
                    <BooleanField
                        source="isActive"
                        key="isActive"
                        label={`resources.dealers.list.isActive`}
                        sortable={true}
                    />
                    <BooleanField
                        source="isServicePartner"
                        key="isServicePartner"
                        label={`resources.dealers.list.isServicePartner`}
                        sortable={true}
                    />
                    <BooleanField
                        source="isDocumentLock"
                        key="isDocumentLock"
                        label={`resources.dealers.list.isDocumentLock`}
                        sortable={true}
                    />
                    <TextField
                        source="website"
                        key="website"
                        label={`resources.dealers.list.website`}
                        sortable={false}
                    />
                    <DateField
                        source="creationDate"
                        key="creationDate"
                        label="resources.dealers.list.creationDate"
                        locales="de-DE"
                        options={{
                            weekday: undefined,
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        }}
                        sortable={true}
                    />

                    <FunctionField
                        source="assortment"
                        label={`resources.dealers.list.assortment`}
                        render={(dealer: Dealer) => (
                            <Stack direction="row" gap={1} flexWrap="wrap">
                                {dealer.assortment.map((designation, key) => {
                                    return (
                                        <Chip
                                            size="small"
                                            key={`${designation}-${key}`}
                                            label={designation}
                                        />
                                    );
                                })}
                            </Stack>)}
                        sortable={false}
                    />
                </DatagridConfigurable>
            </List>
        </React.Fragment>
    );
};

export default DealerList;
