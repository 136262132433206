import * as React from "react";
import {
    BooleanInput,
    Edit,
    SimpleForm,
    TextInput,
    TranslatableInputs, useRecordContext
} from 'react-admin';
import {Box} from "@mui/material";
import TreePreview from "./TreePreview";
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const Form = () => {
    const record = useRecordContext();
    useDefineAppLocation('settings.marketplaces.marketplace.category_edit', {
        categoryRecord: record,
        marketplaceRecord: record.marketplace
    });
    return (
        <SimpleForm>
            <Box display={{xs: 'block', sm: 'flex'}} sx={{width: '100%'}}>
                <Box flex={2} mr={{xs: 0, sm: '0.5em'}}>
                    <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                        <TextInput source="name_translatable" label={`resources.category.edit.name`}/>
                    </TranslatableInputs>
                    <Box
                        display={{xs: 'block', sm: 'flex'}}
                        sx={{width: '100%'}}
                    >
                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                            <TextInput
                                source="internalDescription"
                                name="internalDescription"
                                multiline={true}
                                minRows={4}
                                fullWidth
                                label={`resources.category.edit.internal_desc`}
                            />
                        </Box>
                        <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                            <TextInput source="code" name="code" fullWidth/>
                            <BooleanInput
                                source="isDefault"
                                name="isDefault"
                                label={`resources.category.edit.is_default`}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                    <TreePreview/>
                </Box>
            </Box>
        </SimpleForm>
    );
}

export const CategoryEdit = () => (
    <Edit queryOptions={{meta: {edit: true}}} mutationMode="pessimistic" redirect={false}>
        <Form/>
    </Edit>
);
