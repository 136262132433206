import * as React from 'react';
import { FC } from 'react';
import { useTranslate, SelectInput } from 'react-admin';
import { Typography } from '@mui/material';
import { CouponTab } from '../../utils/types';
import ConditionType from './ConditionType';
import conditionTypes from '../conditionTypes';

const CouponEditConditionTab: FC<CouponTab> = props => {
    const translate = useTranslate();

    return (
        <>
            <Typography variant="h6" gutterBottom>
                {translate('resources.coupon.condition_type')}
            </Typography>
            <SelectInput
                source="condition[type]"
                optionValue="id"
                optionText="name"
                choices={conditionTypes}
                hiddenLabel={true}
                label=""
            />
            <Typography variant="h6" gutterBottom>
                {translate('resources.coupon.condition_request')}
            </Typography>
            <ConditionType types={conditionTypes} />
        </>
    );
};

export default CouponEditConditionTab;
