import * as React from "react";

import {
    SimpleForm,
    TextInput,
    TranslatableInputs,
    useRecordContext,
    useRefresh
} from "react-admin";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";

const CreateChildNodeForm = () => {
    const record = useRecordContext();
    const refresh = useRefresh();

    return (
        <CreateInDialogButton
            record={{parent: record.id}}
            title={`resources.category.edit.new_child_node`}
            label={`resources.category.edit.new_child_node`}
            mutationOptions={{
                onSuccess: () => {
                    refresh();
                }
            }}
        >
            <SimpleForm>
                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                    <TextInput source="title_translatable" label={`resources.category.edit.title`}/>
                </TranslatableInputs>
                <TextInput source="code" name="code" fullWidth/>
            </SimpleForm>
        </CreateInDialogButton>
    );
};

export default CreateChildNodeForm;
