import * as React from 'react';
import {
    Datagrid,
    DateField,
    List,
    useRecordContext,
    useRefresh,
    useDelete,
    useTranslate,
    TextField,
} from 'react-admin';
import {
    Delete as DeleteIcon,
    ArrowForwardIos,
    Update as UpdateIcon,
    PendingActions as PendingActionsIcon,
    DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { CronJob, CronJobSchedule } from '../types';
import { FunctionField } from 'ra-ui-materialui';
import { Box, Button, Typography, Tooltip } from '@mui/material';
import { useState } from 'react';
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const Empty = () => {
    const t = useTranslate();

    return (
        <Box textAlign="center" m={1} width="100%">
            <Typography variant="h4" paragraph>
                {t('resources.server.cron.no_maintenance_periods')}
            </Typography>
            <Typography variant="body2">
                {t('resources.server.cron.no_maintenance_periods_tip')}{' '}
                <ArrowForwardIos sx={{ mb: -0.7 }} />
            </Typography>
        </Box>
    );
};

const CronJobsSchedulesList = () => {
    const record = useRecordContext<CronJob>();
    const refresh = useRefresh();
    const t = useTranslate();

    if (!record) {
        return null;
    }
    useDefineAppLocation('settings.settings_cron.edit', {record});

    const DeleteButton = (props: { schedule: CronJobSchedule }) => {
        const [deleteOne, { isLoading, error }] = useDelete();
        const [isDisabled, setDisabled] = useState(false);

        const handleClick = async () => {
            const del = deleteOne('server/cron-job-schedules', {
                id: props.schedule.id,
            });

            del.then(() => setTimeout(() => refresh(), 1000)).finally(() =>
                setDisabled(true)
            );
        };
        if (error) {
            return <p>ERROR</p>;
        }
        return (
            <Button
                size="small"
                disabled={isDisabled || isLoading}
                onClick={handleClick}
                variant="outlined"
                startIcon={<DeleteIcon />}
                color="error"
            >
                Delete
            </Button>
        );
    };
    return (
        <List
            pagination={false}
            title={` `}
            resource={`server/cron-jobs/${record.id}/schedules`}
            actions={false}
            empty={<Empty />}
        >
            <Datagrid optimized bulkActionButtons={false}>
                <DateField
                    source="timeStart"
                    label="resources.server.cron.start"
                    locales="de-DE"
                    options={{
                        weekday: undefined,
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: 'numeric',
                        minute: 'numeric',
                    }}
                    sortable={false}
                    showTime={true}
                />
                <DateField
                    source="timeEnd"
                    label="resources.server.cron.end"
                    locales="de-DE"
                    options={{
                        weekday: undefined,
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: 'numeric',
                        minute: 'numeric',
                    }}
                    sortable={false}
                    showTime={true}
                />
                <TextField
                    source="createdBy.name"
                    sortable={false}
                    label="resources.server.cron.created_by_label"
                />
                <FunctionField
                    label="resources.server.cron.schedule_state"
                    render={(cron: CronJobSchedule) => {
                        if (cron.isWaiting) {
                            return (
                                <Tooltip
                                    title={t('resources.server.cron.waiting')}
                                >
                                    <PendingActionsIcon color="info" />
                                </Tooltip>
                            );
                        } else if (cron.isActive) {
                            return (
                                <Tooltip
                                    title={t('resources.server.cron.active')}
                                >
                                    <UpdateIcon color="success" />
                                </Tooltip>
                            );
                        }
                        return (
                            <Tooltip
                                title={t('resources.server.cron.archived')}
                            >
                                <DeleteOutlineIcon color="disabled" />
                            </Tooltip>
                        );
                    }}
                />
                <FunctionField
                    label="resources.server.cron.delete_schedule"
                    render={(cron: CronJobSchedule) => {
                        return <DeleteButton schedule={cron} />;
                    }}
                />
            </Datagrid>
        </List>
    );
};

export default CronJobsSchedulesList;
