import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    useTranslate,
    required,
    DateInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';

export const styles = {
    price: { width: '7em' },
    width: { width: '7em' },
    height: { width: '7em' },
    stock: { width: '7em' },
    widthFormGroup: { display: 'inline-block' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const CouponCreate: FC<CreateProps> = props => {
    const translate = useTranslate();

    return (
        <Create {...props} title={translate('resources.coupon.create_coupon')}>
            <SimpleForm>
                <Typography variant="h6" gutterBottom>
                    {translate('resources.coupon.base_data_label')}
                </Typography>

                <TextInput
                    source="name"
                    resource="coupon"
                    validate={requiredValidate}
                    fullWidth
                />
                <RichTextInput
                    source="description"
                    resource="coupon"
                    label={translate('resources.coupon.description')}
                    fullWidth
                />

                <Typography variant="h6" gutterBottom>
                    {translate('resources.coupon.period_of_time')}
                </Typography>
                <Box
                    display={{ xs: 'block', sm: 'flex' }}
                    sx={{width: '100%'}}
                >
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <DateInput
                            source="startDate"
                            resource="coupon"
                            label={`resources.coupon.start_date`}
                            validate={requiredValidate}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <DateInput
                            source="endDate"
                            resource="coupon"
                            label={`resources.coupon.end_date`}
                            validate={requiredValidate}
                            fullWidth
                        />
                    </Box>
                </Box>
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

export default CouponCreate;
