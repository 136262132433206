import * as React from 'react';
import {
    DateInput, NullableBooleanInput,
    SearchInput,
    SelectInput,
} from 'react-admin';

const backInStockFilters = (productTypes: []) => {
    return [
        <SearchInput source="q" name="q" alwaysOn />,
        <NullableBooleanInput
            source="notified"
            name="notified"
            label={`resources.back_in_stock.notified`}
        />,
        <DateInput
            source="createdAt_gte"
            name="createdAt_gte"
            label={`resources.back_in_stock.requested_since`}
        />,
        <DateInput
            source="createdAt_lte"
            name="createdAt_lte"
            label={`resources.back_in_stock.requested_before`}
        />,
        <DateInput
            source="notificationSentAt_gte"
            name="notificationSentAt_gte"
            label={`resources.back_in_stock.notified_since`}
        />,
        <DateInput
            source="notificationSentAt_lte"
            name="notificationSentAt_lte"
            label={`resources.back_in_stock.notified_before`}
        />,
        <SelectInput
            source="productType"
            optionText={(choice?) => {
                return choice?.name;
            }}
            choices={productTypes}
        />,
        <NullableBooleanInput
            source="outdated"
            name="outdated"
            label={`resources.back_in_stock.is_outdated`}
        />,
    ];
}

export default backInStockFilters;
