type statusType = {
    id: number;
    translationKey: string;
    key: string;
    color:
        | 'default'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
};

type statusCollection = {
    [key: string]: statusType;
};

export const statuses: statusCollection = {
    '0': {
        id: 0,
        translationKey: 'resources.order.statuses.new',
        key: 'NEW',
        color: 'default',
    },
    '10': {
        id: 10,
        translationKey: 'resources.order.statuses.payment_pending',
        key: 'PAYMENT_PENDING',
        color: 'primary',
    },
    '20': {
        id: 20,
        translationKey: 'resources.order.statuses.payment_redirect',
        key: 'PAYMENT_REDIRECT',
        color: 'warning',
    },
    '30': {
        id: 30,
        translationKey: 'resources.order.statuses.payment_complete',
        key: 'PAYMENT_COMPLETE',
        color: 'info',
    },
    '40': {
        id: 40,
        translationKey: 'resources.order.statuses.paid',
        key: 'STATUS_PAID',
        color: 'secondary',
    },
    '50': {
        id: 50,
        translationKey: 'resources.order.statuses.sent_to_select_line',
        key: 'STATUS_SENT_TO_SELECT_LINE',
        color: 'success',
    },
    '60': {
        id: 60,
        translationKey: 'resources.order.statuses.partially_refunded',
        key: 'PARTIALLY_REFUNDED',
        color: 'error',
    },
    '65': {
        id: 65,
        translationKey: 'resources.order.statuses.fully_refunded',
        key: 'FULLY_REFUNDED',
        color: 'error',
    },
};

export default [
    { id: 0, name: 'resources.order.statuses.new' },
    { id: 10, name: 'resources.order.statuses.payment_pending' },
    { id: 20, name: 'resources.order.statuses.payment_redirect' },
    { id: 30, name: 'resources.order.statuses.payment_complete' },
    { id: 40, name: 'resources.order.statuses.paid' },
    { id: 50, name: 'resources.order.statuses.sent_to_select_line' },
    { id: 60, name: 'resources.order.statuses.partially_refunded' },
    { id: 65, name: 'resources.order.statuses.fully_refunded' },
];
