import * as React from "react";

import {
    BooleanInput,
    required,
    SimpleForm,
    TextInput,
    minLength,
    maxLength, TranslatableInputs
} from "react-admin";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import {Grid} from "@mui/material";

const CategoryCreateForm = (props: { id: number }) => {

    return (
        <CreateInDialogButton
            record={{marketplace: `/marketplaces/${props.id}`}}
            title={`resources.category.edit.new_category_dialog_title`}
        >
            <SimpleForm>
                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                    <TextInput source="name_translatable" validate={[required(), minLength(2), maxLength(255)]}/>
                </TranslatableInputs>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            source="code"
                            name="code"
                            fullWidth={true}
                            validate={[required(), minLength(2), maxLength(50)]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BooleanInput
                            source="isDefault"
                            name="isDefault"
                            fullWidth={true}
                            sx={{marginTop: '15px'}}
                        />
                    </Grid>
                </Grid>
                <TextInput
                    source="internalDescription"
                    name="internalDescription"
                    multiline={true}
                    minRows={4}
                    fullWidth
                    label={`resources.category.edit.internal_desc`}
                />
            </SimpleForm>
        </CreateInDialogButton>
    );
};

export default CategoryCreateForm;
