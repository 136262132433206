import * as React from 'react';
import { FC } from 'react';
import {
    useRecordContext,
    useTranslate,
    Labeled,
    TextField,
    DateField,
} from 'react-admin';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    Card,
    CardContent,
    CardHeader,
    Divider,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Stack,
} from '@mui/material';

import AsideStatusSelector from './AsideStatusSelector';
import { Coupon } from '../../utils/types';

const AsidePanel = () => {
    return (
        <Box width={300} display={{ xs: 'none', lg: 'block' }}>
            <Panel />
        </Box>
    );
};

export const Panel: FC = props => {
    const record: Coupon = useRecordContext(props);
    const t = useTranslate();

    return (
        <>
            <Card
                sx={{
                    flex: '0 0 15em',
                    ml: 2,
                }}
            >
                <CardHeader
                    title={t('resources.coupon.status.label')}
                    style={{
                        padding: '15px',
                        color: '#808080',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        letterSpacing: '0.02857em',
                        textTransform: 'uppercase',
                    }}
                    disableTypography={true}
                />
                <Divider />
                <CardContent sx={{ pt: 1 }}>
                    {record && (
                        <AsideStatusSelector
                            id={record.id}
                            status={record.status}
                        />
                    )}

                    <Accordion disabled>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                {t('resources.coupon.status.list_label')}
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>
                                {t('resources.coupon.status.draft')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('resources.coupon.status.draft_details')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography>
                                {t('resources.coupon.status.active')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('resources.coupon.status.active_details')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography>
                                {t('resources.coupon.status.on_hold')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('resources.coupon.status.on_hold_details')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5a-content"
                            id="panel5a-header"
                        >
                            <Typography>
                                {t('resources.coupon.status.deactivated')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t(
                                    'resources.coupon.status.deactivated_details'
                                )}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
            <Card
                sx={{
                    flex: '0 0 15em',
                    ml: 2,
                    mt: 2,
                }}
            >
                <CardHeader
                    title={t('resources.coupon.info.label')}
                    style={{
                        padding: '15px',
                        color: '#808080',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        letterSpacing: '0.02857em',
                        textTransform: 'uppercase',
                    }}
                    disableTypography={true}
                />
                <Divider />
                <CardContent sx={{ pt: 1 }}>
                    <Stack
                        divider={<Divider orientation="horizontal" />}
                        spacing={1}
                    >
                        <Labeled>
                            <TextField
                                label="resources.coupon.created_by_label"
                                source="createdBy.name"
                            />
                        </Labeled>
                        <Labeled>
                            <DateField
                                label="resources.coupon.info.created_at_label"
                                source="createdAt"
                                showTime
                                locales="de-DE"
                                options={{
                                    dateStyle: 'medium',
                                    timeStyle: 'short',
                                }}
                            />
                        </Labeled>
                        {!!record?.updatedAt && (
                            <Labeled>
                                <TextField
                                    label="resources.coupon.info.updated_by_label"
                                    source="updatedBy.name"
                                />
                            </Labeled>
                        )}
                        {!!record?.updatedAt && (
                            <Labeled>
                                <DateField
                                    emptyText="resources.coupon.info.updated_at_label"
                                    label="resources.coupon.info.updated_at_label"
                                    source="updatedAt"
                                    showTime
                                    locales="de-DE"
                                    options={{
                                        dateStyle: 'medium',
                                        timeStyle: 'short',
                                    }}
                                />
                            </Labeled>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
};

export default AsidePanel;
