import * as React from 'react';
import { Show } from 'react-admin';

import Aside from './Aside';
import CronJobsSchedulesList from './CronJobsSchedulesList';

const CronJobShow = () => {
    return (
        <Show
            aside={<Aside />}
            actions={false}
            title="resources.server.cron.page_title"
            sx={{
                '& .RaShow-card': {
                    border: 'none',
                    background: 'transparent',
                },
            }}
        >
            <CronJobsSchedulesList />
        </Show>
    );
};

export default CronJobShow;
