import * as React from 'react';
import { FC, useCallback } from 'react';
import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    required,
    minLength,
    email,
    SelectInput,
    useNotify,
    useCreate,
    useRedirect,
} from 'react-admin';
import { Box, Typography } from '@mui/material';

const validatePassword = (
    password: string,
    values: Record<string, any>,
    field: any
) => {
    const fieldName = field?.name;

    if (fieldName !== 'passwordRepeat') {
        return undefined;
    }

    if (values?.password) {
        if (
            !values?.passwordRepeat ||
            values.passwordRepeat !== values.password
        ) {
            return 'resources.user.validation.password_dont_match';
        }
    }

    return undefined;
};

const UserCreate: FC<CreateProps> = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const [create] = useCreate();

    const save = useCallback(
        async values => {
            try {
                await create(`user`, { data: values }, { returnPromise: true });

                notify('resources.user.created_successfully', {
                    type: 'success',
                    autoHideDuration: 5000,
                });
                redirect('/user');
            } catch (error: any) {
                const e = error?.body?.error;

                if (e?.errors) {
                    return error.body.error.errors;
                }
            }
        },
        [create, notify, redirect]
    );

    return (
        <Create {...props} title={translate('resources.user.create_user')}>
            <SimpleForm onSubmit={save}>
                <Typography variant="h6" gutterBottom>
                    {translate('resources.user.fieldGroups.identity')}
                </Typography>
                <Box
                    display={{ xs: 'block', sm: 'flex' }}
                    sx={{width: '100%'}}
                >
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                            source="firstName"
                            resource="user"
                            validate={requiredValidate}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                            source="lastName"
                            resource="user"
                            validate={requiredValidate}
                            fullWidth
                        />
                    </Box>
                </Box>

                <Typography variant="h6" gutterBottom>
                    {translate('resources.user.fieldGroups.contact')}
                </Typography>
                <Box
                    display={{ xs: 'block', sm: 'flex' }}
                    sx={{width: '100%'}}
                >
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                            type="email"
                            source="email"
                            resource="user"
                            validate={[email(), required()]}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <TextInput source="phone" resource="user" fullWidth />
                    </Box>
                </Box>

                <Typography variant="h6" gutterBottom>
                    {translate('resources.user.fieldGroups.access_role')}
                </Typography>
                <SelectInput
                    source="accessRoleId"
                    validate={requiredValidate}
                    choices={[
                        { id: 1, name: 'Admin' },
                        { id: 2, name: 'User' },
                    ]}
                />

                <Typography variant="h6" gutterBottom>
                    {translate('resources.user.fieldGroups.password')}
                </Typography>
                <Box
                    display={{ xs: 'block', sm: 'flex' }}
                    sx={{width: '100%'}}
                >
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <PasswordInput
                            name="password"
                            source="password"
                            validate={[required(), minLength(6)]}
                            resource="user"
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <PasswordInput
                            name="passwordRepeat"
                            source="passwordRepeat"
                            validate={[required(), validatePassword]}
                            resource="user"
                            fullWidth
                        />
                    </Box>
                </Box>
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

export default UserCreate;
