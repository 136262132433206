export enum Conditions {
    Order = 'order',
    Product = 'product',
    Email = 'email',
    Category = 'category',
    Marked = 'marked',
}

export const types = {
    [Conditions.Order]: Conditions.Order,
    [Conditions.Product]: Conditions.Product,
    [Conditions.Email]: Conditions.Email,
    [Conditions.Category]: Conditions.Category,
    [Conditions.Marked]: Conditions.Marked,
};

export default [
    {
        id: Conditions.Order,
        name: 'resources.coupon.order',
    },
    {
        id: Conditions.Product,
        name: 'resources.coupon.product',
    },
    {
        id: Conditions.Email,
        name: 'resources.coupon.email',
    },
    {
        id: Conditions.Category,
        name: 'resources.coupon.category',
    },
    {
        id: Conditions.Marked,
        name: 'resources.coupon.marked',
    },
];
