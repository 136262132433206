import { CouponCreateType } from '../../types';

export default async (
    endDate: string,
    coupon: CouponCreateType,
    field: any
) => {
    const fieldName = field?.name;

    if (fieldName !== 'endDate') {
        return undefined;
    }

    if (coupon?.startDate && coupon?.endDate) {
        const start = new Date(coupon?.startDate as string).getTime();
        const end = new Date(coupon?.endDate as string).getTime();

        if (end < start) {
            return 'resources.coupon.validation.start_end_comparison';
        }
    }

    return undefined;
};
