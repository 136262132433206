import * as React from "react";
import {useRecordContext, useGetOne, Loading, useTranslate} from "react-admin";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const QrCodeRenderer = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    const {
        data: qrCode,
        isLoading
    } = useGetOne(`product/${record.id}/manuals-file`, {id: undefined}, {staleTime: 30000});

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <Card sx={{display: 'flex', width: '700px', margin: 'auto'}}>
            <CardMedia
                component="img"
                sx={{width: 151}}
                image={qrCode?.qrCode}
                alt="QR code"
            />
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <CardContent sx={{flex: '1 0 auto'}}>
                    <Typography component="div" variant="h6" sx={{fontSize: '15px'}}>
                        {qrCode?.url}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        <Link href={qrCode?.file} target={`_blank`} color="inherit">
                            {translate('resources.products.show.download_qr_code_file')}
                        </Link>
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    );
};

export default QrCodeRenderer;
