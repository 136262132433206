import * as React from 'react';
import { FC } from 'react';
import { useTranslate, SelectInput } from 'react-admin';
import { Typography } from '@mui/material';
import { CouponTab } from '../../utils/types';
import targetTypes from '../targetTypes';
import TargetType from './TargetType';

const CouponEditTargetTab: FC<CouponTab> = props => {
    const translate = useTranslate();

    return (
        <>
            <Typography variant="h6" gutterBottom>
                {translate('resources.coupon.target_type')}
            </Typography>
            <SelectInput
                name="target[type]"
                source="target[type]"
                optionValue="id"
                optionText="name"
                choices={targetTypes}
                hiddenLabel={true}
                label=""
            />
            <TargetType types={targetTypes} />
        </>
    );
};

export default CouponEditTargetTab;
