type statusType = {
    id: number;
    translationKey: string;
    key: string;
    color:
        | 'default'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
};

type statusCollection = {
    [key: string]: statusType;
};

export const statuses: statusCollection = {
    '50': {
        id: 50,
        translationKey: 'resources.order.statuses.ordered',
        key: 'ORDERED',
        color: 'success',
    },
    '60': {
        id: 60,
        translationKey: 'resources.order.statuses.partially_refunded',
        key: 'PARTIALLY_REFUNDED',
        color: 'warning',
    },
    '65': {
        id: 65,
        translationKey: 'resources.order.statuses.fully_refunded',
        key: 'FULLY_REFUNDED',
        color: 'error',
    },
};

export default [
    { id: 50, name: 'resources.order.statuses.ordered' },
    { id: 60, name: 'resources.order.statuses.partially_refunded' },
    { id: 65, name: 'resources.order.statuses.fully_refunded' },
];
