import * as React from 'react';
import { useState } from 'react';
import placeholderImage from './assets/placeholder.png';
import { styled } from '@mui/material/styles';

const Img = styled('img')({
    width: '100%',
    verticalAlign: 'middle',
});

const BackInStockDetailsHeader = () => {
    const [image, setImage] = useState(placeholderImage);

    return <Img src={image} alt="Product image" />;
};

export default BackInStockDetailsHeader;
