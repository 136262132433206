import * as React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Link, useTranslate, useGetOne, DateField } from 'react-admin';

import { Order, CouponDictionaryItem } from '../types';
import { TableCellRight } from './TableCellRight';

const OrderDiscount = (props: { order: Order }) => {
    const { order } = props;
    const discount = order.orderDiscount;
    const translate = useTranslate();
    const { data: coupon } = useGetOne<CouponDictionaryItem>('coupon', {
        id: discount.couponId,
    });

    return (
        <>
            <Typography variant="h6" gutterBottom>
                {translate('resources.order.show.discount')}
            </Typography>
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {translate('resources.order.show.coupon')}
                            </TableCell>
                            <TableCell>
                                {translate('resources.order.show.coupon_code')}
                            </TableCell>
                            {discount.type === 'product' ? (
                                <TableCell>
                                    {translate(
                                        'resources.order.show.free_product_sku'
                                    )}
                                </TableCell>
                            ) : (
                                <TableCellRight>
                                    {translate(
                                        'resources.order.show.discount_amount'
                                    )}
                                </TableCellRight>
                            )}
                            <TableCellRight>
                                {translate('resources.order.show.applied_at')}
                            </TableCellRight>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={discount.id}>
                            <TableCell>
                                <Link to={`/coupon/${coupon?.id}`}>
                                    {coupon?.name}
                                </Link>
                            </TableCell>
                            <TableCell>{discount.couponCode}</TableCell>
                            {discount.type === 'product' ? (
                                <TableCell>{discount.value}</TableCell>
                            ) : (
                                <TableCellRight>
                                    {parseFloat(discount.amount).toLocaleString(
                                        'de-DE',
                                        {
                                            style: 'currency',
                                            currency: 'EUR',
                                            currencySign: 'accounting',
                                        }
                                    )}
                                </TableCellRight>
                            )}
                            <TableCellRight>
                                <DateField
                                    source="orderDiscount.createdAt"
                                    showTime={true}
                                    locales="de-DE"
                                    options={{
                                        dateStyle: 'medium',
                                        timeStyle: 'short',
                                    }}
                                />
                            </TableCellRight>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            <Box m={1}>&nbsp;</Box>
        </>
    );
};

export default OrderDiscount;
