import * as React from 'react';
import {
    UrlField,
    ExportButton,
    FilterButton,
    List,
    SearchInput,
    TopToolbar,
    TextField,
    useDataProvider,
    ResourceContextProvider,
    BulkDeleteButton,
    DateField,
    FunctionField,
    DatagridConfigurable,
    SelectColumnsButton,
    downloadCSV,
    useStore,
    useTranslate, BooleanField
} from 'react-admin';
import {useMediaQuery, Theme} from '@mui/material';
import ProductListAside from './ProductListAside';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {useEffect, useState} from "react";
import {Product, ProductTag} from "../types";
import TagsField from "./TagsField";
import MarketplaceBulkUpdateButton from "./MarketplaceBulkUpdateButton";
import TagsBulkUpdateButton from "./TagsBulkUpdateButton";
import ArchiveIcon from '@mui/icons-material/Archive';
import jsonExport from 'jsonexport/dist';
import FlagField, {FLAGS} from "./FlagField";

const ProductListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const [showFilter, setShowFilter] = React.useState(false);
    const [backdrop, handleBackdrop] = useState(false);
    const toggleBackdrop = () => {
        handleBackdrop(!backdrop);
    };
    useDefineAppLocation('catalog.products');

    useEffect(() => {
        let isMounted = true;
        setTimeout(() => {
            if (isMounted) setShowFilter(isSmall);
        }, 0);
        return () => {
            isMounted = false;
        };
    }, [isSmall]);

    return (
        <TopToolbar>
            {showFilter ? <FilterButton/> : null}
            <SelectColumnsButton preferenceKey="products.list"/>
            <ExportButton maxResults={5000} onClick={() => toggleBackdrop()}/>
        </TopToolbar>
    );
};

const ProductList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const dataProvider = useDataProvider();
    const t = useTranslate();

    const [tags, setTags] = useState<ProductTag[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dataProvider.getList(`products/tags/collection`, {
            filter: [],
            sort: {field: 'id', order: 'ASC'},
            pagination: {page: 1, perPage: 1000},
            meta: {}
        })
            .then(({data}) => {
                setTags(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }, []);
    let [availableColumns] = useStore('preferences.products.list.availableColumns');
    let [selectedColumns] = useStore('preferences.products.list.columns');

    const exporter = (products: Product[]) => {
        const productsForExport = products.map(product => {
            if (selectedColumns !== undefined) {

                let productToExport = {};

                for (let i = 0; i < selectedColumns.length; i++) {
                    const column = availableColumns.find((column) => column.index === selectedColumns[i]);
                    if (column) {
                        if (column.source === 'tags') {
                            productToExport[t(`resources.products.list.${column.source}`)] = product.tags.map(tag => {
                                const data = tags.find(t => t.id === tag.tagId);
                                if (data === undefined) {
                                    return null;
                                }
                                return data.name;
                            }).join('|');
                        } else if (column.source === 'stock') {
                            productToExport[t(`resources.products.list.${column.source}`)] = product?.stock?.quantity;
                        } else if (column.source === 'flag') {
                            productToExport[t(`resources.products.list.${column.source}`)] = product[column.source] ? t(`resources.products.flags.${FLAGS[product[column.source]]}`) : '';
                        } else {
                            productToExport[t(`resources.products.list.${column.source}`)] = product[column.source];
                        }
                    }
                }

                return productToExport;
            }

            return {
                [t(`resources.products.list.sku`)]: product.sku,
                [t(`resources.products.list.name`)]: product.name,
                [t(`resources.products.list.color`)]: product.color,
                [t(`resources.products.list.size`)]: product.size,
                [t(`resources.products.list.memo`)]: product.memo,
                [t(`resources.products.list.stock`)]: product?.stock?.quantity,
                [t(`resources.products.list.phasingOutDate`)]: product.phasingOutDate,
                [t(`resources.products.list.tags`)]: product.tags.map(tag => {
                    const data = tags.find(t => t.id === tag.tagId);
                    if (data === undefined) {
                        return null;
                    }
                    return data.name;
                }).join('|')
            };
        });
        jsonExport(productsForExport, {}, (err, csv) => {
            downloadCSV(csv, 'products');
        });
    };

    if (loading) {
        return null;
    }

    return (
        <React.Fragment>
            <List
                exporter={exporter}
                filters={isSmall ? [<SearchInput key="q" source="q" alwaysOn name="q"/>] : undefined}
                sort={{field: 'id', order: 'DESC'}}
                perPage={25}
                aside={<ProductListAside tags={tags}/>}
                actions={<ProductListActions/>}
                sx={{marginTop: isSmall ? undefined : -3}}
            >
                <DatagridConfigurable
                    omit={['memo', 'ean', 'regularDiscount', 'distriDiscount', 'oldUrl', 'isDealerDedicated', 'flag']}
                    preferenceKey="products.list"
                    rowClick={(id, resource, record) => record?.deletedAt === undefined ? 'edit' : false}
                    sx={{
                        '& .column-groups': {
                            md: {display: 'none'},
                            lg: {display: 'table-cell'},
                        },
                    }}
                    isRowSelectable={record => record?.deletedAt === undefined}
                    bulkActionButtons={
                        <>
                            <ResourceContextProvider value="products/allocation">
                                <MarketplaceBulkUpdateButton/>
                            </ResourceContextProvider>
                            <ResourceContextProvider value="products/tags">
                                <TagsBulkUpdateButton tags={tags}/>
                            </ResourceContextProvider>
                            <BulkDeleteButton
                                icon={<ArchiveIcon/>}
                                label="resources.products.list.archive"
                                mutationMode="optimistic"
                                confirmTitle=""
                                confirmContent="resources.products.list.bulk_delete_content"
                            />
                        </>
                    }
                    rowSx={(record, index) => ({
                        backgroundColor: record?.deletedAt !== undefined ? '#f29898' : 'transparent'
                    })}
                >
                    <TextField source="sku" key="sku"/>
                    <TextField
                        source="name"
                        key="name"
                        label={`resources.products.list.name`}
                    />
                    <TextField
                        source="color"
                        key="color"
                        sortable={true}
                        label={`resources.products.list.color`}
                    />
                    <TextField
                        source="size"
                        key="size"
                        sortable={true}
                        label={`resources.products.list.size`}
                    />
                    <TextField
                        source="memo"
                        key="memo"
                        sortable={false}
                        label={`resources.products.list.memo`}
                    />
                    <FunctionField
                        source="stock"
                        label={`resources.products.list.stock`}
                        textAlign={`right`}
                        render={(product: Product) => product?.stock?.quantity}
                    />
                    <DateField
                        source="phasingOutDate"
                        key="phasingOutDate"
                        sortable={true}
                        label={`resources.products.list.phasingOutDate`}
                    />
                    <TextField
                        source="ean"
                        key="ean"
                        label={`resources.products.list.ean`}
                        sortable={true}
                    />
                    <FunctionField
                        source="regularDiscount"
                        key="regularDiscount"
                        label={`resources.products.list.regularDiscount`}
                        render={(product: Product) => Number(product?.regularDiscount / 100).toLocaleString('de-DE', {
                            style: 'percent',
                            minimumFractionDigits: 2
                        })}
                    />
                    <FunctionField
                        source="distriDiscount"
                        key="distriDiscount"
                        label={`resources.products.list.distriDiscount`}
                        render={(product: Product) => Number(product?.distriDiscount / 100).toLocaleString('de-DE', {
                            style: 'percent',
                            minimumFractionDigits: 2
                        })}
                    />
                    <UrlField
                        source="oldUrl"
                        key="oldUrl"
                        label={`resources.products.list.oldUrl`}
                        sortable={true}
                    />
                    <BooleanField
                        source="isDealerDedicated"
                        key="isDealerDedicated"
                        label={`resources.products.list.isDealerDedicated`}
                        sortable={true}
                    />
                    <FunctionField
                        source="flag"
                        label={`resources.products.list.flag`}
                        render={(product: Product) => (<FlagField flag={product.flag}/>)}
                        sortable={true}
                    />
                    <FunctionField
                        source="tags"
                        label={`resources.products.list.tags`}
                        render={(product: Product) => (<TagsField tags={tags}/>)}
                        sortable={false}
                    />
                </DatagridConfigurable>
            </List>
        </React.Fragment>
    );
};

export default ProductList;
