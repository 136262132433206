import * as React from 'react';
import {FC} from 'react';
import {Box, Typography, Card} from '@mui/material';
import {useTranslate} from 'react-admin';
import {ThemeOptions} from "@react-admin/ra-enterprise";
import {SimplePaletteColorOptions} from "@mui/material/styles/createPalette";

const Welcome: FC = () => {
    const translate = useTranslate();

    const style = {
        background: (theme: ThemeOptions) => {
            const secondary: any = (theme?.palette?.secondary as SimplePaletteColorOptions);
            const primary: any = (theme?.palette?.primary as SimplePaletteColorOptions);

            return `linear-gradient(45deg, ${secondary?.dark} 0%, ${secondary.light} 50%, ${primary.dark} 100%)`;
        },
        color: (theme: ThemeOptions) => (theme?.palette?.primary as SimplePaletteColorOptions).contrastText,
        padding: '20px',
    };

    return (
        <Card sx={style}>
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2" gutterBottom>
                        {translate('pos.dashboard.welcome.title')}
                    </Typography>
                    <Box maxWidth="40em">
                        <Typography variant="body1" component="p" gutterBottom>
                            {translate('pos.dashboard.welcome.subtitle')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};

export default Welcome;
