import * as React from 'react';
import {useState, useRef, useCallback} from 'react';
import {
    Confirm,
    useTranslate,
    useRecordContext,
    useNotify,
    useAuthProvider,
    useRefresh,
    useGetOne,
    useUpdate,
    Loading,
    useDelete, number
} from 'react-admin';

import {
    DialogContent,
    DialogTitle,
    Dialog,
    Button,
    Divider,
    IconButton,
    DialogActions,
    Typography,
    Stack,
    Switch,
    TextField,
    Alert,
    AlertTitle,
    Tooltip,
    ImageList,
    ImageListItem,
    ToggleButton,
    ToggleButtonGroup,
    styled,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Link,
    Collapse,
} from '@mui/material';

import {useLocation} from 'react-router-dom';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import CloseIcon from '@mui/icons-material/Close';

const MfaEditSection = () => {
    const notify = useNotify();
    const translate = useTranslate();
    const location = useLocation();
    const record = useRecordContext();
    const auth = useAuthProvider();
    const [update] = useUpdate();
    const [deleteDevice] = useDelete();
    const refresh = useRefresh();

    const [isMfaEnabled, setIsMfaEnabled] = useState(record?.isMfaEnabled);
    const [openDialog, setOpenDialog] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isDisable, setIsDisable] = useState(true);

    const [tooltip, setTooltip] = useState('');
    const [refs, setRefs] = useState({});

    if (auth?.getIdentity) {
        auth?.getIdentity()
            .then(identity => {
                const isDisAllowed = identity?.id !== record?.id;
                setIsDisable(isDisAllowed);

                if (isDisAllowed) {
                    setTooltip(
                        identity?.id !== record?.id
                            ? translate('resources.user.disable_mfa_disallowed')
                            : translate(
                                'resources.user.disable_mfa_disallowed_2'
                            )
                    );
                }
            })
            .catch(() => {
                setTooltip(translate('resources.user.disable_mfa_disallowed'));
            });
    }

    const removeDevice = () => {
        const id = `${record.id}/mfa`;
        const deleteDev = deleteDevice('user', {
            id,
        });

        deleteDev
            .then(() => {
                setIsMfaEnabled(false);
                refresh();
                notify('resources.user.updated_successfully', {
                    type: 'success',
                    autoHideDuration: 5000,
                });
            })
            .catch(() => {
                notify('resources.user.something_went_wrong', {
                    type: 'warning',
                    autoHideDuration: 5000,
                });
                setIsMfaEnabled(true);
            })
            .finally(() => setConfirmOpen(false));
    };

    const saveDevice = useCallback(async () => {
        const code = Object.values(refs)
            .map((input: any) => input.current.value)
            .join('');

        if (code.length !== 6) {
            notify('resources.user.six_digits_validation', {
                type: 'warning',
                autoHideDuration: 5000,
            });

            return;
        }

        try {
            const id = `${record.id}/mfa`;

            setIsDisable(true);
            setOpenDialog(false);

            await update(
                `user`,
                {id, data: {code}},
                {returnPromise: true}
            );

            notify('resources.user.updated_successfully', {
                type: 'success',
                autoHideDuration: 5000,
            });

            setIsMfaEnabled(true);
        } catch (error: any) {
            notify(
                error?.body?.error?.message ??
                'resources.user.something_went_wrong',
                {
                    type: 'warning',
                    autoHideDuration: 5000,
                }
            );
        }
    }, []);

    const isMfa = parseInt(
        new URLSearchParams(location.search).get('mfa') as string
    );
    if (isMfa && !isMfaEnabled) {
        notify('resources.user.you_need_to_setup_mfa', {
            type: 'info',
        });
    }

    const QrCode = () => {
        const id = `${record.id}/mfa-qr-code`;
        const {data: user, isLoading} = useGetOne(
            'user',
            {
                id,
            },
            {cacheTime: 10000}
        );
        if (isLoading) {
            return <Loading/>;
        }

        return (
            <ImageList
                sx={{width: 200, marginTop: '0px !important'}}
                cols={1}
            >
                <ImageListItem key="_1">
                    <img src={user?.qrCode} alt="QR Code"/>
                </ImageListItem>
            </ImageList>
        );
    };

    const processCode = (field: any) => {
        const input = field.target;
        const id = parseInt(input.id);

        if (parseInt(input.id) < 6) {
            refs[id + 1].current.focus();
        }
    };
    const MfaNumberField = (props: { number: string }) => {
        const {number} = props;

        const tmp = refs;
        tmp[number] = useRef(null);
        setRefs(tmp);

        return (
            <TextField
                onInput={processCode}
                inputRef={tmp[number]}
                id={number}
                hiddenLabel={true}
                variant="outlined"
                placeholder={number}
                focused={number === '1'}
                maxRows={1}
                inputProps={{maxLength: '1'}}
                sx={{
                    width: '100%',
                    height: '100%',
                    '& .MuiOutlinedInput-input': {
                        border: '0px !important',
                        padding: '5px',
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                    },
                    '& .MuiOutlinedInput-root': {
                        width: '100%',
                        height: '100%',
                    },
                    '& p': {
                        display: 'none',
                    },
                    '& fieldset': {
                        border: '0',
                    },
                }}
            />
        );
    };

    const setMfa = (status: Boolean) => {
        if (status) {
            setOpenDialog(true);
        } else {
            setConfirmOpen(true);
        }
    };

    const BootstrapDialogTitle = (props: {
        id: string;
        children?: React.ReactNode;
        onClose: () => void;
    }) => {
        const {children, onClose, ...other} = props;

        return (
            <DialogTitle sx={{m: 0, p: 2}} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    const BootstrapDialog = styled(Dialog)(({theme}) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    return (
        <>
            <Typography variant="h6" gutterBottom>
                {translate('resources.user.fieldGroups.mfa')}
            </Typography>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <Typography variant="body2">
                    {translate('resources.user.mfa_headline_subtitle')}
                </Typography>
                <Tooltip title={tooltip}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>
                            {translate('resources.user.fields.switchOff')}
                        </Typography>
                        <Switch
                            title={translate(
                                'resources.user.mfa_headline_subtitle'
                            )}
                            disabled={isDisable}
                            checked={isMfaEnabled}
                            onChange={() => {
                                setMfa(!isMfaEnabled);
                            }}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                        <Typography>
                            {translate('resources.user.fields.switchOn')}
                        </Typography>
                    </Stack>
                </Tooltip>
            </Stack>

            {record.isMfaRequired && !record.isMfaEnabled ? (
                <Alert severity="warning">
                    <AlertTitle>
                        {translate('resources.user.warning')}
                    </AlertTitle>
                    {translate('resources.user.you_need_to_setup_mfa')}
                </Alert>
            ) : null}
            <BootstrapDialog
                onClose={() => setOpenDialog(false)}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={() => setOpenDialog(false)}
                >
                    {translate('resources.user.mfa_dialog_title')}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom width="100%" textAlign="center">
                        {translate('resources.user.mfa_dialog_subtitle')}
                    </Typography>
                    <Stack
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography
                            fontWeight="bold"
                            sx={{margin: '20px 0px -10px 0px', zIndex: 9}}
                        >
                            {translate('resources.user.scanQrCode')}
                        </Typography>
                        <QrCode/>
                    </Stack>
                    <Divider sx={{margin: '20px 0px'}}/>
                    <Typography gutterBottom width="100%" textAlign="center">
                        {translate('resources.user.enterSixDigitCode')}
                    </Typography>
                    <ToggleButtonGroup exclusive fullWidth={true}>
                        <ToggleButton value="1" aria-label="left aligned">
                            <MfaNumberField number="1"/>
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="centered">
                            <MfaNumberField number="2"/>
                        </ToggleButton>
                        <ToggleButton value="3" aria-label="right aligned">
                            <MfaNumberField number="3"/>
                        </ToggleButton>
                        <ToggleButton value="4" aria-label="justified">
                            <MfaNumberField number="4"/>
                        </ToggleButton>
                        <ToggleButton value="5" aria-label="justified">
                            <MfaNumberField number="5"/>
                        </ToggleButton>
                        <ToggleButton value="6" aria-label="justified">
                            <MfaNumberField number="6"/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </DialogContent>
                <DialogActions>
                    <Accordion sx={{width: '480px', border: 'none'}}>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{textDecoration: 'underline'}}>
                                {translate('resources.user.mobileAppList')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <List dense={true}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AppleIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Apple iOS"/>
                                    </ListItem>
                                    <Collapse in={true} unmountOnExit>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Link
                                                        href="https://apps.apple.com/de/app/google-authenticator/id388497605?l=de"
                                                        underline="hover"
                                                        target={`_blank`}
                                                    >
                                                        Google Authenticator
                                                    </Link>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Link
                                                        href="https://apps.apple.com/de/app/twilio-authy/id494168017?l=de"
                                                        underline="hover"
                                                        target={`_blank`}
                                                    >
                                                        Twilio authy
                                                    </Link>
                                                }
                                            />
                                        </ListItem>
                                    </Collapse>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AndroidIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Google Android"/>
                                    </ListItem>
                                    <Collapse in={true} unmountOnExit>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Link
                                                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                                        underline="hover"
                                                        target={`_blank`}
                                                    >
                                                        Google Authenticator
                                                    </Link>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Link
                                                        href="https://play.google.com/store/apps/details?id=com.authy.authy"
                                                        underline="hover"
                                                        target={`_blank`}
                                                    >
                                                        Twilio Authy
                                                    </Link>
                                                }
                                            />
                                        </ListItem>
                                    </Collapse>
                                </List>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Button onClick={saveDevice}>
                        {translate('resources.user.addDevice')}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            <Confirm
                isOpen={confirmOpen}
                title={translate('resources.user.disable_mfa_dialog_title')}
                content={translate('resources.user.disable_mfa_dialog_content')}
                onConfirm={removeDevice}
                onClose={() => {
                    setConfirmOpen(false);
                    setIsMfaEnabled(true);
                }}
            />
        </>
    );
};

export default MfaEditSection;
