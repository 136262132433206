import * as React from "react";

import {
    AutocompleteInput, BooleanInput,
    number,
    NumberInput,
    required,
    SimpleForm,
    TextInput,
    useGetList,
    useListContext, useRefresh
} from "react-admin";
import {Marketplace, Product, ProductMarketplaceAllocation} from "../types";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import {Grid, InputAdornment} from "@mui/material";

const MarketplaceAllocationCreateForm = (props: { record: Product }) => {
    const refresh = useRefresh();

    const marketplaces = useGetList<Marketplace>('marketplaces', {filter: {isDefault: false}}, {staleTime: 30000});
    const allocations = useListContext<ProductMarketplaceAllocation>();

    if (marketplaces.isLoading || allocations.isLoading) return null;

    const marketplacesChoices: Marketplace[] = marketplaces.data as Marketplace[];
    const allocationsChoices: ProductMarketplaceAllocation[] = allocations.data as ProductMarketplaceAllocation[];

    const chices = marketplacesChoices.filter(marketplace => !allocationsChoices.some(allocation => allocation.marketplace.id === marketplace.id));

    return (
        <CreateInDialogButton
            record={{product: `/products/${props.record.id}`}}
            title={`resources.products.show.marketplace_allocation`}
            mutationOptions={{
                onSuccess: () => {
                    refresh();
                }
            }}
        >
            <SimpleForm>
                <AutocompleteInput
                    optionText="name"
                    optionValue="@id"
                    fullWidth={true}
                    choices={chices}
                    name="marketplace"
                    validate={[required()]}
                    label={`resources.products.show.marketplace`}
                />
                <TextInput
                    source="currency"
                    name="currency"
                    fullWidth={true}
                    defaultValue="EUR"
                    sx={{display: 'none'}}
                    validate={[required()]}
                />

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            source="netPrice"
                            name="netPrice"
                            fullWidth={true}
                            validate={[required(), number()]}
                            InputProps={{startAdornment: (<InputAdornment position="start">€</InputAdornment>)}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            source="promoNetPrice"
                            name="promoNetPrice"
                            fullWidth={true}
                            validate={[number()]}
                            InputProps={{startAdornment: (<InputAdornment position="start">€</InputAdornment>)}}
                        />
                    </Grid>
                </Grid>
                <NumberInput
                    source="minInventory"
                    name="minInventory"
                    fullWidth={true}
                    min={1}
                    max={9999}
                    validate={[number()]}
                />
                <BooleanInput source="isActive" name="isActive" validate={[required()]}/>
            </SimpleForm>
        </CreateInDialogButton>
    );
};

export default MarketplaceAllocationCreateForm;
