import * as React from 'react';
import { SVGProps } from 'react';

export const Logo = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width={234.532}
            height={20.475}
            viewBox="0 0 841.89 118.6"
            {...props}
        >
            <g
                aria-label="Croozer Admin Panel"
                style={{
                    lineHeight: 1.25,
                }}
                fontWeight={400}
                fontSize={7.056}
                fontFamily="Permanent Marker"
                letterSpacing={0}
                wordSpacing={0}
                strokeWidth={0.265}
                fill="#808080"
            >
                <path
                    className="st0"
                    d="M306.47,0c-38.19,0-70.45,31.9-70.45,69.66c0,29.27,21.06,48.94,52.4,48.94c18.55,0,36.22-7.48,49.76-21.05   c13.15-13.19,20.69-30.85,20.69-48.45C358.86,19.73,337.81,0,306.47,0z M327.84,51.45c0,20.55-18.04,39.26-37.85,39.26   c-13.94,0-22.94-9.25-22.94-23.57c0-20.55,17.97-39.26,37.69-39.26C318.99,27.89,327.84,36.92,327.84,51.45z"
                />
                <path
                    className="st0"
                    d="M437.87,0c-38.19,0-70.45,31.9-70.45,69.66c0,29.27,21.06,48.94,52.4,48.94c18.55,0,36.22-7.48,49.76-21.05   c13.15-13.19,20.69-30.85,20.69-48.45C490.27,19.73,469.21,0,437.87,0z M421.39,90.72c-13.94,0-22.94-9.25-22.94-23.57   c0-20.55,17.97-39.26,37.69-39.26c14.25,0,23.1,9.03,23.1,23.57C459.24,72,441.2,90.72,421.39,90.72z"
                />
                <path
                    className="st0"
                    d="M615.95,12.12c-1.01-5.36-5.55-9.55-10.74-9.48l-90.92-0.02l-7.89,25.24l60.81,0L486.44,96.3   c-2.64,2.51-3.8,6.38-3.06,10.23c1.3,6.75,7.32,9.54,10.53,9.54l90.98,0l7.92-25.25h-57.91l78.44-68.94   C615.76,19.47,616.63,15.74,615.95,12.12z"
                />
                <path
                    className="st0"
                    d="M830.81,10.66c-7.15-5.26-17.51-8.04-29.96-8.04H753.7l-35.58,113.45h32.7l12.74-40.99h13.34   c3.64,0,6.85,2.36,7.94,5.84l10.97,35.16h32.57L813.4,68.98c15.77-6.21,28.49-19.49,28.49-35.87   C841.89,23.76,838.06,15.99,830.81,10.66z M811.63,36.26c0,8.35-8.58,14.9-19.53,14.9h-20.92l7.64-24.61h20.62   C807.07,26.55,811.63,30.18,811.63,36.26z"
                />
                <path
                    className="st0"
                    d="M219.79,10.66c-7.15-5.26-17.51-8.04-29.96-8.04h-47.15L107.1,116.07h32.7l12.74-40.99h13.34   c3.64,0,6.85,2.36,7.94,5.84l10.97,35.16h32.57l-14.98-47.09c15.77-6.21,28.49-19.49,28.49-35.87   C230.87,23.76,227.04,15.99,219.79,10.66z M200.61,36.26c0,8.35-8.58,14.9-19.53,14.9h-20.92l7.64-24.61h20.62   C196.05,26.55,200.61,30.18,200.61,36.26z"
                />
                <path
                    className="st0"
                    d="M641.84,91.46l5.59-17.72c0.63-1.98,2.46-3.33,4.54-3.33h48.04l7.53-24.01l-51.61-0.12l4.83-15.71   c0.61-2.01,2.46-3.38,4.56-3.38h62.75l7.69-24.53l-83.8-0.03c-8.08,0-15.25,5.37-17.71,13.32l-31.41,100.12h97.35l7.72-24.61   H641.84z"
                />
                <path
                    className="st0"
                    d="M59.95,90.81c-16.55,0-26.17-7.98-26.17-22.58c0-20.96,20.43-40.38,42.84-40.38l40.29,0l7.9-25.2L84.7,2.62   C29.87,2.62,0,33.57,0,70.97c0,27.93,21.53,45.1,56.85,45.1l32.39,0l7.91-25.26H59.95z"
                />
            </g>
        </svg>
    );
};
