import * as React from 'react';
import { FC } from 'react';
import {
    FormDataConsumer,
    useTranslate,
    AutocompleteInput,
    NumberInput,
    TextInput,
    useGetList,
    AutocompleteArrayInput,
    minValue,
} from 'react-admin';
import { Alert, InputAdornment } from '@mui/material';
import {
    CategoryDictionaryItem,
    CouponTypeProps,
    ProductDictionaryItem,
} from '../../types';
import { Conditions } from '../conditionTypes';

export const styles = {
    halfWidth: { width: '50%' },
    shortInput: { width: '170px' },
};

const marked = [
    {
        id: '1',
        name: 'New',
    },
    {
        id: '2',
        name: 'Sale',
    },
];

const ConditionType: FC<CouponTypeProps> = props => {
    const translate = useTranslate();
    const { data: categories } = useGetList<CategoryDictionaryItem>('category');
    const { data: products } = useGetList<ProductDictionaryItem>('product');

    return (
        <FormDataConsumer>
            {({ formData }) => {
                switch (formData?.condition?.type) {
                    case Conditions.Product:
                        return (
                            <AutocompleteArrayInput
                                name="condition[product]"
                                source="condition[product]"
                                choices={
                                    products as Array<ProductDictionaryItem>
                                }
                                optionValue="sku"
                                optionText="nameWithSku"
                                sx={{ width: '50%' }}
                                label="resources.coupon.select_product"
                                clearOnBlur={false}
                                value={undefined}
                            />
                        );
                    case Conditions.Order:
                        return (
                            <NumberInput
                                name="condition[order]"
                                source="condition[order]"
                                label=""
                                hiddenLabel={true}
                                sx={{ width: '170px' }}
                                step={0.01}
                                validate={[minValue(0)]}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            position="end"
                                            variant={`filled`}
                                        >
                                            €
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            variant={`filled`}
                                        >
                                            {`<=`}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        );
                    case Conditions.Email:
                        return (
                            <>
                                <TextInput
                                    multiline
                                    source="condition[email]"
                                    name="condition[email]"
                                    sx={{ width: '50%' }}
                                    label="resources.coupon.email_list"
                                />
                                <Alert severity="info">
                                    {translate(
                                        'resources.coupon.emails_conditions_tip'
                                    )}
                                </Alert>
                            </>
                        );
                    case Conditions.Marked:
                        return (
                            <AutocompleteInput
                                source="condition[marked]"
                                choices={marked}
                                optionValue="id"
                                optionText="name"
                                sx={{ width: '50%' }}
                                label="resources.coupon.flag"
                                name="name"
                            />
                        );
                    case Conditions.Category:
                        return (
                            <AutocompleteArrayInput
                                name="condition[category]"
                                source="condition[category]"
                                choices={categories}
                                optionValue="id"
                                optionText="name"
                                sx={{ width: '50%' }}
                                label="resources.coupon.category"
                            />
                        );
                    default:
                        return (
                            <Alert severity="warning">
                                {translate(
                                    'resources.coupon.select_condition_type'
                                )}
                            </Alert>
                        );
                }
            }}
        </FormDataConsumer>
    );
};

export default ConditionType;
