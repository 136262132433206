import React from 'react';
import {Grid, Box, useMediaQuery, Theme} from '@mui/material';
import {useGetList} from 'react-admin';

import Welcome from './Welcome';
import {Logo} from '../layout/Logo';
import { useDefineAppLocation } from '@react-admin/ra-navigation';

const Dashboard = () => {
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    useGetList('products', {pagination: {page: 1, perPage: 1}});

    return isXSmall ? (
        <Grid container spacing={1}>
            <Box ml={1}>
                <Logo/>
            </Box>
            <Grid item xs={12}>
                <Welcome/>
            </Grid>
        </Grid>
    ) : isSmall ? (
        <Grid container spacing={2} padding={1}>
            <Box mt={1} ml={1} display="flex">
                <Logo/>
            </Box>
            <Grid item xs={12}>
                <Welcome/>
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2} padding={1}>
            <Box mt={2} ml={-3} display="flex">
                <Logo/>
            </Box>
            <Grid item xs={12}>
                <Welcome/>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
