import {
    mergeTranslations,
    TranslationMessages as BaseTranslationMessages,
} from 'react-admin';
import {RaTreeTranslationMessages} from '@react-admin/ra-tree';
import germanMessages from 'ra-language-german';

export interface TranslationMessages
    extends RaTreeTranslationMessages,
        BaseTranslationMessages {
}

const customGermanMessages: TranslationMessages = mergeTranslations(
    germanMessages,
    {
        ra: {
            saved_queries: {
                label: "Gespeicherte Filter"
            },
            configurable: {
                customize: 'Customize',
                configureMode: 'Configure this page',
                inspector: {
                    title: 'Inspector',
                    content: 'Hover the application UI elements to configure them',
                    reset: 'Reset Settings',
                    hideAll: 'Hide All',
                    showAll: 'Show All',
                },
                Datagrid: {
                    title: 'Datagrid',
                    unlabeled: 'Unlabeled column #%{column}',
                },
                SimpleForm: {
                    title: 'Form',
                    unlabeled: 'Unlabeled input #%{input}',
                },
                SimpleList: {
                    title: 'List',
                    primaryText: 'Primary text',
                    secondaryText: 'Secondary text',
                    tertiaryText: 'Tertiary text',
                },
            },
        }
    },
    {
        'ra-form-layout': {
            action: {
                bulk_update: ``,
            }
        },
        'ra-tree': {
            action: {
                add_root: 'Fügen Sie eine Produktkategorie hinzu',
            },
            item_moved: 'Artikel verschoben',
            new_node: 'Neuer Knoten',
        },
        'ra-search': {
            result: `1 result |||| %{smart_count} results`,
        },
        'ra-realtime': {
            notification: {
                lock: {
                    lockedBySomeoneElse: 'The record is locked by someone else',
                },
            },
        },
        pos: {
            search: 'Suche',
            configuration: 'Aufbau',
            language: 'Sprache',
            profile: 'Mein Profil',
            password: 'Passwort',
            logout: 'Ausloggen',
            theme: {
                name: 'Thema',
                light: 'Licht',
                dark: 'Dunkel',
                change_language: 'Sprache ändern',
            },
            dashboard: {
                monthly_revenue: 'Monatliche Einnahmen',
                month_history: '30 Tage Umsatzverlauf',
                new_orders: 'Neue Bestellungen',
                pending_reviews: 'Ausstehende Bewertungen',
                all_reviews: 'Alle Bewertungen ansehen',
                new_customers: 'neue Kunden',
                all_customers: 'Alle Kunden anzeigen',
                pending_orders: 'Ausstehende Bestellungen',
                order: {
                    items:
                        'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
                },
                welcome: {
                    title: 'Willkommen im Croozer Administrationsboard',
                    subtitle:
                        'Es dient der Verwaltung der Marktplätze.',
                    ra_button: 'react-admin site',
                    demo_button: 'Source for this demo',
                },
            },
            menu: {
                sales: 'Der Umsatz',
                catalog: 'Katalog',
                customers: 'Kunden',
            },
        },
    },
    {
        resources: {
            sales_menu_label: 'Verkäufe',
            catalog_menu_label: 'Katalog',
            back_in_stock: {
                main_menu_label: 'Wieder auf Lager',
                request_id: 'Anfrage ID',
                email: 'E-mail',
                requested_sku: 'Produkt SKU',
                product_name: 'Produktname',
                lang: 'Sprache',
                requested_at: 'Angefordert unter',
                notified_at: 'Benachrichtigt am',
                is_notified: 'Benachrichtigung',
                details_label: 'Anfrage Detail',
                notified: 'Benachrichtigt',
                notified_since: 'Benachrichtigung gesendet seitdem',
                notified_before: 'Benachrichtigung vorher gesendet',
                requested_since: 'Anfragen seitdem',
                requested_before: 'Anfragen vorher',
                is_outdated: 'Ist veraltet',
                yes: 'Ja',
                no: 'Nein',
                outdated: 'Veraltet',
            },
            server: {
                main_menu_label: 'Anwendungseinstellungen',
                cron: {
                    main_menu_label: 'Serverjobs',
                    page_title: 'Vorschauseite für Serverjobs',
                    page_list_title: 'Liste der Serverjobs',
                    when: 'Wenn',
                    add_new_period: 'Neuen Wartungszeitraum hinzufügen',
                    id: 'Serverjob id',
                    jobInfo: 'Serverjob info',
                    time: 'Zeitcode',
                    name: 'Serverjob name',
                    description: 'Serverjob Bezeichnung',
                    time_description: 'Zeit Bezeichnung',
                    delete_schedule_question:
                        'Möchten Sie dieses Element wirklich löschen?',
                    delete_schedule: 'Entfernen',
                    start: 'Startzeit',
                    end: 'Endzeit',
                    no_maintenance_periods: 'Noch keine Wartungsperioden definiert',
                    no_maintenance_periods_tip:
                        'Bitte verwenden Sie das Seitenformular, um ein neues Wartungsfenster für den Serverjob zu definieren.',
                    validation: {
                        start_end_comparison:
                            'Das Enddatum sollte immer nach dem Startdatum liegen',
                    },
                    created_successfully: 'Zeitraum erfolgreich erstellt',
                    schedules_remaining: 'Verbleibende Wartungszeiten',
                    state: 'Aktuellen Zustand',
                    running: 'LAUFEND',
                    stopped: 'GESTOPPT',
                    waiting: 'Warten',
                    archived: 'Archiviert',
                    active: 'Aktiv',
                    schedule_state: 'Zustand',
                    created_by_label: 'Erstellt von',
                },
            },
            order: {
                main_menu_label: 'Aufträge',
                is_sent_to_dealer: 'To dealer',
                is_sent_to_parcel_locker: 'To parcel locker',
                totalAmount: 'Gesamtpreis',
                status: 'Status',
                initiated_at: 'Eingeleitet bei',
                number: 'Bestellnummer',
                cart_reference_id: 'Warenkorb-Referenz-ID',
                discounted: 'Coupon',
                filters: {
                    headline: 'Filter',
                    status: 'Status',
                    email: 'Kunden-eMail',
                    yes: 'Ja',
                    no: 'Nein',
                    search: 'Suche',
                    tooltip:
                        'Geben Sie unten etwas ein, um Suchergebnisse basierend auf Kunden-E-Mail oder Bestellnummer zu erhalten.',
                },
                statuses: {
                    new: 'Neu',
                    payment_pending: 'Zahlung ausstehend',
                    payment_redirect: 'Zahlungsabwicklung',
                    payment_complete: 'Zahlung abgeschlossen',
                    paid: 'Bezahlt',
                    sent_to_select_line: 'Nach SL exportiert',
                    fully_refunded: 'Vollständig rückerstattet',
                    partially_refunded: 'Teilweise erstattet',
                    ordered: 'Bestellt',
                    refund_details: 'Details zur Rückerstattung',
                    refunded_items: 'Artikel zurückerstattet',
                    refunded_at: 'Rückerstattung bei',
                },
                show: {
                    title: 'Aufträge %{reference}',
                    headline: 'Aufträge',
                    shipping_same_as_billing: 'Versand wie Rechnung',
                    none: '-',
                    billing_address: 'Rechnungsadresse',
                    shipping_address: 'Lieferadresse',
                    dealer_address: 'Händleradresse',
                    items: 'Bestellte Produkte',
                    product_name: 'Name',
                    product_sku: 'SKU',
                    product_price: 'Preis',
                    product_quantity: 'Menge',
                    product_total: 'Gesamt',
                    total: 'Summen',
                    products_subtotal: 'Zwischensumme',
                    shipping_cost: 'Versand',
                    order_total: 'Gesamt',
                    discount: 'Rabatt',
                    coupon: 'Coupon',
                    coupon_code: 'Coupon code',
                    free_product_sku: 'Kostenlose Produkt-SKU',
                    discount_amount: 'Menge',
                    applied_at: 'Beworben bei',
                    placed_at: 'Platziert bei',
                    sent_to_sl_at: 'Gesendet an Select Line at',
                    sl_customer_id: 'SL Kundennummer',
                    sl_order_number: 'SL Bestellnummer',
                    dealer_id: 'Händler-ID',
                    dhl_post_number: 'DHL Postleitzahl',
                    is_refunded: 'Wird erstattet?',
                },
                source: 'Verkaufskanal',
            },
            coupon: {
                main_menu_label: 'Gutscheine',
                filters: {
                    status: 'Status',
                    draft: 'Entwurf',
                    on_hold: 'In Wartestellung',
                    active: 'Aktiv',
                    deactivated: 'Deaktiviert',
                    operative: 'Operativ',
                    yes: 'Ja',
                    no: 'Nein',
                },
                validation: {
                    start_end_comparison:
                        'Das Enddatum sollte immer nach dem Startdatum liegen',
                },
                create_coupon: 'Neuen Gutschein erstellen',
                base_data_label: 'Beschreibung',
                name: 'Name',
                description: 'Beschreibung',
                period_of_time: 'Gültigkeitszeitraum',
                base: 'Grundinformation',
                conditions: 'Bedingungen',
                target: 'Kundenvorteil',
                codes: 'Codes',
                order_min_value: 'Mindestwert bestellen',
                order_value: 'Wert',
                mandatory_product: 'Pflichtprodukt',
                condition_request: 'Wenn',
                product: 'Produkt',
                target_type: 'Vorteilsart',
                condition_type: 'Anwenden auf',
                free_product: 'Kostenloses Produkt',
                money: 'Rabatt in €',
                percentage: 'Rabatt in %',
                discount_value: 'Zielwert',
                percentage_value: 'Zielwert',
                amount_value: 'Höhe des Rabatts',
                select_target_type: 'Wählen Sie zuerst den Zieltyp aus',
                select_condition_type: 'Wählen Sie zuerst die Konditionsart aus',
                order: 'Auftragssumme',
                email: 'Email',
                category: 'Kategorie',
                marked: 'Markierte Produkte',
                select_product: 'Ausgewähltes Produkt',
                flag: 'Flagge',
                email_list: 'Liste der E-Mails',
                code: 'Code',
                new_codes_list: 'Fügen Sie unten neuen Code hinzu',
                code_generator_description:
                    'Bitte geben Sie an, wie viele Codes Sie benötigen, wie lang sie sein sollen (Anzahl der Zeichen) und die Art des Limits.',
                emails_conditions_tip:
                    'Bitte geben Sie eine E-Mail-Adresse pro Zeile ein.',
                code_generator: 'Batch-Code-Generator',
                generate_batch: 'Codes automatischer Generator',
                codes_quantity: 'Menge an Codes',
                create: 'Schaffen',
                one_time_use: 'einmalige Verwendung',
                all_one_time_use: 'einmalige Verwendung',
                close: 'Schließen',
                generate: 'Generieren',
                updated_successfully: 'Gutschein erfolgreich aktualisiert',
                list_of_coupons: 'Liste der Coupons',
                edit_page_title: 'Coupon Details',
                status: {
                    label: 'Status',
                    list_label: 'Statusliste',
                    draft: 'Entwurf',
                    active: 'Aktiv',
                    on_hold: 'In Wartestellung',
                    deactivated: 'Deaktiviert',
                    draft_details:
                        'Die erste Ebene des Gutscheins. Im Entwurfsstatus können Sie die meisten Felder leer lassen.',
                    active_details:
                        'Der wichtigste Zustand. Dieser Status ermöglicht es dem Kunden, Gutscheincodes zwischen dem Start- und Enddatum zu verwenden. Wenn Sie aktiv sein möchten, müssen Sie fast alle Gutscheinfelder ausfüllen und mindestens einen Gutscheincode hinzufügen.',
                    on_hold_details:
                        'Verwenden Sie diesen, um Gutscheincodes für eine Weile auszusetzen.',
                    deactivated_details:
                        'Letzte Stufe des Gutscheins. Wenn Sie dies eingerichtet haben, gibt es kein Zurück mehr.',
                },
                create_new: 'Neu',
                start_date: 'Von',
                end_date: 'Bis',
                delete_coupon: '%{name} löschen',
                delete_coupon_question:
                    'Möchten Sie diesen Coupon wirklich löschen?',

                start_date_label: 'Startdatum',
                created_by_label: 'Erstellt von',
                info: {
                    label: 'Info',
                    created_at_label: 'Hergestellt in',
                    updated_by_label: 'Zuletzt aktualisiert von',
                    updated_at_label: 'Zuletzt aktualisiert um',
                },
                report: {
                    label: 'Prüfbericht',
                    created_by: 'Erstellt von',
                    one_time_use: 'einmalige Verwendung',
                    is_used: 'Gebraucht',
                    is_active: 'Aktiv',
                    date_of_last_usage: 'Letzte Nutzung um',
                    added_to_cart_at: 'Gebraucht bei',
                    cart_reference_id: 'Warenkorb-Referenz-ID',
                    is_redeemed: 'Wird eingelöst',
                    order_number: 'Bestellnummer',
                    discount_amount: 'Rabattbetrag',
                    nothing_yet: 'Keine Einträge vorhanden',
                    order_placed_at: 'Bestellung aufgegeben bei',
                },
            },
            products: {
                main_menu_label: 'Produkte',
                name: 'Product |||| Products',
                fields: {
                    category_id: 'Category',
                    height_gte: 'Min height',
                    height_lte: 'Max height',
                    height: 'Height',
                    image: 'Image',
                    price: 'Price',
                    reference: 'Reference',
                    sales: 'Sales',
                    stock_lte: 'Low Stock',
                    stock: 'Stock',
                    thumbnail: 'Thumbnail',
                    width_gte: 'Min width',
                    width_lte: 'Max width',
                    width: 'Width',
                },
                tabs: {
                    image: 'Image',
                    details: 'Details',
                    description: 'Description',
                    reviews: 'Reviews',
                },
                filters: {
                    categories: 'Kategorien',
                    stock: 'Inventar',
                    no_stock: 'Ausverkauft',
                    low_stock: '1 - 9 items',
                    average_stock: '10 - 49 items',
                    enough_stock: '50 items & more',
                    sales: 'Sales',
                    best_sellers: 'Best sellers',
                    average_sellers: 'Average',
                    low_sellers: 'Low',
                    never_sold: 'Never sold',
                    is_retail_ready: 'Einzelhandel bereit?',
                    is_wholesale_ready: 'Großhandel bereit?',
                    is_bundle: 'Ist Bundle?',
                    untagged: 'Nur ohne Tags',
                    stock_balance: 'Lagerbestand',
                    phased_out_date: 'Auslaufdatum',
                    included_archived: 'Archivierte einbeziehen',
                    reset_filters: 'Filter zurücksetzen',
                    search_phrase: 'Suche',
                    stock_balance_filter: 'Lager ist',
                },
                list: {
                    archive: 'Archiv',
                    name: 'Produktname',
                    memo: 'Memo',
                    retail_ready: 'Einzelhandel',
                    wholesale_ready: 'Großhandel',
                    is_bundle: 'Bundle',
                    net_price: 'Nettopreis',
                    update_marketplace: 'Marktplatz',
                    operation: 'Operationstyp',
                    attach: 'Anfügen',
                    detach: 'Ablösen',
                    update_marketplace_dialog_title: 'Aktualisierung der Marktplatzzuteilung',
                    marketplace_update_attach_hint: 'Die Produkte werden mit dem Preis, der aktuell für den Standardmarktplatz gilt, zum Marktplatz hinzugefügt.',
                    marketplace_update_detach_hint: 'Wählen Sie den Marktplatz, von dem die Produkte entfernt werden sollen.',
                    update_tags: 'Tags',
                    update_tags_dialog_title: 'Zuweisung von Tags',
                    bulk_delete_content:
                        'Sind Sie sicher, dass Sie dieses Produkt archivieren möchten? |||| Sind Sie sicher, dass Sie diese %{smart_count} Elemente archivieren möchten?',
                    stock: 'Lager',
                    phasingOutDate: 'Auslaufdatum',
                    oldUrl: 'URL',
                    distriDiscount: 'Distri Rabatt',
                    regularDiscount: 'Fachhändlerrabbatt',
                    ean: 'EAN/UPC',
                    tags: 'Tags',
                    sku: 'SKU',
                    color: 'Farbe',
                    size: 'Sitze/Größe',
                    isDealerDedicated: 'Händlersuche',
                    flag: 'Flagge',
                },
                flags: {
                    new: 'Neu',
                    isComingSoon: 'Demnächst',
                },
                show: {
                    color: 'Farbe',
                    size: 'Sitze/Größe',
                    base: 'Grundinformation',
                    sku: 'SKU',
                    ean: 'EAN',
                    price: 'Preis',
                    categories: 'Kategorien',
                    early_bird: 'Ist early bird?',
                    retail_ready: 'Einzelhandel',
                    wholesale_ready: 'Großhandel',
                    is_bundle: 'Bundle',
                    net_price: 'Nettopreis',
                    regular_discount: 'Fachhändlerrabbatt',
                    distributor_discount: 'Distri Rabatt',
                    prices: 'Preisliste',
                    marketplace_allocations: 'Marktplatzzuteilungen',
                    marketplace: 'Marktplatz',
                    rrp_message: 'Unverbindliche Preisempfehlung (Nicht für Update)',
                    promo_price: 'Aktionsnettopreis',
                    marketplace_allocation: 'Produkt im Marktplatz zuordnen',
                    full_price: 'Aktueller Preis inkl. MwSt',
                    price_error_format: 'Falsches Preisformat. Beispiel: 12.34 oder 9.34562',
                    manuals: 'Benutzerhandbuch',
                    phased_out_date: 'Auslaufdatum',
                    product_attributes: 'Technische Daten',
                    product_content: 'Produktbeschreibung',
                    attribute_name: 'Attributname',
                    attribute_value: 'Attributwert',
                    product_media: 'Produktmedien',
                    select_attribute_first: 'Wählen Sie zuerst ein Attribut aus',
                    marketplace_name: 'Marktplatzname',
                    min_inventory: 'Mindestbestand',
                    is_active: 'Ist aktiv',
                    download_qr_code_file: 'Datei mit QR-Code herunterladen',
                    manual_language: 'Sprache',
                    manual_type: 'Typ',
                    manual_source_file: 'Quelldatei',
                    manual_scans: 'Scannt',
                    manual_file_delete_title: 'Die Datei wird entfernt',
                    attribute_desc: 'Beschreibung',
                    image_position_updated: 'Bildposition aktualisiert',
                    accepted_files_format: 'Sie können diese unterstützten Dateitypen hochladen: webp, jpeg, jpg, png, mp4',
                    accepted_files_format_colors_size: 'Sie können diese unterstützten Dateitypen hochladen: png, jpeg, jpg, svg',
                    color_image: 'Farbbilddarstellung',
                    size_image: 'Größenbilddarstellung',
                    update_colour_image_title: 'Farbbilddarstellung aktualisieren',
                    update_size_image_title: 'Bilddarstellung in Größe aktualisieren',
                    add_colour_image_title: 'Fügen Sie eine Farbbilddarstellung hinzu',
                    add_size_image_title: 'Größe der Bilddarstellung hinzufügen',
                    compatible_trailers: 'Kompatible Anhänger',
                    compatible_accessories: 'Kompatible Zubehör',
                    compatible_spare_parts: 'Kompatible Ersatzteile',
                    content: {
                        name: 'Name',
                        description: 'Beschreibung',
                        short_description: 'Kurzbeschreibung',
                        delivery_time: 'Lieferzeit',
                        meta_title: 'Meta-Titel',
                        meta_description: 'Meta-Beschreibung',
                        size: 'Größe',
                        slug: 'Slug',
                        url: 'Alte URL',
                        gallery: 'Galerie',
                        image: 'Bild',
                        title: 'Titel',
                        color_image: 'Farbbild',
                        size_image: 'Größenbild',
                        video: 'Video',
                    },
                    variants: {
                        add_new_variant: 'Neue Variante hinzufügen',
                        section_name: 'Varianten',
                        name: 'Name',
                        color: 'Farbe',
                        size: 'Größe',
                        attribute: 'Attribut',
                        sku: 'SKU',
                        variant: 'Variante',
                        variant_attribute: 'Variante Attribut',
                        variant_value: 'Variantenattributwert'
                    },
                    matched: {
                        add_new_product: 'Fügen Sie neue kompatible Produkte hinzu',
                        section_name: 'Kompatibilitäten',
                        name: 'Name',
                        sku: 'SKU',
                        product: 'Produkt',
                        products: 'Produkte',
                        bulk_delete_content:
                            'Sind Sie sicher, dass Sie dieses Produkt lösen möchten? |||| Sind Sie sicher, dass Sie diese %{smart_count} Elemente lösen möchten?',
                    },
                },
                aside: {
                    stocks_headline: 'Lager',
                    sale_ready_stock: 'Verkaufsbereiter Bestand',
                    purchase_order_headline: 'Bestellliste',
                    arrival_date: 'Ankunftsdatum',
                    purchase: 'Bestellung',
                    arrival: 'WE-Vorschlag',
                    ordered_items: 'Artikel bestellt: %{smart_count}',
                    remaining_items: 'Restposten: %{smart_count}',
                    available_items: 'Verfügbare Artikel: %{smart_count}',
                    settings_headline: 'Einstellungen',
                }
            },
            user: {
                name: 'Benutzer',
                create_user: 'Neuen Benutzer erstellen',
                warning: 'Achtung',
                something_went_wrong: 'Something went wrong.',
                fields: {
                    fullName: 'Vollständiger Name',
                    firstName: 'Vorname',
                    lastName: 'Name',
                    phone: 'Telefon',
                    roleName: 'Rollenname',
                    email: 'Email',
                    accessRole: 'Berechtigungsrolle',
                    newPassword: 'Neu Passwort',
                    oldPassword: 'Altes Passwort',
                    repeatNewPassword: 'Neu Passwort wiederholen',
                    switchOn: 'Ein',
                    switchOff: 'Aus',
                },
                fieldGroups: {
                    identity: 'Identität',
                    change_password: 'Kennwort ändern',
                    contact: 'Kontakt',
                    access_role: 'Zugriffsrolle',
                    password: 'Passwort',
                    mfa: 'Zwei-Faktor-Authentifizierung',
                },
                validation: {
                    password_dont_match: 'Passwort stimmt nicht überein',
                },
                updated_successfully: 'Benutzer erfolgreich aktualisiert',
                created_successfully: 'Benutzer erfolgreich erstellt',
                force_mfa: 'Multi-Faktor-Authentifizierung erzwingen',
                force_mfa_success: 'Multi-Faktor-Authentifizierung gezwungen',
                force_mfa_error: 'Multi-Faktor-Authentifizierung Fehler',
                you_need_to_setup_mfa:
                    'Einrichtung der Multi-Faktor-Authentifizierung.',
                mfa_headline_subtitle:
                    'Zusätzlich zu Deinem Passwort wird ein Sicherheitscode verlangt. Du benötigst dafür eine App (z.B. Authy oder Google Authenticator) und Dein Mobiltelefon um den QR Code zu scannen.',
                mfa_dialog_title: 'Zwei-Faktor-Authentifizierung',
                disable_mfa_dialog_title: 'Ausschalten',
                disable_mfa_dialog_content:
                    'Bist Du Dir sicher, dass Du das registrierte Gerät entfernen und die Zwei-Faktor-Authentifizierung deaktivieren möchtest?',
                disable_mfa_disallowed:
                    'Du kannst diese Einstellung nicht aktualisieren.',
                disable_mfa_disallowed_2:
                    'Du kannst die Einstellung für die Zwei-Faktor-Authentifizierung nicht deaktivieren.',
                six_digits_validation:
                    'Du musst 6 Ziffern eingeben, um ein Gerät hinzuzufügen.',
                type_code_below:
                    'Verwenden Sie Ihre mobile App, um den Authentifizierungscode zu erhalten, und geben Sie ihn unten ein.',
                verify_code: 'Verifizieren',
                mfa_dialog_subtitle:
                    'Du benötigst hierfür eine Google Authentifikations App um den Prozess zu abzuschließen:',
                scanQrCode: 'Scanne den QR Code mit Hilfe der App',
                enterSixDigitCode:
                    'Gib den von Deiner App generierten 6-stelligen Code in die Felder ein:',
                mobileAppList: 'Liste von mobilen Apps',
                addDevice: 'Füge das Gerät hinzu',
            },
            category: {
                edit: {
                    tree_label: 'Baum',
                    tree: 'Baumvorschau',
                    internal_desc: 'Beschreibung (nur interner Zweck)',
                    is_default: 'Ist Standard',
                    edit_tree: 'Baum bearbeiten',
                    add_tree: 'Neuen Baum hinzufügen',
                    new_category_dialog_title: 'Neue Kategorie hinzufügen',
                    new_child_node: 'Neue Knoten',
                    missing_tree: 'Der Baum für diese Kategorie fehlt.',
                    associated_products: 'Zugehörige Produkte',
                    remove_below_category: 'Untere Kategorie entfernen:',
                    name: 'Name',
                    title: 'Titel',
                }
            },
            marketplace: {
                main_menu_label: 'Marktplätze',
                edit: {
                    category_list_title: 'Liste der Kategorien',
                    category_id: 'Kategorie ID',
                    category_name: 'Kategoriename',
                    category_code: 'Code',
                    category_default: 'Ist Standard',
                    name: 'Name',
                    country: 'Landesvorwahl',
                    description: 'Beschreibung',
                    is_active: 'Ist aktiv',
                    list_of_products: 'Liste der zugeordneten Produkte',
                    product_sku: 'SKU',
                    product: 'Produkt',
                    net_price: 'Nettopreis',
                    stock: 'Lager',
                    promo_net_price: 'Aktionsnettopreis',
                    min_inventory: 'Mindestbestand',
                    req_completion_percentage: 'Ausfüllen der Anforderungsfelder %',
                    opt_completion_percentage: 'Ausfüllen optionaler Felder %',
                },
                fields: {
                    name: 'Marktplatzname',
                    proxy: 'Proxy',
                    country_code: 'Landesvorwahl',
                    is_active: 'Ist aktiv',
                    is_retail: 'Ist dem Einzelhandel gewidmet',
                }
            },
            attributes: {
                main_menu_label: 'Produkteigenschaften',
                edit: {
                    default_marketplace_note: 'Sie sind derzeit dabei, ein Attribut zu bearbeiten, das mit einem der Marktplätze verbunden ist: %{marketplace}. Dieses Attribut stammt direkt aus der %{marketplace}-Infrastruktur. Bitte beachten Sie, dass bestimmte Attribute, die von externen Diensten stammen, in begrenztem Umfang bearbeitet werden können, hauptsächlich um die Datenkonsistenz aufrechtzuerhalten. Sie haben jedoch weiterhin die Möglichkeit, dieses Attribut auf einem anderen Marktplatz zu verwenden.',
                    list_of_options: 'Liste der Optionen',
                    add_new_option: 'Neue Option hinzufügen',
                    value: 'Wert',
                },
                fields: {
                    name: 'Name',
                    description: 'Beschreibung',
                    unit: 'Einheit',
                    validation_rules: 'Validierungsregeln',
                    marketplaces: 'Marktplätze',
                    value_type: 'Werttyp',
                    value_validations: 'Wertvalidierungen',
                    has_closed_options: 'Hat geschlossene Optionen',
                    origin_marketplace: 'Ursprungsmarktplatz',
                    tip: 'Tipp',
                }
            },
            reports: {
                main_menu_label: 'Berichte',
                products_allocations_main_menu_label: 'Produktzuordnungen',
                allocations: {
                    all_tab: 'Alle',
                }
            },
            dealers: {
                main_menu_label: 'Händler',
                list: {
                    name: 'Name',
                    selectLineId: 'Nummer',
                    isServicePartner: 'Servicepartner',
                    country: 'Land',
                    groupNumber: 'Group',
                    industry: 'Line',
                    isActive: 'Ist aktiv',
                    creationDate: 'Erstellungsdatum',
                    city: 'Stadt',
                    street: 'Straße',
                    zipCode: 'PLZ',
                    phoneNumber1: 'Telefonnummer',
                    website: 'Website',
                    isDocumentLock: 'Ist gesperrt',
                    bulk_delete_content:
                        'Sind Sie sicher, dass Sie dieses Händler lösen möchten? |||| Sind Sie sicher, dass Sie diese %{smart_count} Elemente lösen möchten?',
                    archive: 'Archiv',
                    addressLine1: 'Zusatz',
                    addressLine2: 'Zusatz 2',
                    assortment: 'Sortiment',
                },
                show: {
                    name: 'Name',
                    selectLineId: 'Nummer',
                    isServicePartner: 'Servicepartner',
                    country: 'Land',
                    groupNumber: 'Group',
                    industry: 'Line',
                    isActive: 'Ist aktiv',
                    creationDate: 'Erstellungsdatum',
                    city: 'Stadt',
                    street: 'Straße',
                    zipCode: 'PLZ',
                    phoneNumber1: 'Telefonnummer',
                    website: 'Website',
                    isDocumentLock: 'Ist gesperrt',
                    bulk_delete_content:
                        'Are you sure you want to archive this %{name}? |||| Are you sure you want to archive these %{smart_count} items?',
                    archive: 'Archiv',
                    addressLine1: 'Zusatz',
                    addressLine2: 'Zusatz 2',
                    tax: 'Tax ID',
                    language: 'Sprache',
                    isPrivatePerson: '',
                    lockReason: 'Sperrgrund',
                    address_contact: 'Adresse & Kontakt',
                    locations: 'Filiare',
                    phoneNumber2: 'Telefonnummer 2',
                    phoneNumber3: 'Telefonnummer 3',
                    phoneNumber4: 'Telefonnummer 4',
                    email: 'E-mail',
                    email2: 'E-mail 2',
                    faxNumber: 'Faxnummer',
                    address: 'Adresse',
                    contact: 'Kontakt',
                },
                address: {
                    selectLineId: 'Nummer ',
                    firstName: 'Vorname',
                    lastName: 'Name',
                    country: 'Land',
                    isDefault: 'Ist Standard',
                    city: 'Stadt',
                    street: 'Straße',
                    zipCode: 'PLZ',
                    phoneNumber1: 'Telefonnummer',
                    phoneNumber2: 'Telefonnummer 2',
                    phoneNumber3: 'Telefonnummer 3',
                    phoneNumber4: 'Telefonnummer 4',
                    website: 'Website',
                    addressLine1: 'Zusatz',
                    addressLine2: 'Zusatz 2',
                    addressLine3: 'Zusatz 3',
                    pickup_point: 'Abholpunkt',
                    service_point: 'Servicepunkt',
                    functions: 'Funktionen',
                    function: 'Funktion',
                    department: 'Abteilung',
                    salutation: 'Anrede',
                    latitude: 'Geo Breitengrad',
                    longitude: 'Geo Längengrad',
                    email: 'E-mail',
                    email2: 'E-mail 2',
                    faxNumber: 'Faxnummer',
                },
                filters: {
                    included_archived: 'Archivierte einbeziehen',
                    reset_filters: 'Filter zurücksetzen',
                    search_phrase: 'Suche',
                },
                aside: {
                    internal_note_headline: 'Interne Notiz',
                    lastOrderPlaced: 'Letzte Bestellung',
                    lastHalfYearStats: 'Statistik der letzten 6 Monate',
                    stats: 'Statistik',
                    productTypes: 'Produkttypen',
                    stats_info: 'Hinweis: Die Statistiken basieren auf Bestellungen der letzten 180 Tage.',
                    assortment_info: 'Beispiel: \'Kid (80%)\' bedeutet, dass 42% aller gekauften Artikel für Kinder bestimmt waren.',
                    type_info: 'Beispiel: \'Trailers (15/45)\' bedeutet, dass der Händler in den letzten 180 Tagen 82 Artikel gekauft hat, darunter 9 Anhänger.',
                },
            },
        },
    }
);

export default customGermanMessages;
