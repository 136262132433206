import * as React from 'react';
import { Chip } from '@mui/material';
import { useTranslate } from 'react-admin';
import Tooltip from '@mui/material/Tooltip';

const RefundedProductItemChip = () => {
    const translate = useTranslate();

    return (
        <Tooltip
            title={translate(
                'resources.order.statuses.shipping_refund_description'
            )}
        >
            <Chip
                color="error"
                size="small"
                key="shipping_refund_item"
                label={translate(
                    'resources.order.statuses.shipping_refund_label'
                )}
            />
        </Tooltip>
    );
};

export default RefundedProductItemChip;
