import * as React from 'react';
import {Box, Card, CardHeader, CardContent, Typography} from '@mui/material';
import {
    DateField,
    EditButton,
    NumberField,
    TextField,
    BooleanField,
    useTranslate,
    useListContext,
    RecordContextProvider, FunctionField,
} from 'react-admin';

import {Order, ProductMarketplaceAllocation} from '../types';
import {formatPrice, USDFormat} from '../formatUtils';

const MobileGrid = () => {
    const {data, isLoading} = useListContext<Order>();
    const translate = useTranslate();
    if (isLoading || data.length === 0) {
        return null;
    }
    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card sx={{margin: '0.5rem 0'}}>
                        <CardHeader
                            title={<TextField source="product.name" variant="body1"/>}
                            titleTypographyProps={{variant: 'body1'}}
                            action={false}
                        />
                        <CardContent sx={{pt: 0}}>
                            <Typography variant="body2" gutterBottom>
                                {translate('resources.marketplace.edit.product_sku')}
                                :&nbsp;
                                <TextField source="product.sku"/>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {translate('resources.marketplace.edit.net_price')}
                                :&nbsp;
                                <FunctionField
                                    source="netPrice"
                                    label="resources.marketplace.edit.net_price"
                                    textAlign={`right`}
                                    render={(price: ProductMarketplaceAllocation) => formatPrice(price.netPrice, price.currency, 2, 5)}
                                />
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {translate('resources.products.show.promo_price')}
                                :&nbsp;
                                <FunctionField
                                    source="promoNetPrice"
                                    label="resources.products.show.promo_price"
                                    textAlign={`right`}
                                    render={(price: ProductMarketplaceAllocation) => price.promoNetPrice ? formatPrice(price.promoNetPrice, price.currency, 2, 5) : 'n/a'}
                                />
                            </Typography>
                            <Typography variant="body2">
                                {translate('resources.marketplace.edit.stock')}
                                :&nbsp;
                                <NumberField source="stock" label="resources.marketplace.edit.stock"
                                             sortable={false}/>
                            </Typography>
                        </CardContent>
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
