import * as React from 'react';
import {useState} from 'react';
import {Card, CardContent} from '@mui/material';
import {
    BooleanField,
    Datagrid,
    DateField,
    TextField,
    useRecordContext,
    NumberField,
    FunctionField,
    Link,
    Pagination,
    ListContextProvider,
    useGetList,
    SortPayload
} from 'react-admin';

const CodeItemShow = () => {
    const record = useRecordContext();

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const sort: SortPayload = {field: 'placedAt', order: 'ASC'};
    const {data, total, isLoading} = useGetList(
        `code/${record?.id ?? 0}/associations`,
        {
            pagination: {page, perPage},
            sort,
        }
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Card sx={{width: '100%', padding: 'none', border: 'none'}}>
            <CardContent>
                <ListContextProvider
                    // @ts-ignore
                    value={{
                        data: data as any[],
                        total: total as number,
                        page,
                        perPage,
                        setPage,
                        sort,
                        setPerPage,
                    }}
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="id" sortable={false}/>
                        <DateField
                            source="createdAt"
                            label="resources.coupon.report.added_to_cart_at"
                            locales="de-DE"
                            options={{
                                weekday: undefined,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            }}
                            sortable={false}
                        />
                        <DateField
                            source="placedAt"
                            label="resources.coupon.report.order_placed_at"
                            locales="de-DE"
                            options={{
                                weekday: undefined,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            }}
                            sortable={true}
                        />
                        <NumberField
                            source="orderId"
                            label="resources.coupon.report.cart_reference_id"
                            sortable={false}
                            options={{useGrouping: false}}
                        />
                        <BooleanField
                            source="redeemed"
                            label="resources.coupon.report.is_redeemed"
                            sortable={false}
                        />
                        <FunctionField
                            source="orderNumber"
                            label="resources.coupon.report.order_number"
                            sortable={false}
                            render={(record: any) => (
                                <Link to={`/order/${record?.orderId}/show`}>
                                    {record?.orderNumber}
                                </Link>
                            )}
                        />
                        <FunctionField
                            source="discountAmount"
                            label="resources.coupon.report.discount_amount"
                            sortable={false}
                            render={(record: any) =>
                                record.discountAmount.toLocaleString('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    currencySign: 'accounting',
                                })
                            }
                        />
                    </Datagrid>
                    <Pagination/>
                </ListContextProvider>
            </CardContent>
        </Card>
    );
};

export default CodeItemShow;
