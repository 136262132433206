import {
    mergeTranslations,
    TranslationMessages as BaseTranslationMessages,
} from 'react-admin';
import englishMessages from 'ra-language-english';
import {RaTreeTranslationMessages} from '@react-admin/ra-tree';
import {raAuditLogLanguageEnglish} from '@react-admin/ra-audit-log';
import {raTreeLanguageEnglish} from '@react-admin/ra-tree';

export interface TranslationMessages
    extends RaTreeTranslationMessages,
        BaseTranslationMessages {
}

const customEnglishMessages: TranslationMessages = mergeTranslations(
    englishMessages,
    raAuditLogLanguageEnglish,
    raTreeLanguageEnglish,
    {
        'ra-form-layout': {
            action: {
                bulk_update: '',
            }
        },
        'ra-tree': {
            action: {
                add_root: 'Add a category of products',
            },
        },
        'ra-search': {
            result: `1 result |||| %{smart_count} results`,
        },
        'ra-realtime': {
            notification: {
                lock: {
                    lockedBySomeoneElse: 'The record is locked by someone else',
                },
            },
        },
        ra: {
            saved_queries: {
                label: 'Saved filters',
            }
        },
        pos: {
            profile: 'Profile',
            search: 'Search',
            configuration: 'Configuration',
            language: 'Language',
            change_language: 'Change language',
            theme: {
                name: 'Theme',
                light: 'Light',
                dark: 'Dark',
                change_theme: 'Change theme',
            },
            filter: 'Filtered by',
            dashboard: {
                monthly_revenue: 'Monthly Revenue',
                month_history: '30 Day Revenue History',
                new_orders: 'New Orders',
                pending_reviews: 'Pending Reviews',
                new_customers: 'New Customers',
                pending_orders: 'Pending Orders',
                all_customers: 'All Customers',
                all_reviews: 'All reviews',
                order: {
                    items: 'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
                },
                timeline: 'Timeline',
                welcome: {
                    title: 'Welcome to the Croozer Management System',
                    subtitle: 'This is the very basic administration panel.',
                    tour_button: 'Take the tour',
                    ra_button: 'react-admin enterprise edition site',
                    demo_button: 'See the showcase',
                    github_button: 'See the source code',
                },
            },
            menu: {
                sales: 'Sales',
                catalog: 'Catalog',
                my_queries: 'My queries',
                customers: 'Customers',
                new_customers: 'New Customers',
                all_customers: 'All Customers',
                visitors: 'Visitors',
                all_reviews: 'All reviews',
                pending_reviews: 'Pending reviews',
                bad_reviews: 'Bad reviews',
            },
            reviews: {
                accepted: 'Accepted',
                rejected: 'Rejected',
                pending: 'Pending',
            },
        }
    },
    {
        resources: {
            sales_menu_label: 'Sales',
            catalog_menu_label: 'Catalog',
            back_in_stock: {
                main_menu_label: 'Back in stock',
                request_id: 'Request ID',
                email: 'Email',
                requested_sku: 'Product SKU',
                product_name: 'Product name',
                lang: 'Language',
                requested_at: 'Requested at',
                notified_at: 'Notified at',
                is_notified: 'Notified',
                details_label: 'Request details',
                notified: 'Notified',
                notified_since: 'Notification sent since',
                notified_before: 'Notification sent before',
                requested_since: 'Requests since',
                requested_before: 'Requests before',
                is_outdated: 'Is outdated',
                yes: 'Yes',
                no: 'No',
                outdated: 'Outdated',
            },
            server: {
                main_menu_label: 'Application settings',
                cron: {
                    main_menu_label: 'Server jobs',
                    page_title: 'Server job preview page',
                    page_list_title: 'Server jobs list',
                    when: 'When',
                    add_new_period: 'Add new maintenance period',
                    id: 'Job id',
                    jobInfo: 'Job info',
                    time: 'Time code',
                    name: 'Job name',
                    description: 'Job description',
                    time_description: 'Time description',
                    delete_schedule_question:
                        'Are you sure you want to delete this item?',
                    delete_schedule: 'Remove',
                    start: 'Start time',
                    end: 'End time',
                    no_maintenance_periods: 'No maintenance periods defined yet',
                    no_maintenance_periods_tip:
                        'Please use side form to define new maintenance window for the server job.',
                    validation: {
                        start_end_comparison:
                            'End datetime should be always later than start date',
                    },
                    created_successfully: 'Period created successfully',
                    schedules_remaining: 'Maintenance periods remaining',
                    state: 'Current state',
                    running: 'RUNNING',
                    stopped: 'STOPPED',
                    waiting: 'Waiting',
                    archived: 'Archived',
                    active: 'Active',
                    schedule_state: 'State',
                    created_by_label: 'Created by',
                },
            },
            order: {
                main_menu_label: 'Orders',
                is_sent_to_dealer: 'To dealer',
                is_sent_to_parcel_locker: 'To parcel locker',
                totalAmount: 'Total price',
                status: 'Status',
                initiated_at: 'Initiated at',
                number: 'Number',
                cart_reference_id: 'Cart reference id',
                discounted: 'Is discounted',
                filters: {
                    headline: 'Filters',
                    status: 'Status',
                    email: 'Customer email',
                    yes: 'Yes',
                    no: 'No',
                    search: 'Search',
                    tooltip:
                        'Type below something to get search results based on customer e-mail or order number.',
                },
                statuses: {
                    new: 'New',
                    payment_pending: 'Payment pending',
                    payment_redirect: 'Payment processing',
                    payment_complete: 'Payment Complete',
                    paid: 'Paid',
                    sent_to_select_line: 'Exported to SL',
                    fully_refunded: 'Fully refunded',
                    partially_refunded: 'Partially refunded',
                    ordered: 'Ordered',
                    refund_details: 'Refund details',
                    refunded_items: 'Items refunded',
                    refunded_at: 'Refunded at',
                },
                show: {
                    title: 'Order %{reference}',
                    headline: 'Order',
                    shipping_same_as_billing: 'Shipping same as billing',
                    none: '-',
                    billing_address: 'Billing address',
                    shipping_address: 'Shipping address',
                    dealer_address: 'Dealer address',
                    items: 'Ordered products',
                    product_name: 'Name',
                    product_sku: 'SKU',
                    product_price: 'Price',
                    product_quantity: 'Quantity',
                    product_total: 'Total',
                    total: 'Totals',
                    products_subtotal: 'Subtotal',
                    shipping_cost: 'Shipping',
                    order_total: 'Total',
                    discount: 'Discount',
                    coupon: 'Coupon',
                    coupon_code: 'Coupon code',
                    free_product_sku: 'Free product sku',
                    discount_amount: 'Amount',
                    applied_at: 'Applied at',
                    placed_at: 'Placed at',
                    sent_to_sl_at: 'Sent to Select Line at',
                    sl_customer_id: 'Select Line customer id',
                    sl_order_number: 'Select Line order number',
                    dealer_id: 'Dealer ID',
                    dhl_post_number: 'DHL post number',
                    is_refunded: 'Is refunded?',
                },
                source: 'Channel',
            },
            coupon: {
                main_menu_label: 'Coupons',
                filters: {
                    status: 'Status',
                    draft: 'Draft',
                    on_hold: 'On Hold',
                    active: 'Active',
                    deactivated: 'Deactivated',
                    operative: 'Operative',
                    yes: 'Yes',
                    no: 'No',
                },
                validation: {
                    start_end_comparison:
                        'End date should be always later than start date',
                },
                create_coupon: 'Create new coupon',
                base_data_label: 'Description',
                name: 'Name',
                description: 'Description',
                period_of_time: 'Validity Period',
                base: 'Basic information',
                conditions: 'Condition',
                target: 'Target',
                codes: 'Codes',
                order_min_value: 'Order min value',
                order_value: 'Value',
                mandatory_product: 'Mandatory product',
                condition_request: 'Requested condition',
                product: 'Product',
                target_type: 'Target type',
                condition_type: 'Condition type',
                free_product: 'Free product',
                money: 'Money',
                percentage: 'Percentage',
                discount_value: 'Target value',
                percentage_value: 'Target value',
                amount_value: 'Amount of discount',
                select_target_type: 'Select target type first',
                select_condition_type: 'Select condition type first',
                order: 'Order total',
                email: 'E-mail',
                category: 'Category',
                marked: 'Marked products',
                select_product: 'Select product',
                flag: 'Flag',
                email_list: 'List of emails',
                code: 'Code',
                new_codes_list: 'Add new code below',
                code_generator_description:
                    'Please provide how many codes do you need, how long they should be (number of characters) and type of limit.',
                emails_conditions_tip: 'One email address per line',
                code_generator: 'Batch of codes generator',
                generate_batch: 'Codes auto-generator',
                codes_quantity: 'Quantity of codes',
                create: 'Create',
                all_one_time_use: 'Set all codes as one-time use',
                close: 'Close',
                generate: 'Generate',
                one_time_use: 'One-time use',
                updated_successfully: 'Coupon updated successfully',
                list_of_coupons: 'List of coupons',
                edit_page_title: 'Coupon edit page',
                status: {
                    label: 'Status',
                    list_label: 'List of statuses',
                    draft: 'Draft',
                    active: 'Active',
                    on_hold: 'On hold',
                    deactivated: 'Deactivated',
                    draft_details:
                        'The first level of coupon. With draft status, you can leave empty most of the fields.',
                    active_details:
                        'The most important status. With this status, the customer is able to use coupon codes between start and end dates. If you want to use active status you need to fill in almost all coupon fields and add at least one coupon code.',
                    on_hold_details:
                        'Use this one to make coupon codes suspend for a while.',
                    deactivated_details:
                        'Last level of coupon. If you set this up, there is no way back.',
                },
                create_new: 'Create',
                start_date: 'Start date',
                end_date: 'End date',
                delete_coupon: 'Coupon %{name} will be deleted.',
                delete_coupon_question:
                    'Are you sure you want to delete this coupon?',
                start_date_label: 'Start date',
                created_by_label: 'Created by',
                info: {
                    label: 'Info',
                    created_at_label: 'Created at',
                    updated_by_label: 'Updated last by',
                    updated_at_label: 'Updated last at',
                },
                report: {
                    label: 'Report',
                    created_by: 'Created by',
                    one_time_use: 'One-time use',
                    is_used: 'Is used',
                    is_active: 'Is active',
                    date_of_last_usage: 'Last usage at',
                    added_to_cart_at: 'Added to cart at',
                    cart_reference_id: 'Cart reference id',
                    is_redeemed: 'Is redeemed',
                    order_number: 'Order number',
                    discount_amount: 'Discount amount',
                    nothing_yet: 'No entries available',
                    order_placed_at: 'Order placed at',
                },
            },
            products: {
                main_menu_label: 'Products',
                name: 'Product |||| Products',
                fields: {
                    category_id: 'Category',
                    height_gte: 'Min height',
                    height_lte: 'Max height',
                    height: 'Height',
                    image: 'Image',
                    price: 'Price',
                    reference: 'Reference',
                    sales: 'Sales',
                    stock_lte: 'Low Stock',
                    stock: 'Stock',
                    thumbnail: 'Thumbnail',
                    width_gte: 'Min width',
                    width_lte: 'Max width',
                    width: 'Width',
                },
                tabs: {
                    image: 'Image',
                    details: 'Details',
                    description: 'Description',
                    reviews: 'Reviews',
                },
                filters: {
                    categories: 'Categories',
                    stock: 'Stock',
                    no_stock: 'Out of stock',
                    low_stock: '1 - 9 items',
                    average_stock: '10 - 49 items',
                    enough_stock: '50 items & more',
                    sales: 'Sales',
                    best_sellers: 'Best sellers',
                    average_sellers: 'Average',
                    low_sellers: 'Low',
                    never_sold: 'Never sold',
                    is_retail_ready: 'Is retail ready?',
                    is_wholesale_ready: 'Is wholesale ready?',
                    is_bundle: 'Is bundle?',
                    untagged: 'Untagged only',
                    stock_balance: 'Stock balance',
                    phased_out_date: 'Phased out date',
                    included_archived: 'Include archived',
                    reset_filters: 'Reset filters',
                    search_phrase: 'Search phrase',
                    stock_balance_filter: 'Stock is',
                },
                list: {
                    archive: 'Archive',
                    name: 'Product name',
                    memo: 'Memo',
                    retail_ready: 'Retail',
                    wholesale_ready: 'Wholesale',
                    is_bundle: 'Bundle',
                    net_price: 'Net price',
                    update_marketplace: 'Marketplace',
                    operation: 'Operation type',
                    attach: 'Attach',
                    detach: 'Detach',
                    update_marketplace_dialog_title: 'Marketplace allocation update',
                    marketplace_update_attach_hint: 'The products will be added to the marketplace with the price that currently applies to the default marketplace.',
                    marketplace_update_detach_hint: 'Choose the marketplace from which the products should be removed.',
                    update_tags: 'Tags',
                    update_tags_dialog_title: 'Tags assignment',
                    bulk_delete_content:
                        'Are you sure you want to archive this %{name}? |||| Are you sure you want to archive these %{smart_count} items?',
                    stock: 'Stock',
                    phasingOutDate: 'Phasing out date',
                    oldUrl: 'URL',
                    distriDiscount: 'Distri discount',
                    regularDiscount: 'Dealer discount',
                    ean: 'EAN/UPC',
                    tags: 'Tags',
                    sku: 'SKU',
                    color: 'Colour',
                    size: 'Seats/Size',
                    isDealerDedicated: 'Dealer search',
                    flag: 'Flag',
                },
                flags: {
                    new: 'New',
                    isComingSoon: 'Coming Soon',
                },
                show: {
                    color: 'Colour',
                    size: 'Seats/Size',
                    base: 'Basic information',
                    sku: 'SKU',
                    ean: 'EAN',
                    price: 'Price',
                    categories: 'Categories',
                    early_bird: 'Is early bird?',
                    retail_ready: 'Retail',
                    wholesale_ready: 'Wholesale',
                    is_bundle: 'Bundle',
                    net_price: 'Net price',
                    regular_discount: 'Dealer discount',
                    distributor_discount: 'Distri discount',
                    prices: 'List of prices',
                    marketplace_allocations: 'Marketplace allocations',
                    marketplace: 'Marketplace',
                    rrp_message: 'Recommended Retail Price (not for update)',
                    promo_price: 'Promo net price',
                    marketplace_allocation: 'Allocate product in marketplace',
                    full_price: 'Current price with tax',
                    price_error_format: 'Wrong price format. Example: 12.34 or 9.34562',
                    manuals: 'Manuals',
                    phased_out_date: 'Phased out date',
                    product_attributes: 'Specifications',
                    product_content: 'Content',
                    attribute_name: 'Attribute name',
                    attribute_value: 'Attribute value',
                    product_media: 'Media files',
                    select_attribute_first: 'Select attribute first',
                    marketplace_name: 'Marketplace name',
                    min_inventory: 'Min inventory',
                    is_active: 'Is active',
                    download_qr_code_file: 'Download file with QR code',
                    manual_language: 'Language',
                    manual_type: 'Type',
                    manual_source_file: 'Source file',
                    manual_scans: 'Scans',
                    manual_file_delete_title: 'File will be removed',
                    attribute_desc: 'Description',
                    image_position_updated: 'Image position updated.',
                    accepted_files_format: 'You can upload these supported file types: webp, jpeg, jpg, png, mp4',
                    accepted_files_format_colors_size: 'You can upload these supported file types: png, jpeg, jpg, svg',
                    color_image: 'Color image representation',
                    size_image: 'Size image representation',
                    update_colour_image_title: 'Update colour image representation',
                    update_size_image_title: 'Update size image representation',
                    add_colour_image_title: 'Add colour image representation',
                    add_size_image_title: 'Add size image representation',
                    compatible_trailers: 'Compatible trailers',
                    compatible_accessories: 'Compatible accessories',
                    compatible_spare_parts: 'Compatible spare parts',
                    content: {
                        name: 'Name',
                        description: 'Description',
                        short_description: 'Short description',
                        delivery_time: 'Delivery time',
                        meta_title: 'Meta title',
                        meta_description: 'Meta description',
                        size: 'Size',
                        slug: 'Url slug',
                        url: 'Old url',
                        gallery: 'Gallery images',
                        image: 'Image',
                        title: 'Title',
                        color_image: 'Color image',
                        size_image: 'Size image',
                        video: 'Video',
                    },
                    variants: {
                        add_new_variant: 'Add new variant',
                        section_name: 'Variants',
                        name: 'Name',
                        color: 'Colour',
                        size: 'Seats/Size',
                        attribute: 'Attribute',
                        sku: 'SKU',
                        variant: 'Variant',
                        variant_attribute: 'Variant attribute',
                        variant_value: 'Variant attribute value'
                    },
                    matched: {
                        add_new_product: 'Add new compatible products',
                        section_name: 'Compatibilities',
                        name: 'Name',
                        sku: 'SKU',
                        product: 'Product',
                        products: 'Products',
                        bulk_delete_content:
                            'Are you sure you want to remove selected product? |||| Are you sure you want to delete these %{smart_count} products?',
                    },
                },
                aside: {
                    stocks_headline: 'Inventory',
                    sale_ready_stock: 'Sale-Ready Stock',
                    purchase_order_headline: 'Purchase orders list',
                    arrival_date: 'Arrival date',
                    purchase: 'Purchase',
                    arrival: 'Arrival',
                    ordered_items: 'Ordered: %{smart_count} items',
                    remaining_items: 'Remaining items: %{smart_count}',
                    available_items: 'Projected availability: %{smart_count}',
                    settings_headline: 'Settings',
                }
            },
            category: {
                edit: {
                    tree_label: 'Tree',
                    tree: 'Tree preview',
                    internal_desc: 'Description (internal purpose only)',
                    is_default: 'Is default',
                    edit_tree: 'Edit tree',
                    add_tree: 'Add new tree',
                    new_category_dialog_title: 'Add new category',
                    new_child_node: 'Add new child node',
                    missing_tree: 'The tree for this category is missing.',
                    associated_products: 'Associated products',
                    remove_below_category: 'Remove below category:',
                    name: 'Name',
                    title: 'Title',
                }
            },
            user: {
                name: 'Users',
                create_user: 'Create new user',
                warning: 'Warning',
                something_went_wrong: 'Something went wrong.',
                fields: {
                    fullName: 'Full Name',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    phone: 'Phone',
                    roleName: 'Role Name',
                    email: 'E-mail',
                    accessRole: 'Access role',
                    oldPassword: 'Old password',
                    newPassword: 'New password',
                    repeatNewPassword: 'Repeat new password',
                    switchOn: 'On',
                    switchOff: 'Off',
                },
                fieldGroups: {
                    identity: 'Identity',
                    change_password: 'Change password',
                    contact: 'Contact',
                    access_role: 'Access Role',
                    password: 'Password',
                    mfa: 'Multi-factor authentication',
                },
                validation: {
                    password_dont_match: "Passwords don't match.",
                },
                updated_successfully: 'User updated successfully',
                created_successfully: 'User created successfully',
                force_mfa: 'Force multi-factor authentication',
                force_mfa_success: 'Multi-factor authentication setup success',
                force_mfa_error: 'Multi-factor authentication setup error',
                you_need_to_setup_mfa:
                    'You need to setup your device for multi-factor authentication',
                mfa_headline_subtitle:
                    'Require a security key or code in addition to your password.',
                mfa_dialog_title: 'Multi-factor authentication setup',
                disable_mfa_dialog_title: 'Remove registered device',
                disable_mfa_dialog_content:
                    'Are you sure you want to remove registered device and turn off two-factor authentication?',
                disable_mfa_disallowed:
                    'You are not allowed to update this settings.',
                disable_mfa_disallowed_2:
                    'You are not allowed to turn off the two-factor authentication setting.',
                six_digits_validation:
                    'You need to provide 6 digits to add a device.',
                type_code_below:
                    'Use you mobile app to obtain the authentication code and type them below.',
                verify_code: 'Verify',
                mfa_dialog_subtitle:
                    'You will need a Google Authenticator to complete the process.',
                scanQrCode: 'Scan the QR code into your app.',
                enterSixDigitCode:
                    'Enter the 6-digit authentication code generated by your app:',
                mobileAppList: 'List of mobile apps',
                addDevice: 'Add device',
            },
            marketplace: {
                main_menu_label: 'Marketplaces',
                edit: {
                    category_list_title: 'List of categories',
                    category_id: 'Category ID',
                    category_name: 'Category name',
                    category_code: 'Code',
                    category_default: 'Is default',
                    name: 'Name',
                    country: 'Country code',
                    description: 'Description',
                    is_active: 'Is active',
                    list_of_products: 'List of allocated products',
                    product_sku: 'SKU',
                    product: 'Product',
                    net_price: 'Net price',
                    stock: 'Stock',
                    promo_net_price: 'Promo net price',
                    min_inventory: 'Min inventory',
                    req_completion_percentage: 'Completion of req. fields %',
                    opt_completion_percentage: 'Completion of opt. fields %',
                },
                fields: {
                    name: 'Marketplace name',
                    proxy: 'Proxy',
                    country_code: 'Country code',
                    is_active: 'Is active',
                    is_retail: 'Is retail dedicated',
                }
            },
            attributes: {
                main_menu_label: 'Product attributes',
                edit: {
                    default_marketplace_note: 'You are currently in the process of editing an attribute associated with one of the marketplaces: %{marketplace}. This attribute is sourced directly from %{marketplace} infrastructure. Please be aware that certain attributes sourced from external services can be edited to a limited extent, primarily to uphold data consistency. However, you are still able to utilize this attribute in another marketplace.',
                    list_of_options: 'List of options',
                    add_new_option: 'Add new option',
                    value: 'Value',
                },
                fields: {
                    name: 'Name',
                    description: 'Description',
                    unit: 'Unit',
                    validation_rules: 'Validation rules',
                    marketplaces: 'Marketplaces',
                    value_type: 'Value type',
                    value_validations: 'Value validations',
                    has_closed_options: 'Has closed options',
                    origin_marketplace: 'Origin marketplace',
                    tip: 'Tip',
                }
            },
            reports: {
                main_menu_label: 'Reports',
                products_allocations_main_menu_label: 'Products allocations',
                allocations: {
                    all_tab: 'All',
                }
            },
            dealers: {
                main_menu_label: 'Dealers',
                list: {
                    name: 'Name',
                    selectLineId: 'Number ',
                    isServicePartner: 'Service partner',
                    country: 'Country',
                    groupNumber: 'Group',
                    industry: 'Industry',
                    isActive: 'Is active',
                    creationDate: 'Creation date',
                    city: 'City',
                    street: 'Street',
                    zipCode: 'Zip code',
                    phoneNumber1: 'Phone number',
                    website: 'Website',
                    isDocumentLock: 'Is locked',
                    bulk_delete_content:
                        'Are you sure you want to archive this %{name}? |||| Are you sure you want to archive these %{smart_count} items?',
                    archive: 'Archive',
                    addressLine1: 'Address additive',
                    addressLine2: 'Address additive 2',
                    assortment: 'Assortment',
                },
                address: {
                    selectLineId: 'Number ',
                    firstName: 'First name',
                    lastName: 'Last name',
                    country: 'Country',
                    isDefault: 'Is default',
                    city: 'City',
                    street: 'Street',
                    zipCode: 'Zip code',
                    phoneNumber1: 'Phone number',
                    phoneNumber2: 'Phone number 2',
                    phoneNumber3: 'Phone number 3',
                    phoneNumber4: 'Phone number 4',
                    website: 'Website',
                    addressLine1: 'Address additive',
                    addressLine2: 'Address additive 2',
                    addressLine3: 'Address additive 3',
                    pickup_point: 'Pick-up point',
                    service_point: 'Repair shop',
                    functions: 'Functions',
                    function: 'Function',
                    department: 'Department',
                    salutation: 'Salutation',
                    latitude: 'Geo latitude',
                    longitude: 'Geo longitude',
                    email: 'E-mail',
                    email2: 'E-mail 2',
                    faxNumber: 'Fax number',
                },
                show: {
                    name: 'Name',
                    selectLineId: 'Number',
                    isServicePartner: 'Service partner',
                    country: 'Country',
                    groupNumber: 'Group',
                    industry: 'Industry',
                    isActive: 'Is active',
                    creationDate: 'Creation date',
                    city: 'City',
                    street: 'Street',
                    zipCode: 'Zip code',
                    phoneNumber1: 'Phone number',
                    website: 'Website',
                    isDocumentLock: 'Is locked',
                    bulk_delete_content:
                        'Are you sure you want to archive this %{name}? |||| Are you sure you want to archive these %{smart_count} items?',
                    archive: 'Archive',
                    addressLine1: 'Address additive',
                    addressLine2: 'Address additive 2',
                    tax: 'Tax ID',
                    language: 'Language',
                    isPrivatePerson: 'Is private person',
                    lockReason: 'Lock reason',
                    address_contact: 'Address & Contact',
                    locations: 'Locations',
                    phoneNumber2: 'Phone number 2',
                    phoneNumber3: 'Phone number 3',
                    phoneNumber4: 'Phone number 4',
                    email: 'E-mail',
                    email2: 'E-mail 2',
                    faxNumber: 'Fax number',
                    address: 'Address',
                    contact: 'Contact',
                },
                filters: {
                    included_archived: 'Include archived',
                    reset_filters: 'Reset filters',
                    search_phrase: 'Search phrase',
                },
                aside: {
                    internal_note_headline: 'Internal memo',
                    lastOrderPlaced: 'Last order placed at',
                    lastHalfYearStats: 'Last half year stats',
                    stats: 'Statistics',
                    productTypes: 'Types of products',
                    stats_info: 'Note: Statistics are based on orders from the last 180 days.',
                    assortment_info: 'Example: \'Kid (80%)\' means 80% of all bought items were kid dedicated.',
                    type_info: 'Example: \'Trailers (15/45)\' means the dealer bought 45 items throughout the last 180 days, including 15 trailers.',
                },
            },
            locks: {
                overlay: 'Currently Edited by %{name}',
            },
        },
    }
);

export default customEnglishMessages;
