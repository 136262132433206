import * as React from 'react';
import { Chip, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import { OrderProduct } from '../types';
import { statuses } from './orderItemStatus';
import Tooltip from '@mui/material/Tooltip';

const RefundedItemChip = (record: OrderProduct) => {
    const translate = useTranslate();
    const status = statuses[record.status];

    const description =
        status.id === 50 ? (
            translate(status.translationKey)
        ) : (
            <React.Fragment>
                <Typography color="inherit">
                    {translate('resources.order.statuses.refund_details')}
                </Typography>
                {translate('resources.order.statuses.refunded_items')}:{' '}
                {record.refundedItems} <br />
                {translate('resources.order.statuses.refunded_at')}:{' '}
                {record.refundedAt} <br />
            </React.Fragment>
        );

    return (
        <Tooltip title={description}>
            <Chip
                color={status.color}
                size="small"
                key={status.id}
                label={translate(status.translationKey)}
            />
        </Tooltip>
    );
};

export default RefundedItemChip;
