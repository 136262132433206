import * as React from 'react';
import { useState } from 'react';
import LockIcon from '@mui/icons-material/ScreenLockPortrait';

import {
    Button,
    useUpdateMany,
    useNotify,
    useUnselectAll,
    Identifier,
    useListContext,
    Confirm,
} from 'react-admin';

const noSelection: Identifier[] = [];

const BulkForceMfaButton = () => {
    const { selectedIds = noSelection } = useListContext();
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const unselectAll = useUnselectAll('reviews');

    const [updateMany, { isLoading }] = useUpdateMany(
        'user/force-mfa',
        { ids: selectedIds, data: { force: true } },
        {
            mutationMode: 'undoable',
            onSuccess: () => {
                notify('resources.user.force_mfa_success', {
                    type: 'info',
                    undoable: true,
                });
                unselectAll();
            },
            onError: () => {
                notify('resources.user.force_mfa_error', {
                    type: 'warning',
                });
            },
        }
    );
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async () => {
        await updateMany();
        setOpen(false);
    };
    return (
        <>
            <Button
                label="resources.user.force_mfa"
                disabled={isLoading}
                onClick={handleClick}
            >
                <LockIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Update"
                content="Are you sure?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default BulkForceMfaButton;
