import ProductList from './ProductList';
import ProductEdit from './ProductEdit';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const resource = {
    list: ProductList,
    edit: ProductEdit,
    icon: FormatListNumberedIcon,
};

export default resource;
