import * as React from 'react';
import {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import {Button, Card, CardActions, CircularProgress} from '@mui/material';
import {
    Form,
    useCreate,
    useTranslate,
    useNotify,
    useAuthProvider
} from 'react-admin';

import Box from '@mui/material/Box';
import backgroundImage from './images/background.jpg';

import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const MultiFactorAuthentication = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const auth = useAuthProvider();

    const notify = useNotify();
    const navigate = useNavigate();

    const [create] = useCreate();

    const save = useCallback(async () => {
        const code = Object.values(refs)
            .map((input: any) => input.current.value)
            .join('');

        if (code.length !== 6) {
            notify('resources.user.six_digits_validation', {
                type: 'warning',
                autoHideDuration: 5000,
            });

            return;
        }

        try {
            if (auth?.getIdentity === undefined) {
                throw new Error();
            }

            const user = await auth.getIdentity();

            await create(
                `user/${user?.id}/mfa`,
                {data: {code}},
                {returnPromise: true}
            );
            navigate(`/`);
        } catch (error: any) {
            notify(
                error?.body?.error?.message ??
                'resources.user.something_went_wrong',
                {
                    type: 'warning',
                    autoHideDuration: 5000,
                }
            );
        }
    }, [create]);

    const processCode = (field: any) => {
        const input = field.target;
        const id = parseInt(input.id);

        if (parseInt(input.id) < 6) {
            refs[id + 1].current.focus();
        }
    };

    const refs: any = {};

    const MfaNumberField = (props: { number: string }) => {
        const {number} = props;

        refs[number] = useRef(null);

        return (
            <TextField
                onInput={processCode}
                inputRef={refs[number]}
                id={number}
                hiddenLabel={true}
                variant="outlined"
                placeholder={number}
                focused={number === '1'}
                maxRows={1}
                inputProps={{maxLength: '1'}}
                sx={{
                    width: '100%',
                    height: '100%',
                    '& .MuiOutlinedInput-input': {
                        border: '0px !important',
                        padding: '5px',
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                    },
                    '& .MuiOutlinedInput-root': {
                        width: '100%',
                        height: '100%',
                    },
                    '& p': {
                        display: 'none',
                    },
                    '& fieldset': {
                        border: '0',
                    },
                }}
            />
        );
    };

    return (
        <Form onSubmit={save}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    background: `url(${backgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Card sx={{minWidth: 300, marginTop: '6em'}}>
                    <Box
                        sx={{
                            padding: '1em',
                            display: 'flex',
                            justifyContent: 'left',
                            backgroundColor: '#F7F8FA',
                            color: '#272c36',
                            fontWeight: 'bold',
                        }}
                    >
                        Two-step authentication
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#272c36',
                            padding: '1em',
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#272c36',
                            fontSize: '14px',
                        }}>
                            {translate('resources.user.type_code_below')}
                        </div>
                    </Box>
                    <Box
                        sx={{
                            padding: '1em 1em 2em 1em',
                            maxWidth: '600px',
                            textAlign: 'center',
                        }}
                    >
                        <ToggleButtonGroup
                            exclusive
                            fullWidth={true}
                            sx={{maxWidth: '350px'}}
                        >
                            <ToggleButton value="1" aria-label="left aligned">
                                <MfaNumberField number="1"/>
                            </ToggleButton>
                            <ToggleButton value="2" aria-label="centered">
                                <MfaNumberField number="2"/>
                            </ToggleButton>
                            <ToggleButton value="3" aria-label="right aligned">
                                <MfaNumberField number="3"/>
                            </ToggleButton>
                            <ToggleButton value="4" aria-label="justified">
                                <MfaNumberField number="4"/>
                            </ToggleButton>
                            <ToggleButton value="5" aria-label="justified">
                                <MfaNumberField number="5"/>
                            </ToggleButton>
                            <ToggleButton value="6" aria-label="justified">
                                <MfaNumberField number="6"/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <CardActions
                        sx={{
                            padding: '1em',
                            backgroundColor: '#F7F8FA',
                            justifyContent: 'right',
                        }}
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2}/>
                            )}
                            {translate('resources.user.verify_code')}
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

MultiFactorAuthentication.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

const MultiFactorAuthenticationWithTheme = (props: any) => {

    return (
        <MultiFactorAuthentication {...props} />
    );
};

export default MultiFactorAuthenticationWithTheme;
