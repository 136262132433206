import * as React from "react";
import {
    BooleanInput,
    number,
    NumberInput,
    required,
    SimpleForm,
    TextInput,
    Toolbar,
    useRecordContext,
    SaveButton,
    regex, useRefresh
} from "react-admin";
import {Product, ProductMarketplaceAllocation} from "../types";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import {Grid, InputAdornment} from "@mui/material";

const MarketplaceAllocationUpdateForm = (props: { record: Product }) => {
    const allocation: ProductMarketplaceAllocation = useRecordContext();
    const refresh = useRefresh();

    return (
        <EditInDialogButton
            mutationMode="optimistic"
            title={`'${props.record.name}' allocated in '${allocation.marketplace.name}'`}
            transform={(data: ProductMarketplaceAllocation) => {
                data.netPrice = String(data.netPrice);
                data.promoNetPrice = data.promoNetPrice ? String(data.promoNetPrice) : null;
                return data;
            }}
            mutationOptions={{
                onSuccess: () => {
                    refresh();
                }
            }}
        >
            <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            source="netPrice"
                            name="netPrice"
                            fullWidth={true}
                            validate={[required(), number(), regex(/^\d{1,9}\.\d{2,5}$/, 'resources.products.show.price_error_format')]}
                            InputProps={{startAdornment: (<InputAdornment position="start">€</InputAdornment>)}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            source="promoNetPrice"
                            name="promoNetPrice"
                            fullWidth={true}
                            validate={[number(), regex(/^\d{1,9}\.\d{2,5}$/, 'resources.products.show.price_error_format')]}
                            InputProps={{startAdornment: (<InputAdornment position="start">€</InputAdornment>)}}
                        />
                    </Grid>
                </Grid>
                <NumberInput
                    source="minInventory"
                    name="minInventory"
                    fullWidth={true}
                    min={1}
                    max={9999}
                    validate={[number()]}
                />
                <BooleanInput source="isActive" name="isActive" validate={[required()]}/>
            </SimpleForm>
        </EditInDialogButton>
    );
};

export default MarketplaceAllocationUpdateForm;
