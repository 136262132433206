import * as React from 'react';
import { FC } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    useRecordContext,
    BooleanField,
    SortPayload,
} from 'react-admin';

import CodeItemShow from './CodeItemShow';

const CouponEditReportTab: FC = props => {
    const record = useRecordContext(props);
    const sort: SortPayload = { field: 'date', order: 'DESC' };
    return (
        <List
            perPage={25}
            sort={sort}
            resource={`coupon/${record.id}/code`}
            sx={{ width: '100%' }}
            title={` `}
        >
            <Datagrid
                rowClick="expand"
                expand={<CodeItemShow />}
                bulkActionButtons={false}
                expandSingle
            >
                <TextField source="id" />
                <TextField source="code" />
                <TextField
                    source="createdBy.name"
                    label="resources.coupon.report.created_by"
                    sortable={false}
                />
                <BooleanField
                    source="isOneTimeUse"
                    label="resources.coupon.report.one_time_use"
                />
                <BooleanField
                    source="isUsed"
                    label="resources.coupon.report.is_used"
                />
                <BooleanField
                    source="isActive"
                    label="resources.coupon.report.is_active"
                />
                <DateField
                    source="usedAt"
                    label="resources.coupon.report.date_of_last_usage"
                    locales="de-DE"
                    options={{
                        dateStyle: 'medium',
                    }}
                />
            </Datagrid>
        </List>
    );
};

export default CouponEditReportTab;
