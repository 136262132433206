import * as React from "react";
import {
    SimpleForm,
    Toolbar,
    useRecordContext,
    SaveButton, FileField, FileInput
} from "react-admin";
import {Product} from "../types";
import {EditInDialogButton} from "@react-admin/ra-form-layout";

const ProductManualUpdateForm = (props: { record: Product }) => {
    const manual = useRecordContext();

    const languages = {
        _ml: 'Multilingual',
        de: 'German',
        en: 'English',
        fr: 'French'
    };

    return (
        <EditInDialogButton
            mutationMode="optimistic"
            title={`Update ${manual.type.toLowerCase()} file type for ${languages[manual.language]} language.`}
        >
            <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
                <FileInput source="files" name={`manual`} placeholder={<p>Drop your file here to update selected manuals</p>} accept="application/pdf">
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </EditInDialogButton>
    );
};

export default ProductManualUpdateForm;
