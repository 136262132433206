import {
    AutocompleteInput,
    BooleanInput,
    FormDataConsumer,
    number,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    useDataProvider,
    useNotify,
    useTranslate,
    useUnselectAll
} from "react-admin";
import {useEffect, useState} from "react";
import {Marketplace, ProductTag} from "../types";
import {BulkUpdateFormButton} from "@react-admin/ra-form-layout";
import * as React from "react";
import {Alert, Typography} from "@mui/material";
import {TreeInput} from "@react-admin/ra-tree";
import StorefrontIcon from '@mui/icons-material/Storefront';

const MarketplaceBulkUpdateButton = () => {

    const t = useTranslate();
    const dataProvider = useDataProvider();
    const resource = 'products';
    const [marketplaces, setMarketplaces] = useState<ProductTag[]>([]);
    const [loading, setLoading] = useState(true);
    const [marketplace, setMarketplace] = useState<number | null>(null);
    const [tree, setTree] = useState([]);

    const unselectAll = useUnselectAll(resource);
    const notify = useNotify();

    useEffect(() => {
        dataProvider.getList<Marketplace>(`marketplaces`, {
            filter: {
                isDefault: false
            },
            sort: {field: 'id', order: 'ASC'},
            pagination: {page: 1, perPage: 1000},
            meta: {}
        })
            .then(({data}) => {
                setMarketplaces(data);
                setLoading(false);
                unselectAll();
            })
            .catch(error => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {

        if (marketplace === null) {
            return;
        }
        setTree([]);

        dataProvider.getList(
            'categories',
            {
                filter: {marketplace, isDefault: true},
                sort: {field: 'id', order: 'ASC'},
                pagination: {page: 1, perPage: 1000},
                meta: {}
            }
        ).then(({data}) => {
            if (data.length === 0) {
                return;
            }

            return dataProvider.getTree(
                'category-trees',
                {
                    meta: {
                        filter: {
                            category: data[0].id
                        }
                    }
                });
        }).then((response) => {
            setTree(response.data);
        });

    }, [marketplace]);

    if (loading) {
        return null;
    }

    return (
        <BulkUpdateFormButton
            icon={<StorefrontIcon/>}
            label={`resources.products.list.update_marketplace`}
            title={`resources.products.show.update_marketplace`}
            DialogProps={
                {
                    sx: {
                        '& .MuiDialog-paper': {minWidth: {md: '30%'}},
                    }
                }
            }
            mutationOptions={{
                onError: (error: any) => {
                    const message = error?.body?.error?.message;

                    if (message) {
                        notify(message, {
                            type: 'error',
                            autoHideDuration: 5000,
                        });
                    }
                    //
                    let close = document.getElementsByClassName('MuiModal-backdrop');
                    // @ts-ignore
                    close[0].click();
                },
                onSuccess: () => {
                    unselectAll();
                }
            }}
        >
            <Typography variant="h6" component="h2" sx={{marginTop: '-22px', paddingLeft: '16px'}}>
                {t(`resources.products.list.update_marketplace_dialog_title`)}
            </Typography>
            <SimpleForm>
                <SelectInput
                    source="operation"
                    name="operation"
                    optionValue="id"
                    optionText="name"
                    choices={[
                        {id: 'allocate', name: 'resources.products.list.attach'},
                        {id: 'detach', name: 'resources.products.list.detach'}
                    ]}
                    label={`resources.products.list.operation`}
                    validate={[required()]}
                />
                <FormDataConsumer>
                    {({formData}) => {
                        switch (formData?.operation) {
                            case 'allocate':
                                return (
                                    <>
                                        <AutocompleteInput
                                            optionText="name"
                                            optionValue="id"
                                            fullWidth={true}
                                            choices={marketplaces}
                                            name="marketplace"
                                            validate={[required()]}
                                            label={`resources.products.show.marketplace`}
                                            onChange={setMarketplace}
                                        />
                                        <NumberInput
                                            source="minInventory"
                                            name="minInventory"
                                            fullWidth={true}
                                            min={1}
                                            max={9999}
                                            validate={[number()]}
                                        />
                                        <BooleanInput source="isActive" name="isActive" validate={[required()]}/>
                                        <TreeInput
                                            source="categories"
                                            name="categories"
                                            treeData={tree}
                                            multiple
                                            hideRootNodes
                                        />
                                        <Alert severity="info" sx={{width: '90%', margin: 'auto'}}>
                                            {t('resources.products.list.marketplace_update_attach_hint')}
                                        </Alert>
                                    </>
                                );
                            case 'detach':
                                return (
                                    <>
                                        <AutocompleteInput
                                            optionText="name"
                                            optionValue="id"
                                            fullWidth={true}
                                            choices={marketplaces}
                                            name="marketplace"
                                            validate={[required()]}
                                            label={`resources.products.show.marketplace`}
                                        />
                                        <Alert severity="info" sx={{width: '90%', margin: 'auto'}}>
                                            {t('resources.products.list.marketplace_update_detach_hint')}
                                        </Alert>
                                    </>
                                );
                            default:
                                return null;
                        }
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}


export default MarketplaceBulkUpdateButton;
