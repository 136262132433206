export enum Targets {
    Product = 'product',
    Money = 'money',
    Percentage = 'percentage',
}

export const types = {
    [Targets.Product]: Targets.Product,
    [Targets.Money]: Targets.Money,
    [Targets.Percentage]: Targets.Percentage,
};

export default [
    {
        id: Targets.Product,
        name: 'resources.coupon.free_product',
    },
    {
        id: Targets.Money,
        name: 'resources.coupon.money',
    },
    {
        id: Targets.Percentage,
        name: 'resources.coupon.percentage',
    },
];
