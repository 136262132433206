import * as React from 'react';
import { CSSProperties, FC, useCallback } from 'react';
import {
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    TabbedForm,
    useRecordContext,
    useUpdate,
    BooleanInput,
    FormDataConsumer,
    useRefresh,
    useNotify,
    Toolbar as BaseToolbar,
    DeleteButton,
    SaveButton,
    useTranslate,
} from 'react-admin';

import CouponEditBaseTab from './CouponEditBaseTab';
import CouponEditConditionTab from './CouponEditConditionTab';
import CouponEditCodesTab from './CouponEditCodesTab';
import CouponEditTargetTab from './CouponEditTargetTab';
import CouponEditReportTab from './CouponEditReportTab';
import { Alert, Box } from '@mui/material';
import { Conditions } from '../conditionTypes';
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const CouponEditForm: FC = props => {
    const record = useRecordContext(props);
    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();
    const [update] = useUpdate();
    useDefineAppLocation('sales.coupons.edit', {record});

    const save = useCallback(
        async values => {
            try {
                await update(
                    `coupon`,
                    { id: record.id, data: values },
                    { returnPromise: true }
                );

                refresh();
                notify('resources.coupon.updated_successfully', {
                    type: 'success',
                    autoHideDuration: 5000,
                });
            } catch (error: any) {
                const e = error?.body?.error;

                if (e?.errors) {
                    if (e.errors?.codes || e.errors?.newCodes) {
                        notify(e.errors?.codes ?? e.errors?.newCodes, {
                            type: 'error',
                            autoHideDuration: 5000,
                        });
                    } else {
                        return error.body.error.errors;
                    }
                }
            }
        },
        [update, refresh, notify, record.id]
    );

    // @ts-ignore
    const Toolbar = props => {
        const { itemName } = props;
        return (
            <BaseToolbar
                {...props}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <SaveButton />
                <DeleteButton
                    confirmContent={`resources.coupon.delete_coupon_question`}
                    confirmTitle={translate(`resources.coupon.delete_coupon`, {
                        name: itemName,
                    })}
                />
            </BaseToolbar>
        );
    };

    return (
        <>
            <TabbedForm
                onSubmit={save}
                warnWhenUnsavedChanges={true}
                toolbar={<Toolbar />}
            >
                <FormTab label={translate(`resources.coupon.base`)}>
                    <CouponEditBaseTab />
                </FormTab>
                <FormTab label="resources.coupon.conditions" path="conditions">
                    <CouponEditConditionTab />
                </FormTab>
                <FormTab label="resources.coupon.target" path="target">
                    <CouponEditTargetTab />
                </FormTab>
                <FormTab label="resources.coupon.codes" path="code">
                    <CouponEditCodesTab {...props} />
                    <ArrayInput source="codes" label="" name="codes">
                        <SimpleFormIterator
                            disableReordering={true}
                            TransitionProps={{
                                addEndListener: () => false,
                                classNames: '',
                            }}
                            disableAdd={true}
                        >
                            <FormDataConsumer>
                                {({ formData, getSource, scopedFormData }) => {
                                    const shouldBeOneTimeUse: boolean =
                                        formData?.condition?.type ===
                                        Conditions.Email;
                                    return (
                                        <Box
                                            display={{
                                                xs: 'block',
                                                sm: 'flex',
                                            }}
                                            style={
                                                {
                                                    width: '100%',
                                                } as CSSProperties
                                            }
                                        >
                                            <TextInput
                                                // @ts-ignore
                                                source={getSource('code')}
                                                label={'resources.coupon.code'}
                                                disabled={
                                                    scopedFormData?.isUsed
                                                }
                                                sx={{
                                                    margin: '10px 0px 0px 0px',
                                                }}
                                            />
                                            <div
                                                style={
                                                    {
                                                        padding: '15px 20px',
                                                    } as CSSProperties
                                                }
                                            >
                                                <BooleanInput
                                                    label="resources.coupon.one_time_use"
                                                    // @ts-ignore
                                                    source={getSource(
                                                        'isOneTimeUse'
                                                    )}
                                                    disabled={
                                                        scopedFormData?.isUsed ||
                                                        (scopedFormData?.isOneTimeUse &&
                                                            shouldBeOneTimeUse)
                                                    }
                                                />
                                            </div>

                                            {scopedFormData?.isUsed ? (
                                                <div
                                                    style={
                                                        {
                                                            padding:
                                                                '10px 20px',
                                                        } as CSSProperties
                                                    }
                                                >
                                                    <Alert
                                                        severity="success"
                                                        style={
                                                            {
                                                                height: '35px',
                                                            } as CSSProperties
                                                        }
                                                    >
                                                        Used
                                                    </Alert>
                                                </div>
                                            ) : null}
                                        </Box>
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="newCodes" label="" name="newCodes">
                        <SimpleFormIterator
                            source={`newCodes`}
                            disableReordering={true}
                            TransitionProps={{
                                addEndListener: () => false,
                                classNames: '',
                            }}
                        >
                            <FormDataConsumer>
                                {({ formData, getSource }) => {
                                    const shouldBeOneTimeUse: boolean =
                                        formData?.condition?.type ===
                                        Conditions.Email;
                                    return (
                                        <Box
                                            display={{
                                                xs: 'block',
                                                sm: 'flex',
                                            }}
                                            style={
                                                {
                                                    width: '100%',
                                                } as CSSProperties
                                            }
                                        >
                                            <TextInput
                                                // @ts-ignore
                                                source={getSource('code')}
                                                label={'resources.coupon.code'}
                                                sx={{
                                                    margin: '10px 0px 0px 0px',
                                                }}
                                            />
                                            <div
                                                style={
                                                    {
                                                        padding: '15px 20px',
                                                    } as CSSProperties
                                                }
                                            >
                                                <BooleanInput
                                                    label="resources.coupon.one_time_use"
                                                    // @ts-ignore
                                                    source={getSource(
                                                        'isOneTimeUse'
                                                    )}
                                                    value={shouldBeOneTimeUse}
                                                    defaultValue={
                                                        shouldBeOneTimeUse
                                                    }
                                                    disabled={
                                                        shouldBeOneTimeUse
                                                    }
                                                />
                                            </div>
                                        </Box>
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="resources.coupon.report.label" path="report">
                    <CouponEditReportTab />
                </FormTab>
            </TabbedForm>
        </>
    );
};

export default CouponEditForm;
