import * as React from 'react';
import { FC } from 'react';
import { Edit, EditProps, Title } from 'react-admin';
import CouponEditForm from './CouponEditForm';
import AsidePanel from './AsidePanel';

const CouponEdit: FC<EditProps> = props => {
    return (
        <Edit
            {...props}
            mutationMode="pessimistic"
            title={<Title title={`resources.coupon.edit_page_title`} />}
            aside={<AsidePanel />}
        >
            <CouponEditForm />
        </Edit>
    );
};

export default CouponEdit;
