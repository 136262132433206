import * as React from "react";
import {Product, ProductVariant} from "../types";
import {
    Datagrid,
    FunctionField,
    TextField,
    DeleteWithConfirmButton,
    List,
} from "react-admin";
import {AccordionSection} from "@react-admin/ra-form-layout";
import {Link} from "@mui/material";
import VariantCreateButton from "./variant/VariantCreateButton";
import {productNameWithColorAndSize} from "../utils/productNameWithColorAndSize";

const VariantsProductEditTab = (props: { record: Product }) => {
    return (
        <AccordionSection label={'resources.products.show.variants.section_name'} fullWidth>
            <List
                resource={`product-variants`}
                filter={{product: props.record.id}}
                perPage={999}
                pagination={false}
                hasCreate={true}
                empty={false}
                sx={{'& .List-content': {border: 'none'}}}
                actions={<VariantCreateButton record={props.record as Product}/>}
            >
                <Datagrid bulkActionButtons={false}>
                    <FunctionField
                        source="variant.name"
                        label={`resources.products.show.variants.name`}
                        sortable={false}
                        render={pv => (
                            <Link href={`/#/products/${pv.variant.id}`}>
                                {productNameWithColorAndSize(pv.variant)}
                            </Link>
                        )}
                    />
                    <TextField source="variant.sku" label="resources.products.show.variants.sku" sortable={false}/>
                    <FunctionField
                        source="variant.attribute"
                        label={`resources.products.show.variants.variant_value`}
                        sortable={false}
                        render={(pv: ProductVariant) => {
                            if (pv?.attribute) {
                                return pv.attribute.code === 'colour' ? pv.variant.color : pv.variant.size
                            }

                            return 'n/a';
                        }}
                    />

                    <FunctionField
                        source="variant.attribute"
                        label={`resources.products.show.variants.attribute`}
                        sortable={false}
                        render={pv => (
                            <Link href={`/#/attributes/${pv?.attribute?.id}`}>
                                {pv?.attribute?.name}
                            </Link>
                        )}
                    />

                    <DeleteWithConfirmButton
                        redirect={false}
                        mutationMode="optimistic"
                        confirmTitle=" "
                    />
                </Datagrid>
            </List>
        </AccordionSection>
    )
};

export default VariantsProductEditTab;
