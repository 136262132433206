import * as React from 'react';
import {
    Datagrid,
    FunctionField,
    List,
    TextField,
    NumberField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

import MobileGrid from './MobileGrid';
import StateChip from './StateChip';
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const CronJobList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    useDefineAppLocation('settings.settings_cron');

    return (
        <List pagination={false} title="resources.server.cron.page_list_title">
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    bulkActionButtons={false}
                    optimized
                    rowClick="show"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <TextField
                        source="cronId"
                        label="resources.server.cron.id"
                    />
                    <TextField
                        source="name"
                        label="resources.server.cron.name"
                    />
                    <TextField
                        source="description"
                        label="resources.server.cron.description"
                    />
                    <NumberField
                        source="schedulesRemaining"
                        label="resources.server.cron.schedules_remaining"
                    />
                    <FunctionField
                        source="state"
                        label="resources.server.cron.state"
                        render={() => <StateChip />}
                    />
                </Datagrid>
            )}
        </List>
    );
};

export default CronJobList;
