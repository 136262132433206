import {Product} from "../types";

export const productNameWithColorAndSize = (product: Product, withSku: boolean = false): string => {
    let name = product.name;

    if (withSku) {
        name += ` #${product.sku}`;
    }
    
    if (product?.size || product?.color) {
        name += ' (';

        if (product?.color) {
            name += product.color;
        }

        if (product?.size) {
            if (product?.color) {
                name += ', ';
            }
            name += product.size;
        }

        name += ')';
    }

    return name;
};
