import * as React from "react";

import {AutocompleteInput, required, SimpleForm, FileInput, FileField} from "react-admin";
import {Product} from "../types";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import {Grid} from "@mui/material";

export const languages = {
    _ml: 'Multilingual',
    de: 'German',
    en: 'English',
    fr: 'French'
};

const ProductManualCreateForm = (props: { record: Product }) => {

    const languages = [
        {
            id: '_ml',
            name: 'Multilingual'
        },
        {
            id: 'de',
            name: 'German'
        },
        {
            id: 'en',
            name: 'English'
        },
        {
            id: 'fr',
            name: 'French'
        },
    ];
    const types = [
        {
            id: 'DESKTOP',
            name: 'Desktop'
        },
        {
            id: 'MOBILE',
            name: 'Mobile'
        }
    ];

    return (
        <CreateInDialogButton
            record={{product: `/products/${props.record.id}`}}
            title={`Add new file`}
        >
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AutocompleteInput
                            optionText="name"
                            optionValue="id"
                            fullWidth={true}
                            choices={languages}
                            name="language"
                            validate={[required()]}
                            label={`File language`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AutocompleteInput
                            optionText="name"
                            optionValue="id"
                            fullWidth={true}
                            choices={types}
                            name="type"
                            validate={[required()]}
                            label={`File is designed for: `}
                        />
                    </Grid>
                </Grid>
                <FileInput source="manual" name={`manual`} accept="application/pdf">
                    <FileField source="src" title="title"/>
                </FileInput>
            </SimpleForm>
        </CreateInDialogButton>
    );
};

export default ProductManualCreateForm;
