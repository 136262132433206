import {GetTreeParams, TreeDataProvider, TreeRecord} from '@react-admin/ra-tree';
import {DataProvider} from 'react-admin';

export const addTreeMethodsBasedOnChildren = (
    dataProvider: DataProvider,
    childrenField = 'children',
    isRootField = 'isRoot'
): DataProvider & TreeDataProvider => {

    const treeDataProvider: TreeDataProvider & DataProvider = {
        ...dataProvider,
        getTree: async <RecordType extends TreeRecord = TreeRecord>(
            resource: string,
            params?: GetTreeParams | undefined
        ) => {

            const {data} = await dataProvider.getList<RecordType>(resource, {
                filter: params?.meta?.filter ?? [],
                sort: {field: 'id', order: 'ASC'},
                pagination: {page: 1, perPage: 1000},
                meta: params?.meta
            });

            const items = data.map((item) => {
                const children = item?.children ? item.children.map((child) => {
                    const iriParts = (child as string).split('/');
                    return parseInt(iriParts[iriParts.length - 1], 10);
                }) : [];

                return {
                    ...item,
                    children
                };
            });

            return {
                data: items
            };
        },
        getRootNodes: async <RecordType extends TreeRecord = TreeRecord>(
            resource: string,
            params: { meta?: any; }
        ) => {

            const {data} = await dataProvider.getList<RecordType>(resource, {
                filter: {[isRootField]: true},
                sort: {field: 'id', order: 'ASC'},
                pagination: {page: 1, perPage: 1000},
                meta: params?.meta,
            });
            return {
                data: data,
            };
        },

        moveAsNthChildOf: async (
            resource,
            {source, destination, position, meta}
        ) => {
            await dataProvider.patch(`${resource}/${source.id}/move`, {
                data: {
                    destination: destination.id,
                    position,
                },
                meta,
            });

            return {data: source};
        },

        moveAsNthSiblingOf: async (
            resource,
            {source, destination, position, meta}
        ) => {
            await dataProvider.patch(`${resource}/${source.id}/move`, {
                data: {
                    destination: destination.id,
                    position,
                },
                meta,
            });

            return {data: source};
        },

        // @ts-ignore
        addChildNode: async <RecordType extends TreeRecord = TreeRecord>(
            resource,
            {parentId, data, meta}
        ) => {
            const newNode = await dataProvider.create(resource, {
                data: {
                    ...data,
                    parentId
                },
                meta,
            });

            return newNode;
        },
        deleteBranch: async <RecordType extends TreeRecord = TreeRecord>(resource, params) => {
            await dataProvider.delete(resource, params);

            return {data: params.previousData};
        },
    };

    return treeDataProvider;
};
