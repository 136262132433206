import * as React from 'react';
import { FC } from 'react';
import { useGetList, useUpdate, useNotify, useRefresh } from 'react-admin';
import { SelectChangeEvent } from '@mui/material/Select';
import { Select, MenuItem } from '@mui/material';

import { Coupon, DictionaryItem } from '../../utils/types';
const LAST_STATUS_ID = 'deactivated';

const AsideStatusSelector: FC<Coupon> = ({ id, status }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [update] = useUpdate();

    const { data: transitions } = useGetList(`coupon/${id}/transition`);
    const [current, setCurrent] = React.useState(status ?? 'draft');

    const handleChange = async (event: SelectChangeEvent) => {
        const value: string = event.target.value;

        if (value === current) {
            return;
        }
        try {
            const resp = await update(
                `coupon/${id}/transition`,
                { id: '', data: { transition: value } },
                { returnPromise: true }
            );
            setCurrent(resp.id);
            refresh();

            notify('resources.coupon.updated_successfully', {
                type: 'success',
                autoHideDuration: 5000,
            });
        } catch (error: any) {
            if (error?.body?.error?.message) {
                notify(error.body.error.message, {
                    type: 'error',
                    autoHideDuration: 5000,
                });
            }
        }
    };

    return (
        <Select
            style={{ width: '100%', marginBottom: '20px' }}
            value={current}
            onChange={handleChange}
            disabled={current === LAST_STATUS_ID}
            displayEmpty={true}
        >
            {transitions &&
                (transitions as DictionaryItem[]).map((option, key) => (
                    <MenuItem value={option.id} key={key++}>
                        {option.name}
                    </MenuItem>
                ))}
        </Select>
    );
};

export default AsideStatusSelector;
