import * as React from 'react';
import {Box, Card, CardContent, Grid, Link, Typography} from '@mui/material';
import {
    useRecordContext,
    useTranslate
} from 'react-admin';
import {ProductMarketplaceAllocation} from '../types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const AllocationDetails = () => {
    const t = useTranslate();
    const record = useRecordContext<ProductMarketplaceAllocation>();
    if (!record) return null;

    return (
        <Card sx={{width: '100%', margin: 'auto'}}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6} container alignContent="flex-start">
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Required attributes</TableCell>
                                        <TableCell align="right">Completed?</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {record?.requirements ? Object.keys(record.requirements).map((key) => {
                                        return (
                                            <TableRow
                                                key={key}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {
                                                        record.requirements[key].id == null ? record.requirements[key].name :
                                                            <Link href={`/#/attributes/${record.requirements[key].id}`}>
                                                                {record.requirements[key].name}
                                                            </Link>
                                                    }
                                                </TableCell>
                                                <TableCell align="right">
                                                    {
                                                        record.requirements[key].value ? t('ra.boolean.true') : t('ra.boolean.false')
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={6} container alignContent="flex-start">
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Optional attributes</TableCell>
                                        <TableCell align="right">Completed?</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {record?.optionals ? Object.keys(record.optionals).map((key) => {
                                        return (
                                            <TableRow
                                                key={key}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {
                                                        record.optionals[key].id == null ? record.optionals[key].name :
                                                            <Link
                                                                href={`/#/attributes/${record.optionals[key].id}`}>{record.optionals[key].name}</Link>
                                                    }
                                                </TableCell>
                                                <TableCell align="right">
                                                    {
                                                        record.optionals[key].value ? t('ra.boolean.true') : t('ra.boolean.false')
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Box height={20}>&nbsp;</Box>
            </CardContent>
        </Card>
    );
};

export default AllocationDetails;
