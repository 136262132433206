import * as React from 'react';
import { FC } from 'react';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import {
    PlaylistAddCheck as LibraryAddCheckIcon,
    Check as CheckIcon
} from '@mui/icons-material';

const Aside: FC = () => {
    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                width: '15em',
                marginRight: '1em',
                overflow: 'initial',
            }}
        >
            <CardContent>
                <FilterLiveSearch />

                <FilterList
                    label="resources.coupon.filters.status"
                    icon={<CheckIcon />}
                >
                    <FilterListItem
                        label="resources.coupon.filters.draft"
                        value={{
                            status: 'draft',
                        }}
                    />
                    <FilterListItem
                        label="resources.coupon.filters.on_hold"
                        value={{
                            status: 'on_hold',
                        }}
                    />
                    <FilterListItem
                        label="resources.coupon.filters.active"
                        value={{
                            status: 'active',
                        }}
                    />
                    <FilterListItem
                        label="resources.coupon.filters.deactivated"
                        value={{
                            status: 'deactivated',
                        }}
                    />
                </FilterList>

                <FilterList
                    label="resources.coupon.filters.operative"
                    icon={<LibraryAddCheckIcon />}
                >
                    <FilterListItem
                        label="resources.coupon.filters.yes"
                        value={{
                            operative: true,
                        }}
                    />
                    <FilterListItem
                        label="resources.coupon.filters.no"
                        value={{
                            operative: false,
                        }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;
