import * as React from 'react';
import { Chip, SxProps } from '@mui/material';
import { useTranslate, useRecordContext } from 'react-admin';
import { CronJob } from '../types';
import { cronJobStates } from './cronJobStates';

const StateChip = (props: { sx: SxProps }) => {
    const translate = useTranslate();
    const record = useRecordContext<CronJob>();
    if (!record) {
        return null;
    }

    const state = cronJobStates[record.state];

    return (
        <Chip
            color={state.color}
            size="small"
            key={state.id}
            label={translate(state.translationKey)}
            sx={props.sx}
        />
    );
};

StateChip.defaultProps = {
    sx: {},
};

export default StateChip;
