import * as React from "react";
import {useRecordContext, useTranslate, Loading, EditButton} from "react-admin";
import {Divider, CardHeader, CardContent, Card, Tooltip} from "@mui/material";
import {AccountTree, Edit as EditIcon} from "@mui/icons-material";

import {
    TreeInput,
    useGetTree
} from '@react-admin/ra-tree';

const TreePreview = () => {
    const t = useTranslate();
    const record = useRecordContext();

    const {data: categories, isLoading} = useGetTree(
        'category-trees',
        {
            meta: {
                filter: {
                    category: record.id
                }
            }
        }
    );

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <Card sx={{minWidth: 200}} variant='outlined'>
            <CardHeader
                avatar={
                    <AccountTree/>
                }
                action={
                    <EditButton aria-label="settings" resource={`category-trees`} record={{id: record?.categoryMainTreeNode?.id}}>
                        <EditIcon/>
                    </EditButton>
                }
                title={t('resources.category.edit.tree')}
            />
            <CardContent>
                <Divider sx={{marginTop: '-10px'}}/>
                <TreeInput source="" treeData={categories} hideRootNodes/>
            </CardContent>
        </Card>
    );
};

export default TreePreview;
