import * as React from 'react';
import { FC, useCallback } from 'react';
import { redirect } from 'react-router-dom';
import {
    Edit,
    EditProps,
    TextInput,
    SelectInput,
    PasswordInput,
    useTranslate,
    required,
    email,
    SimpleForm,
    minLength,
    useUpdate,
    useRecordContext,
    useNotify,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import MfaEditSection from './MfaEditSection';
import { UserEditType } from '../types';
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const UserEdit: FC<EditProps> = props => {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <UserEditForm />
        </Edit>
    );
};

const validatePasswords = async (values: UserEditType) => {
    const errors = {};

    if (values?.password) {
        if (
            !values.passwordRepeat ||
            values.passwordRepeat !== values.password
        ) {
            (errors as UserEditType).passwordRepeat =
                'resources.user.validation.password_dont_match';
        }
    }

    return errors;
};

const UserEditForm = (props: any) => {
    const record = useRecordContext(props);
    const notify = useNotify();
    const translate = useTranslate();
    const [update] = useUpdate();
    useDefineAppLocation('settings.settings_user.edit', {record});

    const save = useCallback(
        async values => {
            try {
                await update(
                    `user`,
                    { id: record.id, data: values },
                    { returnPromise: true }
                );

                notify('resources.user.updated_successfully', {
                    type: 'success',
                    autoHideDuration: 5000,
                });
            } catch (error: any) {
                const e = error?.body?.error;

                if (e?.errors) {
                    return error.body.error.errors;
                }
            }
        },
        [update, notify, redirect]
    );

    return (
        <SimpleForm {...props} validate={validatePasswords} onSubmit={save}>
            <Typography variant="h6" gutterBottom>
                {translate('resources.user.fieldGroups.identity')}
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }} sx={{width: '100%'}}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                        // label={`resources.user.fields.fullName`}
                        source="firstName"
                        resource="user"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                        source="lastName"
                        resource="user"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>
            </Box>

            <Typography variant="h6" gutterBottom>
                {translate('resources.user.fieldGroups.contact')}
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }} sx={{width: '100%'}}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                        type="email"
                        source="email"
                        resource="user"
                        validate={[email(), required()]}
                        fullWidth
                    />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source="phone" resource="user" fullWidth />
                </Box>
            </Box>

            <Typography variant="h6" gutterBottom>
                {translate('resources.user.fieldGroups.access_role')}
            </Typography>
            <SelectInput
                label={`resources.user.fields.accessRole`}
                name="accessRoleId"
                source="accessRoleId"
                choices={[
                    { id: 1, name: 'Admin' },
                    { id: 2, name: 'User' },
                ]}
            />

            <Typography variant="h6" gutterBottom>
                {translate('resources.user.fieldGroups.change_password')}
            </Typography>
            <PasswordInput
                label={`resources.user.fields.oldPassword`}
                source="oldPassword"
                resource="user"
                fullWidth
            />
            <Box display={{ xs: 'block', sm: 'flex' }} sx={{width: '100%'}}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <PasswordInput
                        label={`resources.user.fields.newPassword`}
                        source="password"
                        resource="user"
                        validate={[minLength(6)]}
                        fullWidth
                    />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <PasswordInput
                        label={`resources.user.fields.repeatNewPassword`}
                        source="passwordRepeat"
                        resource="user"
                        fullWidth
                    />
                </Box>
            </Box>
            <MfaEditSection />
        </SimpleForm>
    );
};

const requiredValidate = [required()];

export default UserEdit;
