import * as React from 'react';
import { FC } from 'react';
import { useMediaQuery, Theme, Box, Card } from '@mui/material';
import {
    CreateButton,
    Datagrid,
    ExportButton,
    ListBase,
    ListProps,
    Pagination,
    TextField,
    DateField,
    Title,
    TopToolbar,
    useTranslate,
} from 'react-admin';
import Aside from './Aside';
import {ThemeOptions} from "@react-admin/ra-enterprise";
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const ListActions: FC<any> = ({ isSmall }) => (
    <TopToolbar>
        <CreateButton label={`resources.coupon.create_new`} />
        <ExportButton />
    </TopToolbar>
);

const CouponListView: FC<{ isSmall: boolean }> = ({ isSmall }) => {
    const t = useTranslate();

    const style = {
        card: (theme: ThemeOptions) => {
            return {
                // @ts-ignore
                [theme?.breakpoints?.up('sm')]: {
                    width: '100%',
                },
                // @ts-ignore
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                }
            };
        }
    };


    return (
        <>
            <Title title={t(`resources.coupon.list_of_coupons`)} />
            <ListActions isSmall={isSmall} />
            <Box display="flex">
                <Aside />
                <Card sx={style.card}>
                    <Datagrid optimized rowClick="edit">
                        <TextField source="id" sortable={false} />
                        <TextField
                            source="name"
                            label="resources.coupon.name"
                        />
                        <TextField
                            source="status"
                            label="resources.coupon.status.label"
                        />
                        <DateField
                            label="resources.coupon.start_date_label"
                            source="startDate"
                            locales="de-DE"
                            options={{
                                weekday: undefined,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            }}
                        />
                        <TextField
                            label="resources.coupon.created_by_label"
                            source="createdBy.name"
                        />
                    </Datagrid>{' '}
                    <Pagination rowsPerPageOptions={[10, 20, 40]} />
                </Card>
            </Box>
        </>
    );
};

const CouponList: FC<ListProps> = props => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    useDefineAppLocation('sales.coupons');

    return (
        <ListBase
            perPage={10}
            sort={{ field: 'startDate', order: 'DESC' }}
            {...props}
        >
            <CouponListView isSmall={isSmall} />
        </ListBase>
    );
};

export default CouponList;
