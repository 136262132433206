import * as React from 'react';
import {ReactElement, FC} from 'react';
import {
    useAuthProvider,
    useGetIdentity,
    useSavedQueries,
    useTranslate,
    usePermissions
} from 'react-admin';
import {Avatar, Typography} from '@mui/material';
import {SolarMenu} from '@react-admin/ra-navigation';
import {
    Search as SearchIcon,
    People as PeopleIcon,
    RequestQuote as OrderIcon,
    LocalOffer as CouponIcon,
    SettingsApplications as SettingsApplicationsIcon,
    Receipt as SalesIcon,
    Category as CatalogIcon,
    FormatListNumbered as ProductsIcon,
    Quiz as InquiriesIcon,
} from '@mui/icons-material';
import querystring from 'query-string';
import {hasAccess} from 'ra-auth-acl';

import {SearchSubMenu} from './SearchSubMenu';
import {ProfileSubMenu} from './ProfileSubMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ListIcon from '@mui/icons-material/List';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StoreIcon from '@mui/icons-material/Store';

export const Menu = () => {
    const {permissions} = usePermissions();

    const hasAccessToUser = hasAccess(permissions, 'user.list');
    const hasAccessToCronJobs = hasAccess(permissions, 'server.list');
    const hasAccessToOrder = hasAccess(permissions, 'order.list');
    const hasAccessToCoupons = hasAccess(permissions, 'coupon.list');
    const hasAccessToBackInStock = hasAccess(permissions, 'backinstock.list');
    const hasAccessToProducts = hasAccess(permissions, 'product.list');

    return (<SolarMenu bottomToolbar={<CustomBottomToolbar/>}>
        <SolarMenu.DashboardItem/>

        {(hasAccessToOrder || hasAccessToCoupons) && (
            <SalesMenuItem hasAccessToOrder={hasAccessToOrder} hasAccessToCoupons={hasAccessToCoupons}/>
        )}
        {(hasAccessToBackInStock || hasAccessToProducts) && (
            <ProductsMenuItem hasAccessToBackInStock={hasAccessToBackInStock}
                              hasAccessToProducts={hasAccessToProducts}/>
        )}
        {(hasAccessToUser || hasAccessToCronJobs) && (
            <SettingsMenuItem hasAccessToUser={hasAccessToUser} hasAccessToCronJobs={hasAccessToCronJobs}/>
        )}
        {hasAccessToProducts && (
            <ReportMenuItem/>
        )}
    </SolarMenu>);
};

interface SettingsMenuItemProps {
    hasAccessToUser: Boolean;
    hasAccessToCronJobs: Boolean;
}

const SettingsMenuItem: FC<SettingsMenuItemProps> = ({hasAccessToUser, hasAccessToCronJobs}): ReactElement => {
    const translate = useTranslate();

    return (
        <SolarMenu.Item
            name="settings"
            icon={<SettingsApplicationsIcon/>}
            label="resources.server.main_menu_label"
            subMenu={
                <>
                    <Typography variant="h6" gutterBottom ml={1}>
                        {translate(`resources.server.main_menu_label`)}
                    </Typography>
                    <SolarMenu.List dense>
                        {hasAccessToUser && (
                            <SolarMenu.Item
                                name="settings_user"
                                to="/user"
                                icon={<PeopleIcon/>}
                                label="resources.user.name"
                            />
                        )}
                        {hasAccessToCronJobs && (
                            <SolarMenu.Item
                                name="settings_cron"
                                to="/server/cron-jobs"
                                icon={<SettingsApplicationsIcon/>}
                                label={translate(`resources.server.cron.main_menu_label`)}
                            />
                        )}
                        <SolarMenu.Item
                            name="marketplaces"
                            to="/marketplaces"
                            icon={<StorefrontIcon/>}
                            label={translate(`resources.marketplace.main_menu_label`)}
                        />
                        <SolarMenu.Item
                            name="attributes"
                            to="/attributes"
                            icon={<ListIcon/>}
                            label={translate(`resources.attributes.main_menu_label`)}
                        />
                        <SolarMenu.Item
                            name="dealers"
                            to="/dealers"
                            icon={<StoreIcon/>}
                            label={translate(`resources.dealers.main_menu_label`)}
                        />
                    </SolarMenu.List>
                </>
            }
        />
    );
};

interface SalesMenuItemProps {
    hasAccessToOrder: Boolean;
    hasAccessToCoupons: Boolean;
}

const SalesMenuItem: FC<SalesMenuItemProps> = ({hasAccessToOrder, hasAccessToCoupons}): ReactElement => {
    const translate = useTranslate();

    return (
        <SolarMenu.Item
            name="sales"
            icon={<SalesIcon/>}
            label="resources.sales_menu_label"
            subMenu={
                <>
                    <Typography variant="h6" gutterBottom ml={1}>
                        {translate(`resources.sales_menu_label`)}
                    </Typography>
                    <SolarMenu.List dense>
                        {hasAccessToOrder && (
                            <SolarMenu.Item
                                name="sales_order"
                                to="/order"
                                icon={<OrderIcon/>}
                                label="resources.order.main_menu_label"
                            />
                        )}
                        {hasAccessToCoupons && (
                            <SolarMenu.Item
                                name="sales_coupon"
                                to="/coupon"
                                icon={<CouponIcon/>}
                                label={`resources.coupon.main_menu_label`}
                            />
                        )}
                    </SolarMenu.List>
                </>
            }
        />
    );
};

interface ProductsMenuItemProps {
    hasAccessToBackInStock: Boolean;
    hasAccessToProducts: Boolean;
}

const ProductsMenuItem: FC<ProductsMenuItemProps> = ({hasAccessToBackInStock, hasAccessToProducts}): ReactElement => {
    const translate = useTranslate();

    return (
        <SolarMenu.Item
            name="catalog"
            icon={<CatalogIcon/>}
            label="resources.catalog_menu_label"
            subMenu={
                <>
                    <Typography variant="h6" gutterBottom ml={1}>
                        {translate(`resources.catalog_menu_label`)}
                    </Typography>
                    <SolarMenu.List dense>
                        {hasAccessToProducts && (
                            <SolarMenu.Item
                                name="catalog.products"
                                to="/products"
                                icon={<ProductsIcon/>}
                                label="resources.products.main_menu_label"
                            />
                        )}
                        {hasAccessToBackInStock && (
                            <SolarMenu.Item
                                name="catalog_back-in-stock"
                                to="/back-in-stock"
                                icon={<InquiriesIcon/>}
                                label={`resources.back_in_stock.main_menu_label`}
                            />
                        )}
                    </SolarMenu.List>
                </>
            }
        />
    );
};
const ReportMenuItem: FC = (): ReactElement => {
    const translate = useTranslate();

    return (
        <SolarMenu.Item
            name="report"
            icon={<AssessmentIcon/>}
            label={`resources.reports.main_menu_label`}
            subMenu={
                <>
                    <Typography variant="h6" gutterBottom ml={1}>
                        {translate(`resources.reports.main_menu_label`)}
                    </Typography>
                    <SolarMenu.List dense>
                        <SolarMenu.Item
                            name="report.products"
                            to="/product-marketplace-allocations"
                            icon={<ProductsIcon/>}
                            label="resources.reports.products_allocations_main_menu_label"
                        />
                    </SolarMenu.List>
                </>
            }
        />
    );
};

const usePersistedQueriesMenu = (
    resource: string
): { label: string; to: string }[] => {
    const [savedQueries] = useSavedQueries(resource);

    return savedQueries.map(({label, value}) => ({
        label,
        to: `/${resource}?${querystring.stringify({
            filter: JSON.stringify(value.filter),
            sort: value?.sort?.field,
            order: value?.sort?.order,
            page: 1,
            perPage: value.perPage,
            displayedFilters: value.displayedFilters,
        })}`,
    }));
};

const CustomBottomToolbar = () => (
    <>
        {/*<SearchMenuItem/>*/}
        <SolarMenu.LoadingIndicatorItem/>
        <SolarMenuUserItem/>
    </>
);

const SearchMenuItem = () => (
    <SolarMenu.Item
        icon={<SearchIcon/>}
        label="pos.search"
        name="search"
        subMenu={<SearchSubMenu/>}
        data-testid="search-button"
    />
);

const SolarMenuUserItem = () => {
    const {isLoading, identity} = useGetIdentity();
    const authProvider = useAuthProvider();

    if (isLoading) return null;
    const avatarSx = {maxWidth: '1.4em', maxHeight: '1.4em'};
    return (
        <SolarMenu.Item
            icon={
                authProvider ? (
                    identity?.avatar ? (
                        <Avatar
                            src={identity.avatar}
                            alt={identity.fullName}
                            sx={avatarSx}
                        />
                    ) : (
                        <Avatar sx={avatarSx}/>
                    )
                ) : (
                    <SettingsIcon/>
                )
            }
            label="pos.profile"
            name="profile"
            subMenu={<ProfileSubMenu/>}
            data-testid="profile-button"
        />
    );
};
