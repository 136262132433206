import React from 'react';
import {
    FormDataConsumer,
    SimpleForm,
    TextInput,
    TranslatableInputs,
    useLocaleState,
    Toolbar,
    TextField,
    SaveButton,
    useGetOne,
    Loading,
    ArrayField,
    ChipField,
    SingleFieldList,
    FunctionField,
    Labeled
} from 'react-admin';

import {EditNode, TreeWithDetails, NodeActions, DeleteMenuItemWithConfirmation} from '@react-admin/ra-tree';
import CreateChildNodeForm from "./CreateChildNodeForm";
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {useParams} from 'react-router-dom';
import {Product} from "../types";

const MyActions = (props) => {
    if (props.data.root.id == undefined) {
        return null;
    }

    return (
        <NodeActions {...props}>
            <DeleteMenuItemWithConfirmation
                redirect={`${props.data.root.id}`}
                title={props.data.title}
                confirmTitle={`resources.category.edit.associated_products`}
                confirmContent={props.data.title}
            />
        </NodeActions>
    );
};

const transform = (data: any, options?: { previousData: any }) => {
    const title = data.title_translatable[data.locale];
    delete data.locale;

    return {
        ...data,
        title,
    };
};
const CategoriesEdit = () => {
    const [locale, setLocale] = useLocaleState();

    return (
        <EditNode
            redirect={false}
            mutationMode="optimistic"
            transform={transform}
            actions={<CreateChildNodeForm/>}
        >
            <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
                <FormDataConsumer>
                    {({formData}) => {
                        let record = formData;

                        return <React.Fragment>
                            {record.isMainRoot ?
                                <TextField source="title" name="title"/> :
                                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                                    <TextInput source="title_translatable" label={`resources.category.edit.title`}/>
                                </TranslatableInputs>}

                            {record.isMainRoot ? null : <TextInput source="code" name="code" fullWidth/>}
                            <TextInput source="locale" name="locale" hidden={true} defaultValue={locale}
                                       sx={{display: 'none'}}/>
                            <Labeled label={`resources.category.edit.associated_products`}>
                                <ArrayField source="products">
                                    <SingleFieldList linkType={false}>
                                        <FunctionField
                                            source="netPrice"
                                            label="resources.products.show.price"
                                            sortable={false}
                                            textAlign={`right`}
                                            render={(product: Product) =>
                                                <ChipField
                                                    record={{name: `${product.product.name} #${product.product.sku}`}}
                                                    source="name"
                                                    size="small"
                                                />
                                            }
                                        />
                                    </SingleFieldList>
                                </ArrayField>
                            </Labeled>
                        </React.Fragment>;
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </EditNode>
    );
};

export const CategoriesList = () => {
    const params = useParams();
    const id = parseInt((params['*'] as string), 10);

    const {data: tree, isLoading} = useGetOne(
        'category-trees',
        {
            id,
        }
    );
    useDefineAppLocation('settings.marketplaces.marketplace.category.tree_edit', {
        categoryRecord: tree?.category,
        marketplaceRecord: tree?.category.marketplace
    });
    if (isLoading) {
        return <Loading/>;
    }

    const root = tree.root.id ?? id;

    return (
        <TreeWithDetails
            draggable
            edit={CategoriesEdit}
            meta={{filter: {root}}}
            nodeActions={<MyActions/>}
        />
    );
};
