import * as React from 'react';
import { FC, CSSProperties } from 'react';
import { DateInput, required, TextInput, useTranslate } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import endDateValidator from '../validation/endDateValidator';

const CouponEditBaseTab: FC = () => {
    const translate = useTranslate();
    const boxStyle = { width: '100%' };

    return (
        <>
            <Typography variant="h6" gutterBottom>
                {translate('resources.coupon.base_data_label')}
            </Typography>
            <TextInput
                name="name"
                source="name"
                resource="coupon"
                validate={[required()]}
                fullWidth
            />
            <RichTextInput
                source="description"
                resource="coupon"
                label={translate('resources.coupon.description')}
                fullWidth
            />

            <Typography variant="h6" gutterBottom>
                {translate('resources.coupon.period_of_time')}
            </Typography>
            <Box
                display={{ xs: 'block', sm: 'flex' }}
                style={boxStyle as CSSProperties}
            >
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <DateInput
                        name="startDate"
                        source="startDate"
                        resource="coupon"
                        label={`resources.coupon.start_date`}
                        validate={[required()]}
                        fullWidth
                    />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <DateInput
                        name="endDate"
                        source="endDate"
                        resource="coupon"
                        label={`resources.coupon.end_date`}
                        validate={[required(), endDateValidator]}
                        fullWidth
                    />
                </Box>
            </Box>
        </>
    );
};

export default CouponEditBaseTab;
