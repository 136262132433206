import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    number,
    required,
    minValue,
    maxValue,
    useTranslate,
    SaveButton,
    Toolbar,
    ToolbarProps,
    Create,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import { NumberInput, BooleanInput } from 'react-admin';

import {
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Button,
    Box,
} from '@mui/material';
import { SimpleForm } from 'ra-ui-materialui';
import { Conditions } from '../conditionTypes';

const CouponEditCodesTab: FC = props => {
    const translate = useTranslate();

    const refresh = useRefresh();
    const record = useRecordContext(props);
    const [open, setOpen] = React.useState(false);

    const shouldBeOneTimeUse: boolean =
        record?.condition?.type === Conditions.Email;

    const MyToolbar: FC<ToolbarProps> = props => (
        <Toolbar {...props}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 1,
                    m: 1,
                    width: '100%',
                }}
            >
                <SaveButton label={'resources.coupon.generate'} />
                <Button onClick={() => setOpen(false)} variant={'outlined'}>
                    {translate('resources.coupon.close')}
                </Button>
            </Box>
        </Toolbar>
    );

    return (
        <Fragment>
            <Button variant="outlined" onClick={() => setOpen(true)}>
                {translate('resources.coupon.generate_batch')}
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    {translate('resources.coupon.code_generator')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate(
                            'resources.coupon.code_generator_description'
                        )}
                    </DialogContentText>
                    <Create
                        resource={`coupon/${record.id}/code`}
                        redirect={`/coupon/${record.id}`}
                        {...props}
                        mutationOptions={{
                            onSuccess: () => {
                                setOpen(false);
                                refresh();
                            },
                        }}
                    >
                        <SimpleForm toolbar={<MyToolbar />}>
                            <NumberInput
                                name="codesQuantity"
                                source="codesQuantity"
                                step={1}
                                fullWidth
                                min={1}
                                max={1000}
                                validate={[
                                    required(),
                                    number(),
                                    minValue(1),
                                    maxValue(1000),
                                ]}
                            />
                            <NumberInput
                                name="lengthOfCode"
                                source="lengthOfCode"
                                step={1}
                                fullWidth
                                min={2}
                                max={20}
                                validate={[
                                    required(),
                                    number(),
                                    minValue(2),
                                    maxValue(20),
                                ]}
                            />
                            <BooleanInput
                                label="resources.coupon.all_one_time_use"
                                name="oneTimeUse"
                                source="oneTimeUse"
                                value={shouldBeOneTimeUse}
                                defaultValue={shouldBeOneTimeUse}
                                disabled={shouldBeOneTimeUse}
                            />
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default CouponEditCodesTab;
