export const formatNumberAsUSD = (
    value: number,
    minimumFractionDigits = 0
): string =>
    new Intl.NumberFormat(undefined, USDFormat(minimumFractionDigits)).format(
        value
    );

export const formatPrice = (
    value: string,
    currency: 'EUR' | 'USD' | 'GBP' | 'CHF' | 'PLN' | 'CZK' | 'HUF' | 'RUB' | 'SEK' | 'NOK' | 'DKK',
    minimumFractionDigits = 2,
    maximumFractionDigits = 2
): string =>
    new Intl.NumberFormat(undefined, PriceFormat(currency, minimumFractionDigits, maximumFractionDigits)).format(
        value as unknown as number
    );

export const USDFormat = (minimumFractionDigits = 0) => ({
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits,
});

const PriceFormat = (
    currency: 'EUR' | 'USD' | 'GBP' | 'CHF' | 'PLN' | 'CZK' | 'HUF' | 'RUB' | 'SEK' | 'NOK' | 'DKK',
    minimumFractionDigits: number = 2,
    maximumFractionDigits: number = 2) => (
    {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits,
        maximumFractionDigits
    }
);
