import { CronJobSchedule } from '../../types';
import { FieldValues } from 'react-hook-form';

export default async (values: FieldValues) => {
    const errors: CronJobSchedule | {} = {};

    if (!values.timeStart) {
        (errors as CronJobSchedule).timeStart = 'ra.validation.required';
    }

    if (!values.timeEnd) {
        (errors as CronJobSchedule).timeEnd = 'ra.validation.required';
    }

    if (values?.timeEnd && values?.timeStart) {
        const start = new Date(values.timeStart as string).getTime();
        const end = new Date(values.timeEnd as string).getTime();

        if (end <= start) {
            (errors as CronJobSchedule).timeEnd =
                'resources.server.cron.validation.start_end_comparison';
        }
    }

    return errors;
};
