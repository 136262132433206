import {
    AutocompleteArrayInput,
    SimpleForm,
    useNotify,
    useTranslate,
    useUnselectAll,
    useRefresh
} from "react-admin";
import {BulkUpdateFormButton} from "@react-admin/ra-form-layout";
import * as React from "react";
import {Typography} from "@mui/material";
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';

const TagsBulkUpdateButton = ({tags}) => {
    const t = useTranslate();
    const unselectAll = useUnselectAll('products');
    const notify = useNotify();
    const refresh = useRefresh();

    return (
        <BulkUpdateFormButton
            icon={<LocalOfferIcon/>}
            label={`resources.products.list.update_tags`}
            title={`resources.products.show.update_tags`}
            DialogProps={
                {
                    sx: {
                        '& .MuiDialog-paper': {minWidth: {md: '30%'}},
                    }
                }
            }
            mutationOptions={{
                onError: (error: any) => {
                    const message = error?.body?.error?.message;

                    if (message) {
                        notify(message, {
                            type: 'error',
                            autoHideDuration: 5000,
                        });
                    }
                    //
                    let close = document.getElementsByClassName('MuiModal-backdrop');
                    // @ts-ignore
                    close[0].click();
                },
                onSuccess: () => {
                    unselectAll();
                    refresh();
                }
            }}
        >
            <Typography variant="h6" component="h2" sx={{marginTop: '-22px', paddingLeft: '16px'}}>
                {t(`resources.products.list.update_tags_dialog_title`)}
            </Typography>
            <SimpleForm>
                <AutocompleteArrayInput source="tags" name="tags" choices={tags} fullWidth/>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}


export default TagsBulkUpdateButton;
