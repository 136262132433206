import * as React from 'react';
import { FC } from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    FilterProps,
    SearchInput,
    EmailField,
    usePermissions,
    BulkDeleteWithConfirmButton,
    BooleanField,
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';

import MobileGrid from './MobileGrid';
import { ListWithPermissionsProps } from '../types';
import BulkForceMfaButton from './BulkForceMfaButton';
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const ListFilters = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" name="q" alwaysOn />
    </Filter>
);

const UsersList: FC<ListWithPermissionsProps> = props => {
    const { permissions } = usePermissions();

    const isXsmall = useMediaQuery((theme: Theme) => {
        return theme.breakpoints.down('xs');
    });
    const PostBulkActionButtons = () => (
        <>
            {permissions?.usermfa?.enabled ? <BulkForceMfaButton /> : null}
            <BulkDeleteWithConfirmButton mutationMode="undoable" />
        </>
    );
    useDefineAppLocation('settings.settings_user');

    return (
        <List
            {...props}
            filters={<ListFilters />}
            sort={{ field: 'lastName', order: 'DESC' }}
            perPage={25}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick={permissions?.user?.edit ? `edit` : `show`}
                    bulkActionButtons={<PostBulkActionButtons />}
                >
                    <TextField source="id" />
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <EmailField source="email" />
                    <TextField source="phone" />
                    <BooleanField source="isMfaEnabled" />
                    <TextField
                        source="accessRoleName"
                        label="resources.user.fields.roleName"
                        sortable={false}
                    />
                </Datagrid>
            )}
        </List>
    );
};

export default UsersList;
