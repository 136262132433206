import DiscountIcon from '@mui/icons-material/LocalOffer';

import CouponList from './CouponList';
import CouponEdit from './edit/CouponEdit';
import CouponCreate from './CouponCreate';

export default {
    list: CouponList,
    edit: CouponEdit,
    create: CouponCreate,
    icon: DiscountIcon,
};
