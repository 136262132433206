import * as React from 'react';
import {FC} from 'react';
import {Card, CardContent, CardHeader} from '@mui/material';
import {
    EditButton,
    useTranslate,
    Identifier,
    TextField,
    EmailField,
} from 'react-admin';

import {User} from '../types';
import AvatarField from './AvatarField';

interface Props {
    ids?: Identifier[];
    data?: { [key: string]: User };
    basePath?: string;
}

const MobileGrid: FC<Props> = ({ids, data, basePath}) => {
    const translate = useTranslate();

    if (!ids || !data) {
        return null;
    }

    return (
        <div style={{margin: '1em'}}>
            {ids.map(id => (
                <Card key={id}
                      sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '0.5rem 0',
                      }}>
                    <CardHeader
                        title={
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <h2>{`${data[id].firstName} ${data[id].lastName}`}</h2>
                                <EditButton
                                    resource="visitors"
                                    record={data[id]}
                                />
                            </div>
                        }
                        avatar={<AvatarField record={data[id]} size="45"/>}
                    />
                    <CardContent sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <div>
                            {translate('resources.user.fields.email')}
                            :&nbsp;
                            <EmailField record={data[id]} source="email"/>
                        </div>
                        <div>
                            {translate('resources.user.fields.phone')}
                            :&nbsp;
                            <TextField
                                record={data[id]}
                                source="phone"
                                label="resources.user.fields.phone"
                            />
                        </div>
                        <div>
                            {translate('resources.user.fields.roleName')}
                            :&nbsp;
                            <TextField
                                record={data[id]}
                                source="accessRoleName"
                            />
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
