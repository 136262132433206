import * as React from 'react';
import {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {
    BooleanField, BooleanInput, BulkDeleteWithConfirmButton,
    downloadCSV,
    ExportButton,
    FilterButton, FunctionField,
    List,
    NullableBooleanInput,
    NumberField, NumberInput,
    required,
    TextField,
    TextInput,
    TopToolbar,
    useListContext, useRecordContext, useTranslate,
} from 'react-admin';
import {useMediaQuery, Divider, Tabs, Tab, Theme, Link} from '@mui/material';

import MobileGrid from './MobileGrid';
import {Marketplace, Product, ProductMarketplaceAllocation} from '../types';
import {EditableDatagrid, EditRowButton, RowForm} from "@react-admin/ra-editable-datagrid";
import AllocationDetails from "../marketplace/AllocationDetails";
import {formatPrice} from "../formatUtils";
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import dataProvider from "../dataProvider";
import jsonExport from 'jsonexport/dist';
import {productNameWithColorAndSize} from "../utils/productNameWithColorAndSize";

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <ExportButton maxResults={10000}/>
    </TopToolbar>
);

const ProductsAllocationsList = () => {
    const [marketplaces, setMarketplaces] = useState<Marketplace[]>([]);
    const t = useTranslate();
    useEffect(() => {
        dataProvider.getList<Marketplace>(`marketplaces`, {
            filter: {},
            sort: {field: 'id', order: 'ASC'},
            pagination: {page: 1, perPage: 1000},
            meta: {}
        })
            .then(({data}) => {
                setMarketplaces(data);
            })
            .catch(error => {
            });
    }, []);

    const exporter = (allocations: ProductMarketplaceAllocation[]) => {
        const allocationsForExport = allocations.map(allocation => {
            return {
                [t(`resources.marketplace.edit.product`)]: allocation.product.name,
                [t(`resources.marketplace.edit.product_sku`)]: allocation.product.sku,
                // @ts-ignore
                [t(`resources.marketplace.edit.net_price`)]: allocation.netPrice.toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 5
                }),
                // @ts-ignore
                [t(`resources.marketplace.edit.promo_net_price`)]: allocation.promoNetPrice?.toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 5
                }),
                [t(`resources.marketplace.edit.min_inventory`)]: allocation.minInventory,
                [t(`resources.marketplace.edit.stock`)]: allocation.stock,
                [t(`resources.marketplace.edit.is_active`)]: allocation.isActive ? t(`ra.boolean.true`) : t(`ra.boolean.false`),
                [t(`resources.marketplace.edit.req_completion_percentage`)]: allocation?.requirementsPercentage ? allocation.requirementsPercentage : '0',
                [t(`resources.marketplace.edit.opt_completion_percentage`)]: allocation?.optionalsPercentage ? allocation.optionalsPercentage : '0',
                [t(`resources.marketplace.fields.name`)]: allocation.marketplace.name,
            };
        });
        jsonExport(allocationsForExport, {}, (err, csv) => {
            downloadCSV(csv, 'products_export');
        });
    };

    if (marketplaces?.length === 0) {
        return null;
    }

    return (
        <List
            filterDefaultValues={{marketplace: marketplaces[0].id}}
            perPage={25}
            filters={filters}
            actions={<ListActions/>}
            exporter={exporter}
        >
            <TabbedDatagrid marketplaces={marketplaces}/>
        </List>
    );
};

const filters = [
    <TextInput source="product.sku" label="SKU"/>,
    <NullableBooleanInput source="isActive" name="isActive"/>,
];

const AllocationForm = () => {
    const record = useRecordContext<ProductMarketplaceAllocation>();
    const marketplace = record.marketplace;

    return (
        <RowForm transform={(data: ProductMarketplaceAllocation) => {
            data.netPrice = String(data.netPrice);
            data.promoNetPrice = data.promoNetPrice ? String(data.promoNetPrice) : null;
            return data;
        }}>
            <TextField source="product.name"/>
            <TextField source="product.sku"/>
            {
                marketplace.isDefault ? <TextField source="netPrice"/> :
                    <TextInput source="netPrice" validate={required()} label="resources.marketplace.edit.net_price"/>
            }
            {
                marketplace.isDefault ? (marketplace.isRetail ?
                        <TextInput source="promoNetPrice" label="resources.products.show.promo_price"/> :
                        <TextField source="promoNetPrice"/>) :
                    <TextInput source="promoNetPrice" label="resources.products.show.promo_price"/>
            }
            <NumberInput source="minInventory" validate={required()} label="resources.products.show.min_inventory"/>
            <TextField source="stock"/>
            {
                marketplace.isDefault ? <BooleanField source="isActive"/> :
                    <BooleanInput source="isActive" validate={required()} label={``}/>
            }
            <FunctionField
                source="requirementsPercentage"
                label="resources.marketplace.edit.req_completion_percentage"
                render={(pma: ProductMarketplaceAllocation) => {
                    const color = pma.requirementsPercentage < 20 ? 'error.main' : (pma.requirementsPercentage < 99 ? 'rgb(2, 136, 209)' : '#00AC47');
                    return (
                        <NumberField
                            source="requirementsPercentage"
                            options={{style: 'percent', maximumFractionDigits: 0}}
                            sx={{color, fontWeight: 'fontWeightBold'}}
                            record={{requirementsPercentage: isNaN(pma?.requirementsPercentage) ? 0 : Number(pma?.requirementsPercentage / 100)}}
                        />
                    );
                }}
            />
            <FunctionField
                source="optionalsPercentage"
                label="resources.marketplace.edit.opt_completion_percentage"
                render={(pma: ProductMarketplaceAllocation) => {
                    const color = pma.optionalsPercentage < 20 ? 'error.main' : (pma.optionalsPercentage < 99 ? 'rgb(2, 136, 209)' : '#00AC47');
                    return (
                        <NumberField
                            source="optionalsPercentage"
                            options={{style: 'percent', maximumFractionDigits: 0}}
                            sx={{color, fontWeight: 'fontWeightBold'}}
                            record={{optionalsPercentage: isNaN(pma?.optionalsPercentage) ? 0 : Number(pma?.optionalsPercentage / 100)}}
                        />
                    );
                }}
            />
        </RowForm>
    );
};

const TabbedDatagrid = (props: { marketplaces: Marketplace[] }) => {
    const t = useTranslate();
    const listContext = useListContext();
    const {filterValues, setFilters, displayedFilters} = listContext;

    useDefineAppLocation('reports.productsAllocations');

    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
            setFilters(
                {...filterValues, marketplace: value},
                displayedFilters,
                false // no debounce, we want the filter to fire immediately
            );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.marketplace}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {props.marketplaces.map(choice => (
                    // TODO: remove this when SelectLine is removed from the database
                    choice.name === 'SelectLine' ? null :
                        <Tab
                            key={choice.id}
                            label={choice.name}
                            value={choice.id}
                        />
                ))}
            </Tabs>
            <Divider/>
            {isXSmall ? (
                <MobileGrid/>
            ) : (
                <EditableDatagrid
                    actions={<EditRowButton/>}
                    mutationMode="undoable"
                    editForm={<AllocationForm/>}
                    bulkActionButtons={
                        <>
                            <BulkDeleteWithConfirmButton mutationMode="optimistic"/>
                        </>
                    }
                    rowClick="expand"
                    expand={<AllocationDetails/>}
                    expandSingle={true}
                >
                    <FunctionField
                        source="product.name"
                        label={`resources.marketplace.edit.product`}
                        sortable={false}
                        render={(pma: ProductMarketplaceAllocation) => (
                            <Link href={`/#/products/${pma.product.id}`}>
                                {productNameWithColorAndSize(pma.product)}
                            </Link>
                        )}
                    />
                    <TextField source="product.sku" sortable={false}
                               label={`resources.marketplace.edit.product_sku`}/>
                    <FunctionField
                        source="netPrice"
                        label="resources.marketplace.edit.net_price"
                        textAlign={`right`}
                        render={(price: ProductMarketplaceAllocation) => formatPrice(price.netPrice, price.currency, 2, 5)}
                    />
                    <FunctionField
                        source="promoNetPrice"
                        label="resources.products.show.promo_price"
                        textAlign={`right`}
                        render={(price: ProductMarketplaceAllocation) => price.promoNetPrice ? formatPrice(price.promoNetPrice, price.currency, 2, 5) : 'n/a'}
                    />
                    <NumberField source="minInventory" label="resources.products.show.min_inventory"/>
                    <NumberField source="stock" label="resources.marketplace.edit.stock"
                                 sortable={false}/>
                    <BooleanField source="isActive" label={`resources.marketplace.edit.is_active`}/>
                    <FunctionField
                        source="requirementsPercentage"
                        label="resources.marketplace.edit.req_completion_percentage"
                        render={(pma: ProductMarketplaceAllocation) => {
                            const color = (isNaN(pma?.requirementsPercentage) || pma.requirementsPercentage < 20) ? 'error.main' : (pma.requirementsPercentage < 99 ? 'rgb(2, 136, 209)' : '#00AC47');
                            return (
                                <NumberField
                                    source="requirementsPercentage"
                                    options={{style: 'percent', maximumFractionDigits: 0}}
                                    sx={{color, fontWeight: 'fontWeightBold'}}
                                    record={{requirementsPercentage: isNaN(pma?.requirementsPercentage) ? 0 : Number(pma?.requirementsPercentage / 100)}}
                                />
                            );
                        }}
                    />
                    <FunctionField
                        source="optionalsPercentage"
                        label="resources.marketplace.edit.opt_completion_percentage"
                        render={(pma: ProductMarketplaceAllocation) => {
                            const color = (isNaN(pma?.optionalsPercentage) || pma.optionalsPercentage < 20) ? 'error.main' : (pma.optionalsPercentage < 99 ? 'rgb(2, 136, 209)' : '#00AC47');
                            return (
                                <NumberField
                                    source="optionalsPercentage"
                                    options={{style: 'percent', maximumFractionDigits: 0}}
                                    sx={{color, fontWeight: 'fontWeightBold'}}
                                    record={{optionalsPercentage: isNaN(pma?.optionalsPercentage) ? 0 : Number(pma?.optionalsPercentage / 100)}}
                                />
                            );
                        }}
                    />
                </EditableDatagrid>
            )}
        </Fragment>
    );
};

export default ProductsAllocationsList;
