import * as React from 'react';
import {createElement} from 'react';
import {Card, Box, Typography} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';

interface Props {
    marketplace: string;
    theme: "secondary" | "primary";
    quantity: string | number;
    bundleQuantity?: string | number;
}

const InventoryAllocationCard = (props: Props) => {
    const {marketplace, quantity, bundleQuantity, theme} = props;

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                marginBottom: '10px',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    padding: '10px 15px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& .icon': {
                        color: `${theme}.main`,
                    }
                }}
            >
                <Box width="3em" className="icon">
                    {createElement(BarChartIcon, {fontSize: 'large'})}
                </Box>
                <Box textAlign="right">
                    <Typography color="textSecondary">{marketplace}</Typography>
                    <Typography variant="h5" component="h2">
                        {quantity}
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
};

export default InventoryAllocationCard;
