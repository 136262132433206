import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { useTranslate, useRecordContext, useGetList } from 'react-admin';

import { Order, Product } from '../types';
import { TableCellRight } from './TableCellRight';
import RefundedItemChip from './RefundedItemChip';

const Basket = () => {
    const record = useRecordContext<Order>();
    const translate = useTranslate();

    const skus = record ? record?.products?.map((product: Product) => product.sku) : [];
    const { isLoading, data: products } = useGetList<Product>('product', {
        filter: { skus },
    });
    if (isLoading || !record || !products) return null;

    const productsBySku = products
        ? products.reduce((acc, product) => {
              acc[product.sku] = product;
              return acc;
          }, {} as any)
        : {};
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        {translate('resources.order.show.product_name')}
                    </TableCell>
                    <TableCell>
                        {translate('resources.order.show.product_sku')}
                    </TableCell>
                    <TableCellRight>
                        {translate('resources.order.show.product_price')}
                    </TableCellRight>
                    <TableCellRight>
                        {translate('resources.order.show.product_quantity')}
                    </TableCellRight>
                    <TableCellRight>
                        {translate('resources.order.show.product_total')}
                    </TableCellRight>
                    <TableCellRight>
                        {translate('resources.order.status')}
                    </TableCellRight>
                </TableRow>
            </TableHead>
            <TableBody>
                {record?.products?.map((item: any) => (
                    <TableRow key={item.id}>
                        <TableCell>{productsBySku[item.sku]?.name}</TableCell>
                        <TableCell>{item.sku}</TableCell>
                        <TableCellRight>
                            {item.priceWithTax.toLocaleString('de-DE', {
                                style: 'currency',
                                currency: 'EUR',
                                currencySign: 'accounting',
                            })}
                        </TableCellRight>
                        <TableCellRight>{item.quantity}</TableCellRight>
                        <TableCellRight>
                            {item.isFree
                                ? 'Free'
                                : item.lineTotalWithTax.toLocaleString(
                                      'de-DE',
                                      {
                                          style: 'currency',
                                          currency: 'EUR',
                                          currencySign: 'accounting',
                                      }
                                  )}
                        </TableCellRight>
                        <TableCellRight>
                            <RefundedItemChip {...item} />
                        </TableCellRight>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default Basket;
