import * as React from 'react';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import {
    TextField,
    useRecordContext,
    Datagrid,
    List,
    BooleanField,
    useListContext
} from 'react-admin';
import {ProductMarketplaceAllocation, Product} from '../types';
import Tree from "./Tree";
import ProductCategoryEditForm from "./ProductCategoryEditForm";

const MarketplaceCategoriesList = (props: { product: Product }) => {
    const record = useRecordContext<ProductMarketplaceAllocation>();
    if (!record) return null;

    return (
        <Card sx={{width: '50%', margin: 'auto'}}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} container alignContent="flex-end">
                        <List
                            resource={`categories`}
                            filter={{marketplace: record.marketplace.id}}
                            pagination={false}
                            hasCreate={true}
                            empty={false}
                            sx={{width: '100%'}}
                            actions={false}
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="name" sortable={false}
                                           label={`resources.marketplace.edit.category_name`}/>
                                <BooleanField source="isDefault" sortable={false}
                                              label={`resources.marketplace.edit.category_default`}/>
                                <ProductCategoryEditForm product={props.product}/>
                            </Datagrid>
                        </List>
                    </Grid>
                </Grid>
                <Box height={20}>&nbsp;</Box>
            </CardContent>
        </Card>
    );
};

export default MarketplaceCategoriesList;
