type stateType = {
    id: string;
    translationKey: string;
    color: 'success' | 'warning';
};

type statesCollection = {
    [key: string]: stateType;
};

export const cronJobStates: statesCollection = {
    running: {
        id: 'running',
        translationKey: 'resources.server.cron.running',
        color: 'success',
    },
    stopped: {
        id: 'stopped',
        translationKey: 'resources.server.cron.stopped',
        color: 'warning',
    },
};

export default [
    { id: 'running', name: 'resources.server.cron.running' },
    { id: 'stopped', name: 'resources.server.cron.running' },
];
