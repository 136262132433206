import DealerList from './DealerList';
import DealerEdit from './DealerEdit';
import StoreIcon from '@mui/icons-material/Store';

const resource = {
    list: DealerList,
    edit: DealerEdit,
    icon: StoreIcon,
};

export default resource;
