import * as React from 'react';
import {
    Identifier,
    Datagrid,
    DateField,
    TextField,
    EmailField,
    BooleanField,
} from 'react-admin';

import rowSx from './rowSx';
import {useDefineAppLocation} from "@react-admin/ra-navigation";

export interface BackInStockListDesktopProps {
    selectedRow?: Identifier;
}

const BackInStockListDesktop = ({selectedRow}: BackInStockListDesktopProps) => {
    useDefineAppLocation('catalog.back_in_stock');
    return (
        <Datagrid
            rowClick="edit"
            rowSx={rowSx(selectedRow)}
            optimized
            bulkActionButtons={false}
            sx={{
                '& .RaDatagrid-thead': {
                    borderLeftColor: 'transparent',
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                },
                '& .column-comment': {
                    maxWidth: '18em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
            }}
        >
            <TextField source="id" label={`resources.back_in_stock.request_id`}/>
            <DateField
                source="createdAt"
                label={`resources.back_in_stock.requested_at`}
            />
            <TextField
                source="sku"
                label={`resources.back_in_stock.requested_sku`}
            />
            <TextField
                source="productName"
                label={`resources.back_in_stock.product_name`}
            />
            <EmailField source="email"/>
            <DateField
                source="notificationSentAt"
                label={`resources.back_in_stock.notified_at`}
            />
            <BooleanField
                looseValue={true}
                source="isOutdated"
                sortable={false}
                label="resources.back_in_stock.is_outdated"
            />
        </Datagrid>
    );
}

export default BackInStockListDesktop;
