import * as React from 'react';
import {Stack, Chip} from '@mui/material';
import {useRecordContext} from 'react-admin';
import {Product} from '../types';

const TagsField = ({tags}) => {
    const record = useRecordContext<Product>();
    if (!record || !record.tags) {
        return null;
    }
    return (
        <Stack direction="row" gap={1} flexWrap="wrap">
            {record.tags.map((tag, key) => {
                const data = tags.find(t => t.id === tag.tagId);

                if (data === undefined) {
                    return null;
                }

                return (
                    <Chip
                        size="small"
                        key={tag['@id']}
                        label={data.name}
                    />
                );
            })}
        </Stack>
    );
};

export default TagsField;
