import * as React from 'react';
import { useCallback } from 'react';
import {List, useGetList} from 'react-admin';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Box, Drawer, useMediaQuery, Theme } from '@mui/material';

import BackInStockListMobile from './BackInStockListMobile';
import BackInStockListDesktop from './BackInStockListDesktop';
import backInStockFilters from './backInStockFilters';
import BackInStockShow from './BackInStockShow';

const BackInStockList = () => {
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const location = useLocation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        navigate('/back-in-stock');
    }, [navigate]);

    const match = matchPath('/back-in-stock/:id', location.pathname);

    const { data, isLoading } = useGetList('product/dictionary', {
        filter: { field: 'productType' },
    });

    let filters: JSX.Element[] = [];

    if (!isLoading) {
        filters = backInStockFilters(data as []);
    }

    return (
        <Box display="flex">
            <List
                sx={{
                    flexGrow: 1,
                    transition: (theme: any) =>
                        theme.transitions.create(['all'], {
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    marginRight: !!match ? '400px' : 0,
                }}
                filters={filters}
                perPage={25}
                sort={{ field: 'date', order: 'DESC' }}
            >
                {isXSmall ? (
                    <BackInStockListMobile />
                ) : (
                    <BackInStockListDesktop
                        selectedRow={
                            !!match
                                ? parseInt((match as any).params.id, 10)
                                : undefined
                        }
                    />
                )}
            </List>
            <Drawer
                variant="persistent"
                open={!!match}
                anchor="right"
                onClose={handleClose}
                sx={{ zIndex: 100 }}
            >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {!!match && (
                    <BackInStockShow
                        id={(match as any).params.id}
                        onCancel={handleClose}
                    />
                )}
            </Drawer>
        </Box>
    );
};

export default BackInStockList;
