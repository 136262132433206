import * as React from 'react';
import { Chip } from '@mui/material';
import { useTranslate, useRecordContext } from 'react-admin';
import { Order } from '../types';
import { statuses } from './orderStatus';

const StatusColumn = () => {
    const translate = useTranslate();
    const record = useRecordContext<Order>();
    if (!record) {
        return null;
    }

    const status = statuses[record.status];

    return (
        <Chip
            color={status.color}
            size="small"
            key={status.id}
            label={translate(status.translationKey)}
        />
    );
};

export default StatusColumn;
