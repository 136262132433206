import * as React from "react";
import {
    number,
    NumberInput,
    required,
    SimpleForm,
    Toolbar,
    useRecordContext,
    SaveButton, useRefresh
} from "react-admin";
import {Product, ProductMarketplaceAllocation} from "../types";
import {EditInDialogButton} from "@react-admin/ra-form-layout";

const DefaultWholesaleMarketplaceAllocationUpdateForm = (props: { record: Product }) => {
    const allocation: ProductMarketplaceAllocation = useRecordContext();
    const refresh = useRefresh();

    return (
        <EditInDialogButton
            mutationMode="optimistic"
            title={`'${props.record.name}' allocated in '${allocation.marketplace.name}'`}
            mutationOptions={{
                onSuccess: () => {
                    refresh();
                }
            }}
        >
            <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
                <NumberInput
                    source="minInventory"
                    name="minInventory"
                    fullWidth={true}
                    min={1}
                    max={999}
                    validate={[required(), number()]}
                />
            </SimpleForm>
        </EditInDialogButton>
    );
};

export default DefaultWholesaleMarketplaceAllocationUpdateForm;
