import {
    AutocompleteArrayInput,
    DeleteWithConfirmButton,
    FormDataConsumer, maxLength,
    maxValue, minLength,
    minValue, number, NumberInput,
    SaveButton, SelectInput,
    SimpleForm, TextInput,
    Toolbar, TranslatableInputs, useRecordContext
} from "react-admin";
import {InputAdornment} from "@mui/material";
import {MarkdownInput} from "@react-admin/ra-markdown";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import * as React from "react";

const AttributeEditForm = () => {
    return (
        <SimpleForm
            toolbar={
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <SaveButton/>
                    <DeleteWithConfirmButton
                        redirect={false}
                        mutationMode="optimistic"
                        confirmTitle=" "
                    />
                </Toolbar>}
        >
            <FormDataConsumer>
                {({formData}) => {
                    const validations: any[] = [];

                    if (formData.attribute == undefined) {
                        return null;
                    }

                    if (formData.attribute.valueValidations) {
                        const rules = formData.attribute.valueValidations.split(',');

                        for (let i = 0; i < rules.length; i++) {
                            const rule = rules[i].split('|');
                            const ruleName = rule[0];
                            const ruleValue = rule[1];

                            switch (ruleName) {
                                case 'MIN':
                                    validations.push(minValue(parseInt(ruleValue, 10)));
                                    break;
                                case 'MAX':
                                    validations.push(maxValue(parseInt(ruleValue, 10)));
                                    break;
                                case 'MAX_LENGTH':
                                    validations.push(maxLength(parseInt(ruleValue, 10)));
                                    break;
                                case 'MIN_LENGTH':
                                    validations.push(minLength(parseInt(ruleValue, 10)));
                                    break;
                            }
                        }
                    }

                    switch (formData.attribute.valueType) {
                        case 'INTEGER':
                            validations.push(number());
                            return (
                                <NumberInput
                                    name="value"
                                    source="value"
                                    label="resources.products.show.attribute_value"
                                    hiddenLabel={false}
                                    step={1}
                                    validate={validations}
                                    fullWidth
                                />
                            );
                        case 'MEASUREMENT':
                            validations.push(number());
                            return (
                                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                                    <NumberInput
                                        source="value_translatable"
                                        label=" "
                                        hiddenLabel={true}
                                        step={0.01}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                >
                                                    {formData.attribute?.valueUnit}
                                                </InputAdornment>
                                            ),
                                        }}
                                        validate={validations}
                                    />
                                </TranslatableInputs>
                            );
                        case 'LONG_TEXT':
                            return (
                                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                                    <MarkdownInput
                                        source="value_translatable"
                                        label="resources.products.show.attribute_value"
                                        toolbarItems={[
                                            ['heading', 'bold', 'italic', 'strike'],
                                            ['hr', 'quote'],
                                            ['ul', 'ol', 'indent', 'outdent'],
                                            ['table', 'link'],
                                            ['code', 'codeblock'],
                                        ]}
                                        validate={validations}
                                    />
                                </TranslatableInputs>
                            );
                        case 'LIST_MULTIPLE_VALUES':
                            return (
                                <AutocompleteArrayInput
                                    source="value"
                                    choices={formData.attribute?.attributeValues}
                                    optionValue="id"
                                    optionText="value"
                                    label="resources.products.show.attribute_value"
                                    fullWidth
                                    format={collection => {
                                        if (typeof collection === 'string') {
                                            return collection.split(',');
                                        }

                                        return collection;
                                    }}
                                />
                            );
                        case 'BOOLEAN':
                            return (
                                <SelectInput
                                    source="value"
                                    name="value"
                                    optionValue="id"
                                    optionText="value"
                                    choices={[
                                        {id: "1", value: "ra.boolean.true"},
                                        {id: "0", value: "ra.boolean.false"},
                                    ]}
                                    fullWidth
                                />
                            );
                        case 'LIST':
                            return (
                                <SelectInput
                                    source="value"
                                    name="value"
                                    optionValue="id"
                                    optionText="value"
                                    choices={formData.attribute?.attributeValues}
                                    fullWidth
                                />
                            );
                        default:
                            return (
                                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                                    <TextInput
                                        source="value_translatable"
                                        label="resources.products.show.attribute_value"
                                        validate={validations}
                                    />
                                </TranslatableInputs>
                            );
                    }
                }}
            </FormDataConsumer>
        </SimpleForm>
    );
};
const AttributeEditButton = () => {
    const productAttribute = useRecordContext();
    const attribute = productAttribute.attribute;

    let maxWidthValue: "lg" | "xs" = "lg";

    if (attribute?.valueType === 'INTEGER' || attribute?.valueType === 'BOOLEAN') {
        maxWidthValue = "xs";
    }

    return (
        <EditInDialogButton
            fullWidth
            maxWidth={maxWidthValue}
            mutationMode="optimistic"
            title={attribute.name}
            transform={(data) => {
                const type = data.attribute.valueType;
                const languages = ['de', 'en', 'fr'];

                if (type === 'LIST_MULTIPLE_VALUES' || type === 'LIST') {
                    data.value = type === 'LIST' ? data.value.toString() : data.value.join(',');

                    if (data.value_translatable) {
                        const keys = Object.keys(data.value_translatable);

                        for (let i = 0; i < keys.length; i++) {
                            data.value_translatable[keys[i]] = data.value;
                        }
                    } else {
                        data.value_translatable = {};
                        for (let i = 0; i < languages.length; i++) {
                            data.value_translatable[languages[i]] = data.value;
                        }
                    }
                } else if (type === 'INTEGER' || type === 'BOOLEAN') {
                    data.value = data.value.toString();
                    data.value_translatable = {};

                    for (let i = 0; i < languages.length; i++) {
                        data.value_translatable[languages[i]] = data.value;
                    }
                }
                return data;
            }}
        >
            <AttributeEditForm/>
        </EditInDialogButton>
    );
};

export default AttributeEditButton;
