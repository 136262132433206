import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import {
    EditButton,
    useTranslate,
    RecordContextProvider,
    useListContext,
    useLocaleState,
} from 'react-admin';
import cronstrue from 'cronstrue';
import { CronJob } from '../types';
import 'cronstrue/locales/de';

const MobileGrid = () => {
    const translate = useTranslate();
    const [locale] = useLocaleState();
    const { data, isLoading } = useListContext<CronJob>();

    if (isLoading || data.length === 0) {
        return null;
    }

    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card sx={{ margin: '0.5rem 0' }}>
                        <CardHeader
                            title={record.name}
                            subheader={record.description}
                            action={<EditButton />}
                        />
                        <CardContent sx={{ pt: 0 }}>
                            <Typography variant="body2">
                                {translate('resources.server.cron.when')}
                                :&nbsp;
                                {cronstrue.toString(record.time, {
                                    locale,
                                    use24HourTimeFormat: true,
                                    verbose: true,
                                })}
                            </Typography>
                        </CardContent>
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

export default MobileGrid;
