import * as React from 'react';
import { Targets } from '../coupon/targetTypes';

const DiscountValue = (props: { orderDiscount: any }) => {
    const { orderDiscount } = props;

    if (!orderDiscount) {
        return null;
    }
    let label: string = '';

    switch (orderDiscount.type) {
        case Targets.Money:
            label = `(${parseFloat(orderDiscount.value).toLocaleString(
                undefined,
                {
                    style: 'currency',
                    currency: 'EUR',
                }
            )})`;
            break;
        case Targets.Percentage:
            label = `(${(parseFloat(orderDiscount.value) / 100).toLocaleString(
                undefined,
                {
                    style: 'percent',
                }
            )})`;
            break;
    }
    return <>{label}</>;
};

export default DiscountValue;
