import * as React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Tooltip,
    IconButton,
} from '@mui/material';

import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    useTranslate,
} from 'react-admin';
import { Sell, Help } from '@mui/icons-material';
import { statuses } from './orderStatus';

const OrdersListFilters = () => {
    const translate = useTranslate();

    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 6,
                alignSelf: 'flex-start',
            }}
        >
            <CardHeader
                action={
                    <IconButton aria-label="settings">
                        <Tooltip
                            disableFocusListener
                            leaveDelay={500}
                            title={translate('resources.order.filters.tooltip')}
                        >
                            <Help />
                        </Tooltip>
                    </IconButton>
                }
                title={translate('resources.order.filters.headline')}
            />
            <Divider />
            <CardContent sx={{ pt: 0 }}>
                <FilterLiveSearch
                    sx={{ mt: 2 }}
                    source={`q`}
                    variant={`outlined`}
                />
                <FilterList label="resources.order.status" icon={<Sell />}>
                    {Object.keys(statuses).map(statusId => (
                        <FilterListItem
                            label={statuses[statusId].translationKey}
                            value={{
                                status: statusId,
                            }}
                        />
                    ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default OrdersListFilters;
