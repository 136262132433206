import * as React from 'react';
import {
    EditBase,
    useTranslate,
    SimpleForm,
    DateField,
    EditProps,
    Labeled,
    TextField,
    EmailField,
    FunctionField,
} from 'react-admin';
import { Box, Grid, Stack, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import EditToolbar from './EditToolbar';
import { BackInStock } from '../types';
import BackInStockDetailsHeader from './BackInStockDetailsHeader';

interface Props extends EditProps<BackInStock> {
    onCancel: () => void;
}

const BackInStockShow = ({ onCancel, ...props }: Props) => {
    const translate = useTranslate();
    return (
        <EditBase {...props}>
            <Box pt={5} width={{ xs: '100vW', sm: 400 }} mt={{ xs: 2, sm: 1 }}>
                <Stack direction="row" p={2}>
                    <Typography variant="h6" flex="1">
                        {translate('resources.back_in_stock.details_label')}
                    </Typography>
                    <IconButton onClick={onCancel} size="small">
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <SimpleForm
                    sx={{ pt: 0, pb: 0 }}
                    toolbar={<EditToolbar />}
                >
                    <Grid container rowSpacing={1} mb={1}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8}>
                            <BackInStockDetailsHeader />
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={12}>
                            <Labeled>
                                <EmailField source="email" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <TextField
                                    source="productName"
                                    label={`resources.back_in_stock.product_name`}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <TextField source="sku" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <TextField source="locale" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <FunctionField
                                    source="status"
                                    label={`resources.back_in_stock.is_outdated`}
                                    render={(e: BackInStock) => {
                                        return e.isOutdated
                                            ? translate(
                                                'resources.back_in_stock.yes'
                                            )
                                            : translate(
                                                'resources.back_in_stock.no'
                                            );
                                    }}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <DateField source="createdAt" label={`resources.back_in_stock.requested_at`} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <DateField
                                    source="notificationSentAt"
                                    label={`resources.back_in_stock.notified_at`}
                                />
                            </Labeled>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Box>
        </EditBase>
    );
};

export default BackInStockShow;
